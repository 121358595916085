

/* ----------------------------------------------------------------------
>>> TABLE OF CONTENT
-------------------------------------------------------------------------
1 - Basic Elements & Classes
2 - Top Navbar
3 - Sidebar
4 - Nav
5 - Main Container
6 - Footer
7 - Settings
8 - Off sidebar
9 - Utilities
10 - Dropdown Menu
11 - Tabs
12 - Panles
13 - Pagination
14 - Accordion
15 - Animations
16 - Progress Bars
17 - Labels and Badges
18 - Form Elements
19 - Icons
20 - Buttons
21 - Links
22 - Form Wizard
23 - Login
24 - Invoice
25 - Timeline
26 - Tables
27 - User Profile
28 - Calendar
29 - Todo
30 - Chat
31 - Messages
32 - Modals
33 - Maps
34 - Pricing Table
35 - Error
36 - Page Title
37 - Tooltips
38 - Popovers
39 - Date Picker
/* ---------------------------------------------------------------------- */
/*



*/
/* ---------------------------------------------------------------------- */
/*  Basic Elements & Classes
/* ---------------------------------------------------------------------- */
html, body {
  height: 100%;
  width: 100%;
}

html {
  font-size: 100%;
}

body {
  color: #8e8e93;
  direction: ltr;
  overflow-x: hidden;
  height: 100%;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 13px;
  padding: 0;
  margin: 0;
  background: #f7f7f8;
  -webkit-font-smoothing: antialiased !important;
}

::-moz-selection {
  background: #5F8295;
  text-shadow: none;
  color: #ffffff;
}

::selection {
  background: #5F8295;
  text-shadow: none;
  color: #ffffff;
}

a, a:focus, a:hover, a:active {
  outline: 0 !important;
  text-decoration: none !important;
  cursor: pointer;
}

button {
  outline: 0 !important;
}

a {
  color: #007AFF;
}
a:hover, a:focus, a:active {
  color: #004999;
}

h1, h2 {
  font-family: "Raleway", sans-serif;
}

h3, h4, h5 {
  font-family: Helvetica, Arial, sans-serif;
}

h1, h2, h3, h4, h5 {
  font-weight: 300;
  margin-top: 0;
  color: #5b5b60;
}
.text-bold h1, h1.text-bold, h1 .text-bold, .text-bold h2, h2.text-bold, h2 .text-bold, .text-bold h3, h3.text-bold, h3 .text-bold, .text-bold h4, h4.text-bold, h4 .text-bold, .text-bold h5, h5.text-bold, h5 .text-bold {
  font-weight: 600;
}

h5.over-title {
  font-family: "Lato", sans-serif;
  font-size: 16px;
}

.mainTitle {
  margin: 20px 0;
}
.mainTitle small {
  color: #8e8e93;
  font-size: 17px;
  font-weight: 300;
  letter-spacing: 0;
  display: block;
  margin: 10px 0;
}
.mainTitle i {
  font-size: 17px;
}

.ct-callout {
  border-color: #007AFF;
  border-image: none;
  border-radius: 0;
  border-style: solid;
  border-width: 0 0 0 5px;
  margin: 20px 0;
  padding: 10px;
  background: rgba(255, 255, 255, 0.4);
}

.main-wrapper {
  position: relative;
}

.negative {
  color: #f00 !important;
}

.ajax-white-backdrop {
  background-color: rgba(255, 255, 255, 0.5);
  display: none;
  bottom: 0;
  left: 0;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1049;
}

.ajax-white-backdrop:before {
  background: #000;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 5px;
  top: 60px;
  left: 50%;
  margin-left: -20px;
  content: "";
  position: absolute;
}

.ajax-white-backdrop:after {
  content: "\f110";
  font-family: FontAwesome;
  width: 40px;
  height: 40px;
  line-height: 40px;
  top: 60px;
  color: #ffffff;
  text-align: center;
  position: absolute;
  left: 50%;
  margin-left: -20px;
  -webkit-animation: spin 2s infinite linear;
  -moz-animation: spin 2s infinite linear;
  -o-animation: spin 2s infinite linear;
  animation: spin 2s infinite linear;
}

@media (min-width: 992px) {
  .app-navbar-fixed {
    padding-top: 65px;
  }
}

#app {
  height: auto;
  min-height: 100%;
  position: relative;
  width: 100%;
  overflow: hidden;
}
#app .app-content {
  height: 100%;
}
#app .app-content:before, #app .app-content:after {
  content: " ";
  display: table;
}
#app .app-content:after {
  clear: both;
}
@media (max-width: 991px) {
  #app {
    width: 100%;
  }
  #app .app-content {
    -moz-transition: -moz-transform 300ms ease 0s;
    -o-transition: -o-transform 300ms ease 0s;
    -webkit-transition: -webkit-transform 300ms ease;
    -webkit-transition-delay: 0s;
    transition: transform 300ms ease 0s;
    height: 100%;
    width: 100%;
    position: relative;
    z-index: 1050;
  }
  #app.app-slide-off > .app-content {
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
    overflow: hidden;
    z-index: 2;
    position: absolute;
    -webkit-transform: translate3d(260px, 0, 0);
    transform: translate3d(260px, 0, 0);
    -moz-transition: -moz-transform 300ms ease 0s;
    -o-transition: -o-transform 300ms ease 0s;
    -webkit-transition: -webkit-transform 300ms ease;
    -webkit-transition-delay: 0s;
    transition: transform 300ms ease 0s;
  }
  #app.app-slide-off > #off-sidebar {
    -webkit-transform: translate3d(260px, 0, 0);
    transform: translate3d(260px, 0, 0);
    -moz-transition: -moz-transform 300ms ease 0s;
    -o-transition: -o-transform 300ms ease 0s;
    -webkit-transition: -webkit-transform 300ms ease;
    -webkit-transition-delay: 0s;
    transition: transform 300ms ease 0s;
  }
  #app.app-offsidebar-open > .app-content {
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
    overflow: hidden;
    z-index: 2;
    position: absolute;
    -webkit-transform: translate3d(-260px, 0, 0);
    transform: translate3d(-260px, 0, 0);
    -moz-transition: -moz-transform 300ms ease 0s;
    -o-transition: -o-transform 300ms ease 0s;
    -webkit-transition: -webkit-transform 300ms ease;
    -webkit-transition-delay: 0s;
    transition: transform 300ms ease 0s;
  }
}

.app-content-loading {
  background: #000;
  border-radius: 10px;
  height: 100px;
  left: 50%;
  margin-left: -50px;
  margin-top: -50px;
  position: absolute;
  text-align: center;
  top: 50%;
  width: 100px;
  z-index: 1051;
}

.loading-spinner {
  font-size: 50px;
  left: 50%;
  margin-left: -25px;
  margin-top: -25px;
  position: absolute;
  top: 50%;
}

.well {
  box-shadow: none;
}

.nav-pills > li.active > a, .nav-pills > li.active > a:hover, .nav-pills > li.active > a:focus {
  background-color: #007AFF;
  color: #fff;
}

.nav-pills > li > a {
  border-radius: 10px;
}

.list-group-item.active, .list-group-item.active:hover, .list-group-item.active:focus {
  background-color: #007AFF;
  border-color: #007AFF;
}

.container-fullw {
  margin-left: -15px;
  margin-right: -15px;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid #eee;
}
@media (max-width: 768px) {
  .container-fullw {
    padding-left: 15px;
    padding-right: 15px;
  }
}

/* ---------------------------------------------------------------------- */
/*  Top Navbar
/* ---------------------------------------------------------------------- */
.navbar {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1089;
  border: none;
  margin-bottom: 0;
  height: 65px;
}
.navbar .loading-bar-wrapper {
  position: absolute;
  bottom: 0;
  height: 3px;
  left: 0;
  right: 0;
  z-index: 999;
}
.navbar .navbar-header {
  z-index: 10;
  background: #ffffff;
  border-bottom: 1px solid #c8c7cc;
  position: relative;
  height: 65px;
}
@media (min-width: 768px) {
  .navbar .navbar-header {
    width: 260px;
  }
}
@media (min-width: 992px) {
  .navbar .navbar-header {
    border-right: 1px solid #c8c7cc;
  }
}
.navbar .navbar-header a {
  line-height: 65px;
  height: 65px;
}
.navbar .navbar-header .navbar-brand {
  padding: 3px 60px;
}
.navbar .navbar-header .navbar-brand img {
  display: inline-block;
  margin-top: -6px;
}
.navbar .navbar-header .menu-toggler {
  margin-right: 60px;
  padding: 0 15px;
  font-size: 17px;
  color: #c8c7cc;
}
.navbar .navbar-header .sidebar-toggler {
  font-size: 17px;
  padding-right: 15px;
  color: #c8c7cc;
}
.app-sidebar-closed .navbar .navbar-header .sidebar-toggler
{
  padding-right: 27px;
}

.navbar .navbar-header .sidebar-toggler:hover {
  color: #aeacb4;
}
.navbar .navbar-header .sidebar-mobile-toggler {
  font-size: 17px;
  padding-left: 15px;
  padding-right: 15px;
  color: #c8c7cc;
}
.navbar .navbar-header .sidebar-mobile-toggler:hover {
  color: #aeacb4;
}
.navbar .navbar-collapse {
  border-bottom: 1px solid #c8c7cc;
  border-top: none;
  background: #ffffff;
  padding-left: 15px !important;
  padding-right: 15px !important;
  position: relative;
}
@media (min-width: 768px) {
  .navbar .navbar-collapse {
    margin-left: 260px;
    height: 65px !important;
  }
  .navbar .navbar-collapse .nav {
    height: auto !important;
  }
}
@media (max-width: 767px) {
  .navbar .navbar-collapse {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .navbar .navbar-collapse .nav {
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }
}
.navbar .navbar-collapse.in {
  overflow-y: visible;
}
@media (max-width: 991px) {
  .app-slide-off .navbar, .app-offsidebar-open .navbar {
    position: absolute !important;
  }
}
@media (min-width: 992px) {
  .app-navbar-fixed .navbar .navbar-collapse, .app-navbar-fixed .navbar .navbar-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
  }
  .app-navbar-fixed .navbar .dropdown-off-sidebar {
    position: fixed;
    top: 0;
  }
}
@media (min-width: 992px) {
  .app-sidebar-closed .navbar .navbar-header {
    width: 70px;
  }
  .app-sidebar-closed .navbar .navbar-header .navbar-brand {
    display: none;
  },

  .app-sidebar-closed .navbar .navbar-header .sidebar-toggler {
    float: none !important;
    display: block;
    margin: 0;
    padding: 0;
    text-align: center;
  }
  .app-sidebar-closed .navbar .navbar-collapse {
    margin-left: 70px;
  }
}
@media (max-width: 991px) {
  .navbar {
    left: 0;
    margin: 0;
    position: fixed;
    right: 0;
    width: 100%;
  }
}
@media (min-width: 992px) {
  .app-sidebar-fixed .navbar .navbar-header {
    position: fixed;
    top: 0;
  }
}
.navbar .close-handle {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: #ffffff;
  cursor: pointer;
  z-index: 1;
  min-height: 40px;
}
.navbar .close-handle .arrow-left, .navbar .close-handle .arrow-right {
  background-color: #c8c7cc;
  border-radius: 10px;
  bottom: 15px;
  height: 8px;
  left: 50%;
  position: absolute;
  -moz-transition: -moz-transform 0.2s ease 0s;
  -o-transition: -o-transform 0.2s ease 0s;
  -webkit-transition: -webkit-transform 0.2s ease;
  -webkit-transition-delay: 0s;
  transition: transform 0.2s ease 0s;
  width: 24px;
  margin-left: -12px;
  cursor: pointer;
}
.navbar .close-handle .arrow-left {
  -moz-transform: translate3d(-8px, 0px, 0px) rotate(-10deg);
  -ms-transform: translate3d(-8px, 0px, 0px) rotate(-10deg);
  -webkit-transform: translate3d(-8px, 0px, 0px) rotate(-10deg);
  transform: translate3d(-8px, 0px, 0px) rotate(-10deg);
}
.navbar .close-handle .arrow-right {
  -moz-transform: translate3d(8px, 0px, 0px) rotate(10deg);
  -ms-transform: translate3d(8px, 0px, 0px) rotate(10deg);
  -webkit-transform: translate3d(8px, 0px, 0px) rotate(10deg);
  transform: translate3d(8px, 0px, 0px) rotate(10deg);
}
.navbar .navbar-right .nav {
  margin: 0;
}
.navbar .navbar-left .nav {
  margin: 0;
}

.navbar .navbar-right .nav .dropdown-light:after {
  right: 36px;
  left: auto;
  top: -9px;
}
.navbar .navbar-left .nav .dropdown-light:after {
  right: 36px;
  left: auto;
  top: -9px;
}
.navbar .navbar-right .nav .dropdown-light:before {
  right: 35px;
  left: auto;
  top: -11px;
}
.navbar .navbar-left .nav .dropdown-light:before {
  right: 35px;
  left: auto;
  top: -11px;
}
.navbar .navbar-right .badge {
  background-color: #C82E29;
  color: #ffffff;
  padding: 4px 6px;
  position: absolute;
  top: 3px;
  font-size: 10px;
  left: 50%;
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  right: auto;
  margin-left: 13px;
}
.navbar .navbar-left .badge {
  background-color: #C82E29;
  color: #ffffff;
  padding: 4px 6px;
  position: absolute;
  top: 3px;
  font-size: 10px;
  left: 50%;
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  right: auto;
  margin-left: 13px;
}
.navbar .navbar-right .dot-badge {
  border-radius: 100%;
  border-style: solid;
  border-width: 2px;
  height: 10px;
  margin: 2px;
  position: absolute !important;
  border-color: #ffffff;
  top: 6px;
  width: 10px;
  left: 50%;
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  right: auto;
  margin-left: 10px;
}
.navbar .navbar-left .dot-badge {
  border-radius: 100%;
  border-style: solid;
  border-width: 2px;
  height: 10px;
  margin: 2px;
  position: absolute !important;
  border-color: #ffffff;
  top: 6px;
  width: 10px;
  left: 50%;
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  right: auto;
  margin-left: 10px;
}
.navbar .navbar-right .view-all {
  text-align: center;
}
.navbar .navbar-left .view-all {
  text-align: center;
}
.navbar .navbar-right .drop-down-wrapper ul {
  list-style: none;
  margin: 0;
  padding: 5px 5px 0 5px;
}
.navbar .navbar-left .drop-down-wrapper ul {
  list-style: none;
  margin: 0;
  padding: 5px 5px 0 5px;
}
.navbar .dropdown-off-sidebar {
  position: absolute;
  z-index: 11;
  top: 0;
  right: 0;
  border-left: 1px solid #c8c7cc;
  width: 30px;
  background: #ffffff;
  line-height: 64px;
  color: #c8c7cc;
}
.navbar .dropdown-off-sidebar:after {
  position: absolute;
  font-size: 17px;
  padding: 0;
  margin: 0;
  font-family: 'themify';
  content: "\e6da";
  left: 5px;
  top: 0;
}
.app-offsidebar-open .navbar .dropdown-off-sidebar:after {
  content: "\e6d9";
}
@media (min-width: 992px) {
  .app-offsidebar-open .navbar .dropdown-off-sidebar {
    position: fixed;
  }
}
@media (max-width: 991px) {
  .navbar .dropdown-off-sidebar {
    width: 50px;
  }
  .navbar .dropdown-off-sidebar:after {
    left: 15px;
  }
}
@media (min-width: 992px) {
  .navbar .navbar-right {
    position: relative;
    /*margin: 1px 15px 0 0;*/
  }
}
@media (min-width: 992px) {
  .navbar .navbar-left {
    position: relative;
    /*margin: 1px 15px 0 0;*/
  }
}
@media (max-width: 991px) and (min-width: 767px) {
  .navbar .navbar-right {
    position: relative;
    /*margin-right: 1px 35px 0 0;*/
  }
}
@media (max-width: 991px) and (min-width: 767px) {
  .navbar .navbar-left {
    position: relative;
    /*margin-right: 1px 35px 0 0;*/
  }
}
@media (min-width: 768px) {
  .navbar .navbar-right > li {
    float: left;
  }
}
@media (min-width: 768px) {
  .navbar .navbar-left > li {
    float: left;
  }
}
@media (max-width: 767px) {
  .navbar .navbar-right > li {
    border-bottom: 1px solid #c8c7cc;
  }
  .navbar .navbar-right > li:last-child {
    margin-bottom: 40px;
  }
}
@media (max-width: 767px) {
  .navbar .navbar-left > li {
    border-bottom: 1px solid #c8c7cc;
  }
  .navbar .navbar-left > li:last-child {
    margin-bottom: 40px;
  }
}
.navbar .navbar-right > li > a {
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  color: #8e8e93;
  font-size: 11px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 27px;
  text-transform: uppercase;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=90);
  opacity: 0.9;
  padding: 5px 15px 5px 15px;
  position: relative;
  text-align: center;
}
.navbar .navbar-left > li > a {
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  color: #8e8e93;
  font-size: 11px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 27px;
  text-transform: uppercase;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=90);
  opacity: 0.9;
  padding: 5px 15px 5px 15px;
  position: relative;
  text-align: center;
}
@media (min-width: 768px) {
  .navbar .navbar-right > li > a {
    margin-right: 3px;
  }
}
@media (min-width: 768px) {
  .navbar .navbar-left > li > a {
    margin-right: 3px;
  }
}
.navbar .navbar-right > li > a:hover {
  background: #efeff0;
}
.navbar .navbar-left > li > a:hover {
  background: #efeff0;
}
.navbar .navbar-right > li > a i {
  display: block;
  margin-top: 7px;
  color: #c8c7cc;
  font-size: 17px;
}
.navbar .navbar-left > li > a i {
  display: block;
  margin-top: 7px;
  color: #c8c7cc;
  font-size: 17px;
}
.navbar .navbar-right > li.current-user > a {
  padding-top: 12px;
  padding-bottom: 12px;
  font-size: 13px;
  font-weight: normal;
  text-transform: none;
  float: left;
}
.navbar .navbar-left > li.current-user > a {
  padding-top: 12px;
  padding-bottom: 12px;
  font-size: 13px;
  font-weight: normal;
  text-transform: none;
  float: left;
}
.navbar .navbar-right > li.current-user > a img {
  float: left;
  margin-right: 5px;
  width: 39px;
  margin-top: -2px;
  border-radius: 20%;
}
.navbar .navbar-left > li.current-user > a img {
  float: left;
  margin-right: 5px;
  width: 39px;
  margin-top: -2px;
  border-radius: 20%;
}
.navbar .navbar-right > li.current-user > a span {
  float: left;
  line-height: 18px;
  margin-top: 3px;
}
.navbar .navbar-left > li.current-user > a span {
  float: left;
  line-height: 18px;
  margin-top: 3px;
}
.navbar .navbar-right > li.current-user > a i {
  margin: 0;
  font-size: 14px;
}
.navbar .navbar-left > li.current-user > a i {
  margin: 0;
  font-size: 14px;
}
@media (max-width: 767px) {
  .navbar .navbar-right > li.current-user a {
    display: block;
    float: none;
  }
  .navbar .navbar-right > li.current-user a img {
    display: inline-block;
    margin-right: 0;
    float: none;
  }
  .navbar .navbar-right > li.current-user a span {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    font-size: 0;
    line-height: 0px;
    float: none;
    margin-top: 0;
  }
  .navbar .navbar-right > li.current-user a i {
    font-size: 14px;
    line-height: 60px;
    color: #ffffff !important;
    -moz-transition: opacity 0.3s ease;
    -o-transition: opacity 0.3s ease;
    -webkit-transition: opacity 0.3s ease;
    transition: opacity 0.3s ease;
    background: rgba(0, 0, 0, 0.5);
    height: 39px;
    line-height: 39px;
    margin: 10px auto;
    width: 39px;
    border-radius: 20%;
  }
  .navbar .navbar-right > li.current-user.open i, .navbar .navbar-right > li.current-user a:hover i {
    opacity: 0;
  }
}
@media (max-width: 767px) {
  .navbar .navbar-left > li.current-user a {
    display: block;
    float: none;
  }
  .navbar .navbar-left > li.current-user a img {
    display: inline-block;
    margin-right: 0;
    float: none;
  }
  .navbar .navbar-left > li.current-user a span {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    font-size: 0;
    line-height: 0px;
    float: none;
    margin-top: 0;
  }
  .navbar .navbar-left > li.current-user a i {
    font-size: 14px;
    line-height: 60px;
    color: #ffffff !important;
    -moz-transition: opacity 0.3s ease;
    -o-transition: opacity 0.3s ease;
    -webkit-transition: opacity 0.3s ease;
    transition: opacity 0.3s ease;
    background: rgba(0, 0, 0, 0.5);
    height: 39px;
    line-height: 39px;
    margin: 10px auto;
    width: 39px;
    border-radius: 20%;
  }
  .navbar .navbar-left > li.current-user.open i, .navbar .navbar-left > li.current-user a:hover i {
    opacity: 0;
  }
}
.navbar .navbar-right .dropdown-menu:before {
  top: -11px;
  right: 35px;
  left: auto;
}
.navbar .navbar-left .dropdown-menu:before {
  top: -11px;
  right: 35px;
  left: auto;
}
.navbar .navbar-right .dropdown-menu:after {
  top: -9px;
  right: 36px;
  left: auto;
}
.navbar .navbar-left .dropdown-menu:after {
  top: -9px;
  right: 36px;
  left: auto;
}
.navbar .navbar-right .dropdown-menu.dropdown-subview .fa-stack {
  height: 1.9em;
  line-height: 1.9em;
  vertical-align: top;
  margin-right: 5px;
}
.navbar .navbar-left .dropdown-menu.dropdown-subview .fa-stack {
  height: 1.9em;
  line-height: 1.9em;
  vertical-align: top;
  margin-right: 5px;
}
.navbar .navbar-right .dropdown-menu.dropdown-large {
  width: 300px;
}
.navbar .navbar-left .dropdown-menu.dropdown-large {
  width: 300px;
}
.navbar .navbar-right .dropdown-menu.dropdown-messages .drop-down-wrapper ul > li a {
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  color: #8e8e93;
  display: block;
  margin-bottom: 5px;
  padding: 10px;
  border-bottom: none;
  background-color: #f7f7f8;
  text-align: left;
}
.navbar .navbar-left .dropdown-menu.dropdown-messages .drop-down-wrapper ul > li a {
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  color: #8e8e93;
  display: block;
  margin-bottom: 5px;
  padding: 10px;
  border-bottom: none;
  background-color: #f7f7f8;
  text-align: left;
}
.navbar .navbar-right .dropdown-menu.dropdown-messages .drop-down-wrapper ul > li a.unread {
  background-color: #edecee;
}
.navbar .navbar-left .dropdown-menu.dropdown-messages .drop-down-wrapper ul > li a.unread {
  background-color: #edecee;
}
.navbar .navbar-right .dropdown-menu.dropdown-messages .drop-down-wrapper ul > li a:hover, .navbar .navbar-right .dropdown-menu.dropdown-messages .drop-down-wrapper ul > li a:focus, .navbar .navbar-right .dropdown-menu.dropdown-messages .drop-down-wrapper ul > li a:active {
  background-color: #e9e8ea;
}
.navbar .navbar-left .dropdown-menu.dropdown-messages .drop-down-wrapper ul > li a:hover, .navbar .navbar-right .dropdown-menu.dropdown-messages .drop-down-wrapper ul > li a:focus, .navbar .navbar-right .dropdown-menu.dropdown-messages .drop-down-wrapper ul > li a:active {
  background-color: #e9e8ea;
}
.navbar .navbar-right .dropdown-menu.dropdown-messages .drop-down-wrapper ul > li a .thread-image {
  float: left;
  height: 50px;
  margin-right: 8px;
  width: 50px;
}
.navbar .navbar-left .dropdown-menu.dropdown-messages .drop-down-wrapper ul > li a .thread-image {
  float: left;
  height: 50px;
  margin-right: 8px;
  width: 50px;
}
.navbar .navbar-right .dropdown-menu.dropdown-messages .drop-down-wrapper ul > li a .author {
  display: block;
  font-size: 12px;
  font-weight: 600;
  line-height: normal;
}
.navbar .navbar-left .dropdown-menu.dropdown-messages .drop-down-wrapper ul > li a .author {
  display: block;
  font-size: 12px;
  font-weight: 600;
  line-height: normal;
}
.navbar .navbar-right .dropdown-menu.dropdown-messages .drop-down-wrapper ul > li a .preview {
  display: block;
  font-size: 11px;
  line-height: normal;
  color: #aeacb4;
}
.navbar .navbar-left .dropdown-menu.dropdown-messages .drop-down-wrapper ul > li a .preview {
  display: block;
  font-size: 11px;
  line-height: normal;
  color: #aeacb4;
}
.navbar .navbar-right .dropdown-menu.dropdown-messages .drop-down-wrapper ul > li a .time {
  display: block;
  float: right;
  font-size: 11px;
  font-style: italic;
  font-weight: 600;
  color: #aeacb4;
}
.navbar .navbar-left .dropdown-menu.dropdown-messages .drop-down-wrapper ul > li a .time {
  display: block;
  float: right;
  font-size: 11px;
  font-style: italic;
  font-weight: 600;
  color: #aeacb4;
}
@media (max-width: 767px) {
  .navbar .navbar-right .dropdown-menu {
    position: relative;
    width: 100% !important;
    max-width: 100% !important;
    box-shadow: none;
    border-right: none;
    border-left: none;
    border-bottom: none;
    margin: 0;
  }
  .navbar .navbar-right .dropdown-menu:before {
    left: 50% !important;
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }
  .navbar .navbar-right .dropdown-menu:after {
    left: 50% !important;
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }
}
@media (max-width: 767px) {
  .navbar .navbar-left .dropdown-menu {
    position: relative;
    width: 100% !important;
    max-width: 100% !important;
    box-shadow: none;
    border-right: none;
    border-left: none;
    border-bottom: none;
    margin: 0;
  }
  .navbar .navbar-left .dropdown-menu:before {
    left: 50% !important;
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }
  .navbar .navbar-left .dropdown-menu:after {
    left: 50% !important;
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }
}
@media (max-width: 767px) {
  .navbar .navbar-right > li {
    float: left;
    width: 100%;
  }
}
@media (max-width: 767px) {
  .navbar .navbar-left > li {
    float: left;
    width: 100%;
  }
}

/* ---------------------------------------------------------------------- */
/*  Sidebar
/* ---------------------------------------------------------------------- */
#sidebar {
  width: 260px;
  float: left;
  left: 0;
  bottom: 0;
  background: #f7f7f8;
  overflow: visible;
  z-index: 1051;
}
#sidebar > div, #sidebar .sidebar-container {
  height: 100%;
}
.app-sidebar-fixed #sidebar > .sidebar-container {
  overflow-y: hidden;
  overflow-x: visible;
  position: relative;
}
.app-mobile #sidebar {
  overflow: auto;
}
#sidebar .wrapper {
  padding: 20px 15px;
}
.app-navbar-fixed.app-sidebar-fixed #sidebar {
  top: 0;
}
#sidebar nav {
  min-height: 100%;
}
@media (min-width: 992px) {
  #sidebar {
    margin-top: 65px;
  }
}
@media (min-width: 992px) and (min-width: 992px) {
  #sidebar:before {
    background-color: inherit;
    border: inherit;
    bottom: 0;
    content: "";
    position: absolute;
    top: 0;
    width: inherit;
    z-index: -1;
    border-right: 1px solid #c8c7cc;
  }
}
@media (min-width: 992px) {
  #sidebar nav {
    border-right: 1px solid #c8c7cc;
  }
  .app-navbar-fixed #sidebar {
    margin-top: 0;
  }
  .app-sidebar-fixed #sidebar {
    position: fixed;
    top: 64px !important;
    margin-top: 0;
  }
  .app-sidebar-closed #sidebar {
    width: 70px;
  }
  .app-sidebar-closed #sidebar .expo-brand {
    display: none;
  }
}
@media (max-width: 991px) {
  #sidebar {
    position: fixed !important;
    top: 0;
    left: 0;
    z-index: 0;
    float: none;
    -webkit-transform: translate3d(-260px, 0, 0);
    transform: translate3d(-260px, 0, 0);
    -moz-transition: -moz-transform 300ms ease 0s;
    -o-transition: -o-transform 300ms ease 0s;
    -webkit-transition: -webkit-transform 300ms ease;
    -webkit-transition-delay: 0s;
    transition: transform 300ms ease 0s;
  }
  .app-slide-off #sidebar {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    -moz-transition: -moz-transform 300ms ease 0s;
    -o-transition: -o-transform 300ms ease 0s;
    -webkit-transition: -webkit-transform 300ms ease;
    -webkit-transition-delay: 0s;
    transition: transform 300ms ease 0s;
  }
  #sidebar nav {
    border-right: none !important;
  }
  #sidebar > .sidebar-container {
    overflow-y: hidden;
    overflow-x: visible;
    position: relative;
  }
}

/* ---------------------------------------------------------------------- */
/*  Nav
/* ---------------------------------------------------------------------- */
.item-content {
  align-items: center;
  display: table-row;
}
.item-content .item-media {
  display: table-cell;
  padding: 10px 15px 10px 15px;
}
.item-content .item-inner {
  align-items: center;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: #c8c7cc;
  display: table-cell;
  padding: 10px 15px 10px 0;
  width: 100%;
}
.no-touch .item-content .item-inner {
  -moz-transition: border 300ms linear;
  -o-transition: border 300ms linear;
  -webkit-transition: border 300ms linear;
  transition: border 300ms linear;
}
.active .item-content .item-inner, .open .item-content .item-inner, li:last-child .item-content .item-inner {
  border-bottom-color: transparent !important;
}

#sidebar > div nav > ul {
  background: #ffffff;
  list-style: none;
  /*margin: 10px 0;*/
  padding: 0;
  border-top: 1px solid #c8c7cc;
  border-bottom: 1px solid #c8c7cc;
}
#sidebar > div nav > ul > li a {
  color: #8e8e93;
  border-bottom: none;
}
#sidebar > div nav > ul > li a i {
  font-weight: normal;
}
#sidebar > div nav > ul > li > a {
  display: table;
}
#sidebar > div nav > ul > li.hover {
  background: #efeff0;
}
.no-touch #sidebar > div nav > ul > li:hover {
  background: #efeff0;
}
#sidebar > div nav > ul > li.active {
  background: #c8c7cc;
}
#sidebar > div nav > ul > li.active > a {
  border-top: none !important;
  color: #ffffff;
}
#sidebar > div nav > ul > li.active > a i {
  color: #ffffff;
}
#sidebar > div nav > ul li {
  border: none;
  display: block;
  position: relative;
  margin: 0;
  padding: 0;
}
.no-touch #sidebar > div nav > ul li {
  -moz-transition: background-color 300ms linear;
  -o-transition: background-color 300ms linear;
  -webkit-transition: background-color 300ms linear;
  transition: background-color 300ms linear;
}
@media (min-width: 992px) {
  .app-sidebar-closed #sidebar > div nav > ul li ul.sub-menu {
    display: none !important;
  }
}
#sidebar > div nav > ul li.active > ul.sub-menu {
  display: block;
  padding: 0;
}
#sidebar > div nav > ul li > a {
  margin-top: -1px !important;
  font-size: 14px;
}
#sidebar > div nav > ul li > a:active:focus {
  background: #d5d4d8;
}
.no-touch #sidebar > div nav > ul li > a:hover .item-inner {
  border-bottom-color: transparent;
}
#sidebar > div nav > ul li a {
  border: none;
  font-weight: 300;
  margin: 0;
  position: relative;
  text-decoration: none;
}
#sidebar > div nav > ul li a i[class*="ti-"] {
  margin-right: 5px;
  display: inline-block;
  font-size: 17px;
  position: relative;
  top: 2px;
}
#sidebar > div nav > ul li a .icon-arrow {
  float: right;
  margin-right: 6px !important;
  font-style: normal;
  font-weight: normal;
  color: #c8c7cc;
  font-size: 12px;
}
#sidebar > div nav > ul li a .icon-arrow:before {
  content: "\e64a";
  font-family: themify;
}
#sidebar > div nav > ul li.open {
  background: #edecee;
}
#sidebar > div nav > ul li.open > a .icon-arrow {
  filter: progid:DXImageTransform.Microsoft.Alpha(enabled=false);
  opacity: 1;
}
#sidebar > div nav > ul li.open > a .icon-arrow:before {
  content: "\e64b";
}

ul.sub-menu {
  clear: both;
  list-style: none;
  padding: 1px 0;
  display: none;
  margin: 0;
  background: #f7f7f8;
}
ul.sub-menu li {
  background: none;
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0;
  margin-top: 1px !important;
  padding: 0;
}
ul.sub-menu li.active, ul.sub-menu li.open {
  background: #e7e7e9 !important;
}
.no-touch ul.sub-menu li:hover {
  background: #edecee;
}
ul.sub-menu li a {
  background: none;
  display: block;
  font-size: 13px !important;
  font-weight: 300;
  border: none;
  margin: 0;
  padding-bottom: 5px;
  padding-left: 52px !important;
  padding-right: 15px;
  padding-top: 5px;
  text-decoration: none;
}
ul.sub-menu li a .icon-arrow {
  margin-right: 22px;
}
ul.sub-menu li.dropdown-header {
  padding-left: 40px !important;
}
ul.sub-menu li.divider {
  height: 1px;
  margin: 9px 0;
  overflow: hidden;
  margin-left: 40px !important;
}
ul.sub-menu li ul.sub-menu li a {
  padding-left: 60px !important;
}
ul.sub-menu li ul.sub-menu li ul.sub-menu li a {
  padding-left: 80px !important;
}
@media (min-width: 992px) {
  .app-sidebar-closed ul.sub-menu {
    border-right: 1px solid #c8c7cc;
    border-bottom: 1px solid #c8c7cc;
    background: #ffffff;
    left: 70px;
    position: absolute;
    padding: 1px 0;
    overflow-y: auto;
    overflow-x: visible;
    height: auto;
    bottom: auto;
    width: 260px;
    z-index: 1050;
    display: none;
  }
  .app-sidebar-closed ul.sub-menu > li a {
    padding-left: 20px !important;
    padding-right: 5px;
  }
  .app-sidebar-closed ul.sub-menu > li ul.sub-menu {
    position: relative;
    left: 0;
    width: 100%;
    border: none !important;
  }
  .app-sidebar-closed ul.sub-menu > li ul.sub-menu li a {
    padding-left: 30px !important;
  }
  .app-sidebar-closed ul.sub-menu > li ul.sub-menu li ul.sub-menu li a {
    padding-left: 50px !important;
  }
}

@media (min-width: 992px) {
  .app-sidebar-closed .app-aside {
    overflow: visible !important;
  }
  .app-sidebar-closed .app-aside .search-form {
    background: #e2e2e4;
    border-bottom: 1px solid #c8c7cc;
  }
  .app-sidebar-closed .app-aside .search-form > a {
    height: 45px;
    display: block;
    padding: 13px 15px;
    text-align: center;
    font-size: 17px;
    color: #aeacb4;
  }
  .app-sidebar-closed .app-aside .search-form .navbar-form {
    display: none;
  }
  .app-sidebar-closed .app-aside li a .icon-arrow {
    float: right;
    margin-right: 6px !important;
    font-style: normal;
    font-weight: normal;
    color: #c8c7cc;
    font-size: 12px;
  }
  .app-sidebar-closed .app-aside li a .icon-arrow:before {
    content: "\e64a";
    font-family: themify;
  }
  .app-sidebar-closed .app-aside li.open > a .icon-arrow {
    filter: progid:DXImageTransform.Microsoft.Alpha(enabled=false);
    opacity: 1;
  }
  .app-sidebar-closed .app-aside li.open > a .icon-arrow:before {
    content: "\e64b";
  }
  .app-sidebar-closed .app-aside > .item-inner {
    width: 260px;
    left: 70px;
    top: 0;
    padding: 13px 0;
    border-bottom: none !important;
    width: 260px;
    background: #efeff0;
    margin-left: 0;
    z-index: 1051;
  }
  .app-sidebar-closed .app-aside > .item-inner.active {
    background: #c8c7cc !important;
  }
  .app-sidebar-closed .app-aside > .item-inner:before {
    content: "";
    background: none;
    border-bottom: 12px solid transparent;
    border-left: 12px solid #c8c7cc;
    border-right: none;
    border-top: 12px solid transparent;
    display: block;
    float: right;
    height: 0;
    position: absolute;
    left: 0;
    top: 11px;
    width: 0;
    z-index: 1;
  }
  .app-sidebar-closed .app-aside > .item-inner:after {
    content: "";
    background: none;
    border-bottom: 12px solid transparent;
    border-left: 12px solid #efeff0;
    border-top: 12px solid transparent;
    display: block;
    float: right;
    height: 0;
    position: absolute;
    left: -1px;
    top: 11px;
    width: 0;
    z-index: 1;
  }
  .app-sidebar-closed .app-aside > .item-inner .title {
    margin-left: 20px;
    color: #aeacb4 !important;
    font-size: 14px;
  }
  .app-sidebar-closed .app-aside > .item-inner .icon-arrow {
    display: none;
  }
  .app-sidebar-closed .app-aside > ul.sub-menu {
    width: 260px;
    display: block !important;
    overflow: auto;
    padding: 0;
    left: 70px;
  }
  .app-sidebar-closed .app-aside > ul.sub-menu > li a {
    color: #8e8e93;
    font-size: 14px;
  }
}
@media (min-width: 992px) and (min-width: 992px) {
  .app-sidebar-closed #sidebar > div nav > ul {
    padding: 1px 0;
  }
  .no-touch .app-sidebar-closed #sidebar > div nav > ul li {
    -moz-transition: background-color 0ms linear;
    -o-transition: background-color 0ms linear;
    -webkit-transition: background-color 0ms linear;
    transition: background-color 0ms linear;
  }
  .app-sidebar-closed #sidebar > div nav > ul > li.open {
    background: inherit;
  }
  .app-sidebar-closed #sidebar > div nav > ul > li.hover {
    background: #efeff0;
  }
  .app-sidebar-closed #sidebar > div nav > ul > li.active:before {
    border-right: none !important;
  }
  .app-sidebar-closed #sidebar > div nav > ul > li.active:after {
    border-right: none !important;
  }
  .app-sidebar-closed #sidebar > div nav > ul > li.active:hover:after {
    border-left-color: #c8c7cc;
  }
  .app-sidebar-closed #sidebar > div nav > ul > li.active a {
    margin: 0 !important;
  }
  .app-sidebar-closed #sidebar > div nav > ul > li > a {
    height: 45px;
    display: block;
  }
  .app-sidebar-closed #sidebar > div nav > ul > li > a i:not([class*="fa-stack"]) {
    font-size: 17px;
  }
  .app-sidebar-closed #sidebar > div nav > ul > li > a .item-content {
    display: block;
    padding-left: 0;
  }
  .app-sidebar-closed #sidebar > div nav > ul > li > a .item-media {
    display: block;
    text-align: center;
    padding: 13px 15px;
  }
  .app-sidebar-closed #sidebar > div nav > ul > li > a .item-inner {
    display: none;
    position: absolute;
    left: 70px;
    top: 0;
    padding: 13px 0;
    bottom: 0;
    border-bottom: none !important;
    width: 260px;
    background: #efeff0;
    margin-left: 0;
  }
  .app-sidebar-closed #sidebar > div nav > ul > li > a .item-inner .title {
    margin-left: 20px;
    color: #aeacb4 !important;
  }
  .app-sidebar-closed #sidebar > div nav > ul > li > a .item-inner .icon-arrow {
    display: none;
  }
  .app-sidebar-closed #sidebar .s-open {
    width: 70px;
  }
  .app-sidebar-closed #sidebar .navbar-form {
    height: 45px;
    position: absolute;
    z-index: 1;
    width: 70px;
  }
  .app-sidebar-closed #sidebar .navbar-form .s-remove, .app-sidebar-closed #sidebar .navbar-form .form-group {
    display: block;
  }
  .app-sidebar-closed #sidebar .navbar-form a {
    font-size: 17px;
    display: block;
    text-align: center;
    padding: 3px 0;
    color: #aeacb4;
  }
  .app-sidebar-closed #sidebar .navbar-form a.s-remove {
    display: none;
  }
  .app-sidebar-closed #sidebar .navbar-form .form-group {
    left: 69px;
    border-bottom: 1px solid #c8c7cc;
    border-right: 1px solid #c8c7cc;
    border-top: 1px solid #c8c7cc;
    top: 0;
    position: absolute;
    width: 210px;
    z-index: 1;
    background: #e2e2e4;
    padding: 10px 10px 10px 0;
    display: none;
    bottom: -1px;
    top: -1px;
  }
  .app-sidebar-closed #sidebar .navbar-form .form-group input {
    background: rgba(255, 255, 255, 0.3);
  }
  .app-sidebar-closed #sidebar .navbar-form .form-group .search-button {
    top: 8px;
  }
  .app-sidebar-closed #sidebar > .navbar-form .s-remove {
    display: block !important;
    padding: 3px 0;
    text-align: center;
  }
  .app-sidebar-closed #sidebar > .navbar-form .form-group {
    display: block !important;
  }
  .app-sidebar-closed #sidebar .navbar-title {
    display: none;
  }
}

.navbar-title {
  color: #808285;
  font-size: 10px;
  letter-spacing: 0.2px;
  padding-left: 15px !important;
  padding-right: 15px !important;
  padding-top: 20px !important;
  padding-bottom: 5px !important;
  text-transform: uppercase;
}

.s-open {
  display: none;
}

.navbar-form {
  background: #e2e2e4;
  border: none;
  box-shadow: none;
  margin: 0;
  padding: 8px;
  position: relative;
}
.navbar-form a {
  display: none;
}
.navbar-form .search-button {
  background: none;
  border: none;
  border-radius: 0;
  box-shadow: none !important;
  color: #c8c7cc;
  position: absolute;
  right: 5px;
  top: -2px;
  transition: all 100ms ease-in 0s;
}
.navbar-form .search-button:hover {
  transition: all 300ms ease-in 0s;
}
.navbar-form .form-control {
  background: transparent;
  border: medium none;
  border-radius: 0;
  box-shadow: none;
  color: #8e8e93;
  display: block;
  transition: all 300ms ease-in 0s;
  width: 100%;
  font-size: 12px;
  height: 25px;
  border-radius: 3px !important;
}
.navbar-form .form-control:focus {
  background-color: #ffffff !important;
  border-color: #c8c7cc;
  transition: all 300ms ease-in 0s;
}
.navbar-form .form-control:focus + .search-button {
  color: #aeacb4;
}
.navbar-form .form-group {
  display: block;
}

/* ---------------------------------------------------------------------- */
/*  Main Content
/* ---------------------------------------------------------------------- */
.main-content {
  min-height: 100%;
  position: relative;
}
.main-content .container {
  max-width: none !important;
  width: 100% !important;
  display: inline-block;
}
.main-content > .container {
  padding-bottom: 50px;
}
@media (min-width: 992px) {
  .main-content {
    margin-top: 65px;
    margin-left: 260px;
  }
  .app-navbar-fixed .main-content {
    margin-top: 0;
  }
  .app-navbar-fixed .main-content:before, .app-navbar-fixed .main-content:after {
    content: " ";
    display: table;
  }
  .app-navbar-fixed .main-content:after {
    clear: both;
  }
  .app-sidebar-closed .main-content {
    margin-left: 70px;
  }
}
@media (max-width: 991px) {
  .main-content {
    padding-top: 65px;
  }
}

/* ---------------------------------------------------------------------- */
/*  Footer
/* ---------------------------------------------------------------------- */
#app > footer {
  background-color: #ffffff;
  border-top: 1px solid #c8c7cc;
  bottom: 0;
  right: 0;
  left: 0;
  position: absolute;
  font-size: 12px;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  z-index: 1088;
}
#app > footer .footer-inner {
  padding: 0 20px;
  line-height: 50px;
}
@media (min-width: 992px) {
  #app > footer {
    margin-left: 260px;
  }
  #app.app-sidebar-closed > footer {
    margin-left: 70px;
  }
  #app.app-footer-fixed > footer {
    position: fixed;
  }
}
@media (max-width: 991px) {
  #app.app-slide-off > footer, #app.app-offsidebar-open > footer {
    display: none;
  }
}

.go-top {
  cursor: pointer;
  font-size: 16px;
  margin-bottom: 0;
  margin-right: 0;
  text-decoration: none;
  line-height: 50px;
  text-align: center;
}

/* ---------------------------------------------------------------------- */
/*  Settings
/* ---------------------------------------------------------------------- */
.settings {
  position: fixed !important;
  right: -240px;
  top: 120px;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -moz-transition: right 0.2s ease 0s;
  -o-transition: right 0.2s ease 0s;
  -webkit-transition: right 0.2s ease;
  -webkit-transition-delay: 0s;
  transition: right 0.2s ease 0s;
  border-left: 1px solid #c8c7cc !important;
  border-bottom: 1px solid #c8c7cc !important;
  width: 240px;
  z-index: 1050;
}
.settings.active {
  right: 0;
}
.settings > .btn {
  background: #ffffff !important;
  border-color: #c8c7cc !important;
  border-right-width: 0;
  left: -44px;
  padding: 10px 15px;
  position: absolute;
  top: 41px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.settings > .btn:hover, .settings > .btn:active, .settings > .btn:focus {
  box-shadow: none !important;
}
.settings.panel {
  border-radius: 0 !important;
  border-top: none;
  border-right: none;
  background: #ffffff;
  border-color: #c8c7cc !important;
}
.settings.panel .panel-heading {
  border-top-width: 1px;
  border-top-style: solid;
  padding: 11px 15px;
  background: #efeff0;
  border-color: #c8c7cc;
  border-radius: 0 !important;
}
.settings.panel .panel-body {
  padding: 0;
}
.settings.panel .setting-box {
  border-bottom: 1px solid #c8c7cc;
  padding: 11px 10px 10px;
}
.settings.panel .setting-box .setting-title {
  padding: 5px 0;
}
.settings.panel .setting-box:last-child {
  border-bottom: none;
}

.colors-row {
  display: table;
  height: 100%;
  table-layout: fixed;
  width: 100%;
  padding-bottom: 0 !important;
  padding-top: 5px !important;
  border-bottom: none !important;
}

.color-theme {
  display: table-cell;
  height: 100%;
  vertical-align: top;
  padding-right: 5px;
}
.color-theme:last-child {
  padding-right: 0;
}
.color-theme .color-layout > label {
  border: 1px solid rgba(0, 0, 0, 0.1);
  cursor: pointer;
  display: block;
  overflow: hidden;
  position: relative;
}
.color-theme .color-layout > label .ti-check {
  color: #fff;
  display: block;
  font-size: 12px;
  height: 100%;
  opacity: 0;
  position: absolute;
  text-align: center;
  width: 100%;
  z-index: 1;
  background: rgba(44, 47, 59, 0.3);
  padding: 10px;
}
.color-theme .color-layout > label > input[type="radio"] {
  opacity: 0;
  position: absolute;
  visibility: hidden;
}
.color-theme .color-layout > label > input[type="radio"]:checked + .ti-check {
  opacity: 1 !important;
}
.color-theme .color-layout > label > .color {
  display: block;
  height: 18px;
  position: relative;
}
.color-theme .color-layout > label > .split {
  display: block;
  position: relative;
}
.color-theme .color-layout > label > .split:before, .color-theme .color-layout > label > .split:after {
  content: " ";
  display: table;
}
.color-theme .color-layout > label > .split:after {
  clear: both;
}
.color-theme .color-layout > label > .split > .color {
  display: block;
  height: 20px;
  position: relative;
}
.color-theme .color-layout > label > .split > .color:first-child {
  float: left;
  width: 30%;
}
.color-theme .color-layout > label > .split > .color:last-child {
  float: right;
  width: 70%;
}
.color-theme .color-layout > label > .split > .color .element {
  position: absolute;
  height: 5px;
  width: 100%;
  top: 5px;
}
.color-theme .color-layout > label > .split.header > .color {
  height: 10px;
}

.theme-1 .th-header {
  background: #ffffff;
}
.theme-1 .th-collapse {
  background: #ffffff;
}
.theme-1 .th-sidebar {
  background: #ffffff;
}
.theme-1 .th-body {
  background: #EFEFF4;
}
.theme-1 .element {
  background: #007aff;
}

.theme-2 .th-header {
  background: #22262E;
}
.theme-2 .th-collapse {
  background: #ffffff;
}
.theme-2 .th-sidebar {
  background: #1B1E24;
}
.theme-2 .th-body {
  background: #E5E9EC;
}
.theme-2 .element {
  background: #D1351F;
}

.theme-3 .th-header {
  background: #485058;
}
.theme-3 .th-collapse {
  background: #485058;
}
.theme-3 .th-sidebar {
  background: #788290;
}
.theme-3 .th-body {
  background: #F0F3F4;
}
.theme-3 .element {
  background: #1FBBA6;
}

.theme-4 .th-header {
  background: #E7E7E7;
}
.theme-4 .th-collapse {
  background: #F1F1F1;
}
.theme-4 .th-sidebar {
  background: #F3F3F3;
}
.theme-4 .th-body {
  background: #F8F8F8;
}
.theme-4 .element {
  background: #00BDCC;
}

.theme-5 .th-header {
  background: #2C3543;
}
.theme-5 .th-collapse {
  background: #2C3543;
}
.theme-5 .th-sidebar {
  background: #364150;
}
.theme-5 .th-body {
  background: #F8F8F8;
}
.theme-5 .element {
  background: #81B441;
}

.theme-6 .th-header {
  background: #1C2B36;
}
.theme-6 .th-collapse {
  background: #ffffff;
}
.theme-6 .th-sidebar {
  background: #1C2B36;
}
.theme-6 .th-body {
  background: #F0F3F4;
}
.theme-6 .element {
  background: #131E26;
}

/* ---------------------------------------------------------------------- */
/*  OFF SIDEBAR
/* ---------------------------------------------------------------------- */
#off-sidebar {
  bottom: 0;
  position: fixed;
  right: -260px;
  top: 64px;
  -moz-transition: all 0.3s !important 0s;
  -o-transition: all 0.3s !important 0s;
  -webkit-transition: all 0.3s !important;
  -webkit-transition-delay: 0s;
  transition: all 0.3s !important 0s;
  width: 260px;
  z-index: 1091;
  background: #ffffff;
  border-left: 1px solid #c8c7cc;
  border-top: 1px solid #c8c7cc;
  overflow: hidden;
}
#off-sidebar .tab-content {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 45px;
}
@media (max-width: 991px) {
  #off-sidebar {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 0;
    -webkit-transform: translate3d(260px, 0, 0);
    transform: translate3d(260px, 0, 0);
    -moz-transition: -moz-transform 300ms ease 0s;
    -o-transition: -o-transform 300ms ease 0s;
    -webkit-transition: -webkit-transform 300ms ease;
    -webkit-transition-delay: 0s;
    transition: transform 300ms ease 0s;
  }
}
#off-sidebar .tab-pane {
  height: 100%;
  padding: 0;
}
#off-sidebar div[perfect-scrollbar] {
  overflow: hidden !important;
}
.app-mobile #off-sidebar div[perfect-scrollbar] {
  overflow-x: hidden !important;
  overflow-y: auto !important;
}
@media (min-width: 992px) {
  .app-offsidebar-open #off-sidebar {
    right: 0;
  }
}
@media (max-width: 991px) {
  .app-offsidebar-open #off-sidebar {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    -moz-transition: -moz-transform 300ms ease 0s;
    -o-transition: -o-transform 300ms ease 0s;
    -webkit-transition: -webkit-transform 300ms ease;
    -webkit-transition-delay: 0s;
    transition: transform 300ms ease 0s;
  }
}
#off-sidebar .nav-tabs.nav-justified > li {
  display: table-cell;
  width: 1%;
}
#off-sidebar .nav-tabs.nav-justified > li.active > a, #off-sidebar .nav-tabs.nav-justified > li.active > a:hover, #off-sidebar .nav-tabs.nav-justified > li.active > a:focus {
  border-bottom-color: #fff;
}
#off-sidebar .nav-tabs.nav-justified > li > a {
  border-bottom: 1px solid #c8c7cc;
  border-right: 1px solid #c8c7cc;
  border-left: none;
  border-radius: 0;
  border-top: none;
}
#off-sidebar .nav-tabs.nav-justified > li > a i {
  font-size: 17px;
}
#off-sidebar .nav-tabs.nav-justified > li.active > a, #off-sidebar .nav-tabs.nav-justified > li.active > a:hover, #off-sidebar .nav-tabs.nav-justified > li.active > a:focus {
  color: #aeacb4;
}
#off-sidebar .nav-tabs.nav-justified > li:first-child > a {
  border-left: none !important;
}
#off-sidebar .nav-tabs.nav-justified > li:last-child > a {
  border-right: none !important;
}
#off-sidebar .save-options {
  padding: 10px;
}

#off-sidebar .sidebar-wrapper {
  position: relative;
  min-height: 100%;
}

#off-sidebar #users {
  position: relative;
  right: 0;
  top: 0;
  height: 100%;
}
.app-offsidebar-open #off-sidebar #users {
  -moz-transition: right 0.3s ease 0s;
  -o-transition: right 0.3s ease 0s;
  -webkit-transition: right 0.3s ease;
  -webkit-transition-delay: 0s;
  transition: right 0.3s ease 0s;
}
.app-slide-off #off-sidebar #users {
  right: 0 !important;
}
#off-sidebar #users.chat-open {
  right: 260px;
}

#off-sidebar #users .user-chat {
  position: relative;
  left: 260px;
  top: 0;
  height: 100%;
}
#off-sidebar #users .chat-content {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 50px;
}

#off-sidebar .users-list {
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  height: 100%;
}

#off-sidebar .media-list .media {
  border-bottom: 1px solid #c8c7cc;
  margin: 0;
}

#off-sidebar .media-list .media a {
  padding: 10px;
  display: block;
}

#off-sidebar .media-list .media a:hover {
  background: #efeff0;
  text-decoration: none;
}

#off-sidebar .sidebar-title {
  color: #aeacb4;
  font-size: 11px;
  margin: 15px 10px 10px 10px;
  text-transform: uppercase;
}

#off-sidebar .sidebar-back {
  position: relative;
  text-decoration: none;
  padding: 10px;
}
#off-sidebar .sidebar-back i {
  display: inline-block;
  font-size: 17px;
  margin-right: -2px;
  margin-top: 2px;
  font-weight: 600;
  vertical-align: top;
}
#off-sidebar .sidebar-back span {
  font-size: 15px;
  position: relative;
}

#off-sidebar .media-list .media .media-object {
  border-radius: 50% !important;
  float: left;
  margin-right: 10px;
  opacity: 0.8;
  width: 40px;
}

#off-sidebar .media-list .media .user-label {
  display: inline-block;
  margin-top: 10px;
  position: absolute;
  right: 25px;
}

#off-sidebar .media-list .media .status-online {
  line-height: 40px;
  font-size: 6px;
  position: absolute;
  right: 10px;
  display: inline-block;
  color: #1FBBA6;
}

#off-sidebar .media-list .media .media-heading {
  color: #8e8e93 !important;
  display: block;
  font-size: 13px;
  margin-bottom: 4px;
}

#off-sidebar .media-list .media .media-body span {
  color: #c8c7cc;
  display: block;
  font-size: 12px;
}

#off-sidebar .sidebar-content {
  height: 100%;
  position: relative;
}

#off-sidebar .discussion .avatar img {
  border-radius: 50% !important;
  width: 40px;
}

#off-sidebar .discussion .messages {
  margin-top: 10px;
}

#off-sidebar .discussion .messages p {
  font-size: 12px;
}

#off-sidebar .message-bar {
  position: absolute;
  bottom: 0;
}

/* ---------------------------------------------------------------------- */
/*  Utilities
/* ---------------------------------------------------------------------- */
.no-padding {
  padding: 0 !important;
}

.no-margin {
  margin: 0 !important;
}

.inline {
  display: inline;
}

.no-radius {
  -moz-border-radius: 0 !important;
  -webkit-border-radius: 0;
  border-radius: 0 !important;
}

.no-border {
  border: none !important;
}

.border-right {
  border-right-style: solid;
  border-right-width: 1px;
  border-color: rgba(0, 0, 0, 0.07);
}

.border-left {
  border-left-style: solid;
  border-left-width: 1px;
  border-color: rgba(0, 0, 0, 0.07);
}

.border-top {
  border-top-style: solid;
  border-top-width: 1px;
  border-color: rgba(0, 0, 0, 0.07);
}

.border-bottom {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: rgba(0, 0, 0, 0.07);
}

.border-light {
  border-color: rgba(255, 255, 255, 0.2) !important;
}

.border-around {
  border: 1px solid #c8c7cc;
}

.panel-white .border-light, .partition-white .border-light {
  border-color: rgba(0, 0, 0, 0.07) !important;
}

.border-dark {
  border-color: rgba(0, 0, 0, 0.2);
}

.panel-white .border-dark, .partition-white .border-dark {
  border-color: rgba(0, 0, 0, 0.3) !important;
}

.block {
  display: block !important;
}

.inline-block {
  display: inline-block !important;
}

.no-display {
  display: none !important;
}

.display-table {
  display: table !important;
}

.display-table-cell {
  display: table-cell !important;
}

.example {
  margin: 15px 0;
  padding: 14px 19px;
}

.space5 {
  display: block;
  margin-bottom: 5px !important;
  clear: both;
}

.space7 {
  margin-bottom: 7px !important;
  clear: both;
}

.space10 {
  margin-bottom: 10px !important;
  clear: both;
}

.space12 {
  margin-bottom: 12px !important;
  clear: both;
}

.space15 {
  margin-bottom: 15px !important;
  clear: both;
}

.space20 {
  margin-bottom: 20px !important;
  clear: both;
}

.padding-5 {
  padding: 5px !important;
}

.padding-10 {
  padding: 10px !important;
}

.padding-15 {
  padding: 15px !important;
}

.padding-20 {
  padding: 20px !important;
}

.padding-25 {
  padding: 25px !important;
}

.padding-30 {
  padding: 30px !important;
}

.padding-35 {
  padding: 35px !important;
}

.padding-40 {
  padding: 40px !important;
}

.padding-horizontal-5 {
  padding: 0 5px !important;
}

.padding-horizontal-10 {
  padding: 0 10px !important;
}

.padding-horizontal-15 {
  padding: 0 15px !important;
}

.padding-horizontal-20 {
  padding: 0 20px !important;
}

.padding-vertical-10 {
  padding: 10px 0 !important;
}

.padding-vertical-15 {
  padding: 15px 0 !important;
}

.padding-vertical-20 {
  padding: 20px 0 !important;
}

.padding-bottom-0 {
  padding-bottom: 0px !important;
}

.padding-bottom-5 {
  padding-bottom: 5px !important;
}

.padding-bottom-10 {
  padding-bottom: 10px !important;
}

.padding-bottom-15 {
  padding-bottom: 15px !important;
}

.padding-bottom-20 {
  padding-bottom: 20px !important;
}

.padding-bottom-25 {
  padding-bottom: 25px !important;
}

.padding-bottom-30 {
  padding-bottom: 30px !important;
}

.padding-top-0 {
  padding-top: 0px !important;
}

.padding-top-5 {
  padding-top: 5px !important;
}

.padding-top-10 {
  padding-top: 10px !important;
}

.padding-top-15 {
  padding-top: 15px !important;
}

.padding-top-20 {
  padding-top: 20px !important;
}

.padding-top-25 {
  padding-top: 25px !important;
}

.padding-top-30 {
  padding-top: 30px !important;
}

.padding-left-0 {
  padding-left: 0px !important;
}

.padding-left-5 {
  padding-left: 5px !important;
}

.padding-left-10 {
  padding-left: 10px !important;
}

.padding-left-15 {
  padding-left: 15px !important;
}

.padding-left-20 {
  padding-left: 20px !important;
}

.padding-left-25 {
  padding-left: 25px !important;
}

.padding-left-30 {
  padding-left: 30px !important;
}

.padding-right-0 {
  padding-right: 0px !important;
}

.padding-right-5 {
  padding-right: 5px !important;
}

.padding-right-10 {
  padding-right: 10px !important;
}

.padding-right-15 {
  padding-right: 15px !important;
}

.padding-right-20 {
  padding-right: 20px !important;
}

.padding-right-25 {
  padding-right: 25px !important;
}

.padding-right-30 {
  padding-right: 30px !important;
}

.margin-bottom-0 {
  margin-bottom: 0px !important;
}

.margin-bottom-5 {
  margin-bottom: 5px !important;
}

.margin-bottom-10 {
  margin-bottom: 10px !important;
}

.margin-bottom-15 {
  margin-bottom: 15px !important;
}

.margin-bottom-20 {
  margin-bottom: 20px !important;
}

.margin-bottom-25 {
  margin-bottom: 25px !important;
}

.margin-bottom-30 {
  margin-bottom: 30px !important;
}

.margin-top-0 {
  margin-top: 0px !important;
}

.margin-top-5 {
  margin-top: 5px !important;
}

.margin-top-10 {
  margin-top: 10px !important;
}

.margin-top-15 {
  margin-top: 15px !important;
}

.margin-top-20 {
  margin-top: 20px !important;
}

.margin-top-25 {
  margin-top: 25px !important;
}

.margin-top-30 {
  margin-top: 30px !important;
}

.margin-right-0 {
  margin-right: 0px !important;
}

.margin-right-5 {
  margin-right: 5px !important;
}

.margin-right-10 {
  margin-right: 10px !important;
}

.margin-right-15 {
  margin-right: 15px !important;
}

.margin-right-20 {
  margin-right: 20px !important;
}

.margin-right-25 {
  margin-right: 25px !important;
}

.margin-right-30 {
  margin-right: 30px !important;
}

.margin-left-0 {
  margin-left: 0px !important;
}

.margin-left-5 {
  margin-left: 5px !important;
}

.margin-left-10 {
  margin-left: 10px !important;
}

.margin-left-15 {
  margin-left: 15px !important;
}

.margin-left-20 {
  margin-left: 20px !important;
}

.margin-left-25 {
  margin-left: 25px !important;
}

.margin-left-30 {
  margin-left: 30px !important;
}

.min-height-155 {
  min-height: 155px !important;
}

.min-height-180 {
  min-height: 180px !important;
}

.min-height-200 {
  min-height: 200px !important;
}

.min-height-230 {
  min-height: 230px !important;
}

.min-height-250 {
  min-height: 250px !important;
}

.min-height-270 {
  min-height: 270px !important;
}

.min-height-280 {
  min-height: 280px !important;
}

.min-height-300 {
  min-height: 300px !important;
}

.min-height-350 {
  min-height: 350px !important;
}

.height-155 {
  height: 155px !important;
}

.height-180 {
  height: 180px !important;
}

.height-200 {
  height: 200px !important;
}

.height-230 {
  height: 230px !important;
}

.height-250 {
  height: 250px !important;
}

.height-270 {
  height: 270px !important;
}

.height-300 {
  height: 300px !important;
}

.height-330 {
  height: 330px !important;
}

.height-350 {
  height: 350px !important;
}

.width-200 {
  width: 200px !important;
}

.vertical-align-bottom {
  vertical-align: bottom !important;
}

.vertical-align-middle {
  vertical-align: middle !important;
}

.vertical-align-top {
  vertical-align: top !important;
}

.text-muted {
  color: #c2c2c5;
}

.text-bold {
  font-weight: bold !important;
}

.text-extra-small {
  font-size: 11px !important;
}

.text-small {
  font-size: 12px !important;
}

.text-large {
  font-size: 16px !important;
}

.text-extra-large {
  font-size: 17px !important;
}

h1.text-extra-large {
  font-size: 50px !important;
}

.text-primary {
  color: #007AFF !important;
}

.text-grey {
  color: #c8c7cc !important;
}

.text-azure {
  color: #0095C8 !important;
}

.text-orange {
  color: #FF6600 !important;
}

.text-green {
  color: #1FBBA6 !important;
}

.text-blue {
  color: #5F8295 !important;
}

.text-pink {
  color: #DD5A82 !important;
}

.text-purple {
  color: #DD5A82 !important;
}

.text-bricky {
  color: #894550 !important;
}

.text-yellow {
  color: #FFB848 !important;
}

.text-red {
  color: #C82E29 !important;
}

.text-white {
  color: #ffffff !important;
}

a.text-white {
  color: rgba(255, 255, 255, 0.6) !important;
}
a.text-white:hover {
  color: #ffffff !important;
}

.text-dark {
  color: #5b5b60 !important;
}

a.text-dark:hover {
  color: #6f6f74 !important;
}

.text-light {
  color: #c2c2c5 !important;
}

a.text-light:hover {
  color: #adadb1 !important;
}

.text-white-transparent {
  color: rgba(255, 255, 255, 0.7) !important;
}

/* ---------------------------------------------------------------------- */
/*  Background Colors
/* ---------------------------------------------------------------------- */
.no-bg {
  background: none !important;
}

.bg-white {
  background: #ffffff !important;
}

.bg-black {
  background: #2C2F3B !important;
  color: #ffffff;
}

.bg-primary {
  background: #007AFF !important;
  color: #ffffff;
}

.bg-light-primary {
  background: #3395ff !important;
  color: #ffffff;
}

.bg-dark-primary {
  background: #0062cc !important;
  color: #ffffff;
}

.bg-success {
  background: #5cb85c !important;
  color: #ffffff;
}

.bg-info {
  background: #46b8da !important;
  color: #ffffff;
}

.bg-warning {
  background: #eea236 !important;
  color: #ffffff;
}

.bg-danger {
  background: #d43f3a !important;
  color: #ffffff;
}

.bg-azure {
  background: #0095C8 !important;
  color: #ffffff;
}

.bg-light-azure {
  background: #00a8e2 !important;
  color: #ffffff;
}

.bg-dark-azure {
  background: #006f95 !important;
  color: #ffffff;
}

.bg-red {
  background: #C82E29 !important;
  color: #ffffff;
}

.bg-light-red {
  background: #da4f4a !important;
  color: #ffffff;
}

.bg-dark-red {
  background: #9e2420 !important;
  color: #ffffff;
}

.bg-green {
  background: #1FBBA6 !important;
  color: #ffffff;
}

.bg-light-green {
  background: #30ddc6 !important;
  color: #ffffff;
}

.bg-dark-green {
  background: #188f7f !important;
  color: #ffffff;
}

.bg-orange {
  background: #FF6600 !important;
  color: #ffffff;
}

.bg-light-orange {
  background: #ff8533 !important;
  color: #ffffff;
}

.bg-dark-orange {
  background: #cc5200 !important;
  color: #ffffff;
}

.bg-blue {
  background: #5F8295 !important;
  color: #ffffff;
}

.bg-light-blue {
  background: #7c9bab !important;
  color: #ffffff;
}

.bg-dark-blue {
  background: #4b6776 !important;
  color: #ffffff;
}

.bg-pink {
  background: #DD5A82 !important;
  color: #ffffff;
}

.bg-light-pink {
  background: #e684a2 !important;
  color: #ffffff;
}

.bg-dark-pink {
  background: #d43062 !important;
  color: #ffffff;
}

.bg-purple {
  background: #804C75 !important;
  color: #ffffff;
}

.bg-light-purple {
  background: #a05f92 !important;
  color: #ffffff;
}

.bg-dark-purple {
  background: #603958 !important;
  color: #ffffff;
}

.bg-beige {
  background: #D2BD8E !important;
  color: #ffffff;
}

.bg-light-beige {
  background: #d9c8a0 !important;
  color: #ffffff;
}

.bg-dark-beige {
  background: #c3a86a !important;
  color: #ffffff;
}

.bg-grey {
  background: #c8c7cc !important;
  color: #ffffff;
}

.bg-light-grey {
  background: #f7f7f8 !important;
}

.bg-dark-grey {
  background: #aeacb4 !important;
  color: #ffffff;
}

.bg-yellow {
  background: #FFB848 !important;
  color: #ffffff;
}

.bg-light-yellow {
  background: #ffcc7b !important;
  color: #ffffff;
}

.bg-dark-yellow {
  background: #ffa415 !important;
  color: #ffffff;
}

.bg-bricky {
  background: #894550 !important;
  color: #ffffff;
}

.bg-light-bricky {
  background: #aa5764 !important;
  color: #ffffff;
}

.bg-dark-bricky {
  background: #67343c !important;
  color: #ffffff;
}

.bg-body {
  background: #F1F3FA;
}

.center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.circle-100 {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: inline-block;
  -moz-box-shadow: 0, 1px, 2px, #C3C3C3;
  -webkit-box-shadow: 0, 1px, 2px, #C3C3C3;
  box-shadow: 0, 1px, 2px, #C3C3C3;
}

.circle-50 {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  line-height: 55px;
  text-align: center;
  display: inline-block;
  -moz-box-shadow: 0, 1px, 2px, #C3C3C3;
  -webkit-box-shadow: 0, 1px, 2px, #C3C3C3;
  box-shadow: 0, 1px, 2px, #C3C3C3;
}

.rate .value {
  font-size: 30px;
  font-weight: 600;
}
.rate i {
  vertical-align: middle;
  padding-bottom: 15px;
}
.rate .percentage {
  vertical-align: middle;
  font-size: 15px;
  padding-bottom: 15px;
}
.rate img {
  display: block;
  position: relative;
  width: 100%;
}

.overlayer {
  display: block;
  position: absolute;
  z-index: 101;
  background-image: linear-gradient(rgba(0, 0, 0, 0) 0px, #000000 100%);
  opacity: 1;
}
.overlayer.fullwidth {
  width: 100%;
}
.overlayer .overlayer-wrapper {
  display: block;
  position: relative;
  z-index: 100;
}

.bottom-left {
  bottom: 0;
  left: 0;
  position: absolute;
}

.tags {
  display: inline-block;
  background: #C2C6CD;
  color: #ffffff !important;
  text-decoration: none;
  cursor: pointer;
  margin: 0 0 10px 18px;
  padding: 2px 10px 1px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  position: relative;
  transition: all .25s linear;
  white-space: nowrap;
  line-height: 21px;
}
.tags:before {
  content: "";
  border-style: solid;
  border-color: transparent #c5c5c5 transparent transparent;
  border-width: 12px 13px 12px 0;
  position: absolute;
  left: -13px;
  top: 0;
  transition: all .25s linear;
}
.tags:hover {
  background-color: #8B91A0;
  color: #FFFFFF;
}
.tags:hover:before {
  border-color: transparent #8B91A0 transparent transparent;
}
.tags:after {
  background: none repeat scroll 0 0 #FFFFFF;
  border-radius: 50% 50% 50% 50%;
  content: "";
  height: 5px;
  left: -1px;
  position: absolute;
  top: 10px;
  width: 5px;
}

.tooltip-notification {
  cursor: pointer;
  right: 0;
  margin-top: 3px;
  padding: 5px 0;
  position: absolute;
  top: 25px;
  z-index: 10000;
}
.toolbar .tooltip-notification {
  top: 60px;
  right: 20px;
}
.tooltip-notification .tooltip-notification-arrow {
  border-width: 0 5px 5px;
  left: 80%;
  margin-left: -5px;
  top: 0;
  border-color: transparent;
  border-bottom-color: #292B2E;
  border-style: solid;
  height: 0;
  position: absolute;
  width: 0;
}
.tooltip-notification .tooltip-notification-inner {
  background-color: #292B2E;
  border-radius: 4px;
  color: #FFFFFF;
  font-size: 12px;
  padding: 8px;
  text-align: center;
  text-decoration: none;
  line-height: normal;
}
.tooltip-notification .tooltip-notification-inner .message {
  color: #BFC0C1;
  font-size: 11px;
  margin-top: -4px;
}
.tooltip-notification .tooltip-notification-inner > div {
  width: 100px;
}

.float-none {
  float: none;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.background-dark {
  background: rgba(0, 0, 0, 0.2);
}

.background-light {
  background: rgba(255, 255, 255, 0.6) !important;
}

.radius-3 {
  border-radius: 3px;
}

.radius-5 {
  border-radius: 5px;
}

.radius-10 {
  border-radius: 10px;
}

.stack-right-bottom {
  left: auto !important;
  right: -0.5em !important;
  top: auto !important;
  bottom: -0.5em !important;
  text-shadow: 2px 0 0 #fff, -2px 0 0 #fff, 0 2px 0 #fff, 0 -2px 0 #fff, 1px 1px #fff, -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff;
}

.noTransform {
  -o-transform: none !important;
  -moz-transform: none !important;
  -ms-transform: none !important;
  -webkit-transform: none !important;
  transform: none !important;
}

.vertical-bottom {
  vertical-align: bottom;
}

.vertical-middle {
  vertical-align: middle;
}

.vertical-top {
  vertical-align: top;
}

.show-grid {
  margin-bottom: 15px;
}

.show-grid [class^="col-"] {
  background-color: rgba(86, 61, 124, 0.15);
  border: 1px solid rgba(86, 61, 124, 0.2);
  padding-bottom: 10px;
  padding-top: 10px;
}

.no-wrap {
  white-space: nowrap;
}

/* ---------------------------------------------------------------------- */
/* Dropdown Menu
/* ---------------------------------------------------------------------- */
.dropdown-menu.dropdown-dark {
  background-color: #2B3034;
  border: 1px solid #1A1C1E;
  border-radius: 0;
  box-shadow: none;
  list-style: none outside none;
  max-width: 300px;
  min-width: 166px;
  padding: 0;
  position: absolute;
  text-shadow: none;
  z-index: 1000;
}
.dropdown-menu.dropdown-dark:after {
  border-color: transparent;
  border-style: solid;
  display: block;
  height: 0;
  position: absolute;
  width: 0;
  border-width: 10px;
  content: "";
  border-bottom-color: #2B3034;
  border-top-width: 0;
  content: " ";
  top: -9px;
  left: 10px;
}
.dropdown-menu.dropdown-dark.pull-right:after {
  left: auto;
  right: 10px;
}
.dropdown-menu.dropdown-dark .dropdown-header {
  padding: 8px 8px 6px;
  color: #f7f7f8;
  font-size: 11px;
}
.dropdown-menu.dropdown-dark .divider {
  background: none;
}
.dropdown-menu.dropdown-dark li p, .dropdown-menu.dropdown-dark li a, .dropdown-menu.dropdown-dark .drop-down-wrapper li p, .dropdown-menu.dropdown-dark .drop-down-wrapper li a {
  color: #EDEDED;
  border-bottom-color: #1A1C1E;
  color: #FFFFFF;
  font-size: 12px;
  font-weight: 300;
  margin: 0;
  padding: 8px 8px 6px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  white-space: normal !important;
  display: block;
}
.dropdown-menu.dropdown-dark li:last-child a {
  border-bottom: none !important;
  border-radius: 0 0 6px 6px;
}
.dropdown-menu.dropdown-dark > li > a:hover, .dropdown-menu.dropdown-dark > li > a:focus, .dropdown-menu.dropdown-dark .dropdown-submenu:hover > a, .dropdown-menu.dropdown-dark .dropdown-submenu:focus > a, .dropdown-menu.dropdown-dark .drop-down-wrapper li a:hover, .dropdown-menu.dropdown-dark .drop-down-wrapper li a:focus {
  background-color: #212326;
}

.dropdown-menu.dropdown-light {
  background-color: #FFFFFF;
  border: 1px solid rgba(17, 17, 17, 0.15);
  max-width: 300px;
  min-width: 166px;
  border-radius: 7px;
  padding: 0;
}
.dropdown-menu.dropdown-light:before, .dropdown-menu.dropdown-light:after {
  border-color: transparent;
  border-style: solid;
  display: block;
  height: 0;
  position: absolute;
  width: 0;
}
.dropdown-menu.dropdown-light:before {
  border-bottom-color: rgba(17, 17, 17, 0.15);
  border-style: solid;
  display: block;
  height: 0;
  position: absolute;
  width: 0;
  border-width: 11px;
  content: "";
  border-top-width: 0;
  content: " ";
  top: -11px;
  left: 10px;
}
.dropdown-menu.dropdown-light:after {
  border-color: transparent;
  border-style: solid;
  display: block;
  height: 0;
  position: absolute;
  width: 0;
  border-width: 10px;
  content: "";
  border-bottom-color: #FFFFFF;
  border-top-width: 0;
  content: " ";
  top: -9px;
  left: 11px;
}
.dropdown-menu.dropdown-light.pull-right:after {
  left: auto;
  right: 11px;
}
.dropdown-menu.dropdown-light.pull-right:before {
  left: auto;
  right: 10px;
}
.dropdown-menu.dropdown-light li a {
  border-bottom: 1px solid #DDDDDD;
  font-size: 14px;
  line-height: 35px;
  color: #007AFF;
  text-align: center;
}
.dropdown-menu.dropdown-light li:first-child a {
  border-radius: 7px 7px 0 0;
}
.dropdown-menu.dropdown-light li:last-child a {
  border-radius: 0 0 7px 7px;
}
.dropdown-menu.dropdown-light li:last-child a {
  border-bottom: none;
}

.drop-down-wrapper .list-group-item {
  border-left: none;
  border-right: none;
  margin-top: 0;
}
.drop-down-wrapper .list-group-item:first-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.drop-down-wrapper .list-group-item:last-child {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.dropdown-header {
  padding: 5px 10px;
}

/* ---------------------------------------------------------------------- */
/* Tabs
/* ---------------------------------------------------------------------- */
.nav-tabs {
  margin-bottom: 0;
  padding: 0;
}
.nav-tabs.nav-justified > li > a {
  border-left: none !important;
  border-radius: 0;
  border-top: none !important;
  border-right: none !important;
}
.nav-tabs > li {
  margin-bottom: -3px;
  text-align: center;
}
.nav-tabs > li.dropdown.active a {
  cursor: pointer;
}
.nav-tabs > li.dropdown .dropdown-menu > .active a, .nav-tabs > li.dropdown .dropdown-menu > .active a:hover, .nav-tabs > li.dropdown .dropdown-menu > .active a:focus {
  background-color: #f7f7f8;
  color: #ffffff;
}
.nav-tabs > li > a {
  border: 0 none;
  border-radius: 0;
  min-width: 70px;
  padding: 15px 20px;
}
.nav-tabs > li.active a, .nav-tabs > li.active a:hover, .nav-tabs > li.active a:focus {
  border-width: 0;
}
.nav-tabs > .active a, .nav-tabs > .active a:hover, .nav-tabs > .active a:focus {
  border: 0 none;
  -moz-box-shadow: 1px 1px 2px rgba(44, 47, 59, 0.1);
  -webkit-box-shadow: 1px 1px 2px rgba(44, 47, 59, 0.1);
  box-shadow: 1px 1px 2px rgba(44, 47, 59, 0.1);
  color: #505458;
}

.panel-tabs, .tabbable {
  background-color: #E6E8E8;
  padding: 1px;
  margin-bottom: 15px;
}

.tabbable.pills {
  background: none;
}
.tabbable.pills > .nav {
  margin-bottom: 10px;
}

.panel-tabs {
  padding: 0;
}
.panel-tabs .panel-heading {
  background: #EFEFF4;
}
.panel-tabs .panel-body {
  padding: 0;
}
.panel-tabs .tabbable {
  margin: 0;
  padding: 0;
}
.panel-tabs .nav-tabs {
  border: medium none;
  float: right;
  margin-top: -48px;
}
.panel-tabs .nav-tabs > li > a {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.tab-content {
  background-color: #FFFFFF;
  position: relative;
}

.tab-content > .tab-pane, .pill-content > .pill-pane {
  display: none;
  padding: 15px;
  padding-bottom: 40px;
}


.tab-content > .active, .pill-content > .active {
  display: block;
}

.tabs-left.tabbable {
  background-color: none;
  padding: 0;
}
.tabs-left .tab-content {
  border-top: 1px solid #EFEFF4;
  border-right: 1px solid #EFEFF4;
  border-bottom: 1px solid #EFEFF4;
  overflow: hidden;
}
.tabs-left > .nav-tabs {
  border: none;
  float: left;
  margin-bottom: 0;
  top: auto;
}
.tabs-left > .nav-tabs > li {
  float: none;
  text-align: left;
}
.tabs-left > .nav-tabs > li > a, .tabs-left > .nav-tabs > li a:focus, .tabs-left > .nav-tabs > li a:hover {
  border-color: #EFEFF4;
  margin: 0 -1px 0 0;
}
.tabs-left > .nav-tabs > li.active > a, .tabs-left > .nav-tabs > li.active a:focus, .tabs-left > .nav-tabs > li.active a:hover {
  border-color: #EFEFF4;
  border-right: none;
  border-left: 1px solid #EFEFF4;
  border-top-width: 1px;
  margin: 0 -1px;
}

.tabs-right.tabbable {
  background-color: none;
  padding: 0;
}
.tabs-right .tab-content {
  border-top: 1px solid #EFEFF4;
  border-left: 1px solid #EFEFF4;
  border-bottom: 1px solid #EFEFF4;
  overflow: hidden;
}
.tabs-right > .nav-tabs {
  border: none;
  float: right;
  margin-bottom: 0;
  top: auto;
}
.tabs-right > .nav-tabs > li {
  text-align: left;
  float: none;
}
.tabs-right > .nav-tabs > li > a, .tabs-right > .nav-tabs > li a:focus, .tabs-right > .nav-tabs > li a:hover {
  border-color: #EFEFF4;
  margin: 0 0 0 -1px;
}
.tabs-right > .nav-tabs > li.active > a, .tabs-right > .nav-tabs > li.active a:focus, .tabs-right > .nav-tabs > li.active a:hover {
  border-color: #EFEFF4;
  border-right: 1px solid #EFEFF4 !important;
  border-left: none;
  border-top-width: 1px;
  margin: 0 -1px;
}

.tabs-below > .nav-tabs {
  border-bottom-width: 0;
  border-color: #EFEFF4;
  margin-bottom: 0;
  margin-top: 0;
  top: auto;
}
.tabs-below > .nav-tabs > li {
  margin-bottom: 0;
}
.tabs-below > .nav-tabs > li > a, .tabs-below > .nav-tabs > li a:focus, .tabs-below > .nav-tabs > li a:hover {
  border-color: #EFEFF4;
}
.tabs-below > .nav-tabs > li.active > a, .tabs-below > .nav-tabs > li.active a:focus, .tabs-below > .nav-tabs > li.active a:hover {
  border-bottom: none;
  border-color: transparent #EFEFF4 #EFEFF4;
  margin-top: 0;
}

.partition-dark .nav-tabs {
  border: none;
}
.partition-dark .nav-tabs > li {
  margin-bottom: 0;
}
.partition-dark .nav-tabs > li.active > a, .partition-dark .nav-tabs > li.active > a:hover, .partition-dark .nav-tabs > li.active a:focus {
  background-color: #2C2F3B;
  border: none;
  color: #ffffff;
}
.partition-dark .nav-tabs > li.active > a:before, .partition-dark .nav-tabs > li.active a:after {
  border-color: transparent;
  border-style: solid;
  display: block;
  height: 0;
  position: absolute;
  width: 0;
}
.partition-dark .nav-tabs > li.active > a:after {
  content: " ";
  height: 0;
  position: absolute;
  width: 0;
  border: 5px solid transparent;
  border-top-color: #2C2F3B;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  z-index: 1;
}
.partition-dark .nav-tabs > li > a {
  border: none;
  color: #f7f7f8;
  padding: 10px 15px;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
}
.partition-dark .nav-tabs > li > a:hover, .partition-dark .nav-tabs > li a:focus {
  background-color: #2C2F3B;
}
.partition-dark .tab-content {
  z-index: 0;
  color: #2C2F3B;
}

/* ---------------------------------------------------------------------- */
/*  Panels
/* ---------------------------------------------------------------------- */
.panel {
  background: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  color: #8e8e93;
  border: none;
  position: relative;
}
.panel .collapse-on {
  display: none;
}
.panel .collapse-off {
  display: block;
}
.panel.collapses .panel-body {
  display: none;
}
.panel.collapses .collapse-on {
  display: block;
}
.panel.collapses .collapse-off {
  display: none;
}

.panel-footer {
  position: relative;
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: inherit;
  padding: 15px;
  background: none;
}

.panel-heading {
  position: relative;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  padding: 15px;
  min-height: 50px;
}
.panel-heading .panel-heading-tabs {
  list-style: none;
  top: 0;
  right: 0;
  position: absolute;
  margin: 0;
  padding: 0;
}
.panel-heading .panel-heading-tabs > li {
  float: left;
  padding: 0 15px;
  border-left-width: 1px;
  border-left-style: solid;
  border-left-color: inherit;
  height: 50px;
  line-height: 50px;
}
.panel-heading .panel-heading-tabs > li.panel-tools {
  position: relative;
  filter: progid:DXImageTransform.Microsoft.Alpha(enabled=false);
  opacity: 1;
  right: auto;
  top: auto;
}
.panel-heading .panel-heading-tabs > li.panel-tools .tmp-tool {
  right: 20px;
  top: auto;
}
.panel-heading .panel-heading-tabs > li.panel-tools .dropdown {
  line-height: normal;
}

.core-box .heading {
  margin-bottom: 15px;
}
.core-box .core-icon {
  width: 33.3333%;
  left: 0;
  top: 0;
  bottom: 0;
  position: absolute;
}
.core-box .core-icon .icon-big {
  line-height: 80px;
}
.core-box .core-content {
  margin-left: 33.3333%;
  min-height: 120px;
}
.core-box .heading {
  font-size: 12px;
}
.core-box .title {
  font-size: 20px;
  line-height: 21px;
  margin-bottom: 0;
  font-weight: 400;
}
.core-box .subtitle {
  font-size: 14px;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  opacity: 0.8;
}
.core-box .content {
  line-height: 20px;
  margin-bottom: 15px;
  position: relative;
  padding-left: 60px;
}
.core-box .content .fa {
  font-size: 50px;
  left: 0;
  top: 0;
  position: absolute;
  width: 40px;
}
.core-box .view-more {
  float: right;
}
.core-box .view-more i {
  display: inline-block;
}

.panel-tools {
  position: absolute;
  right: 10px;
  top: 14px;
  font-size: 12px;
}

.panel-portfolio .portfolio-grid {
  padding: 0;
  text-align: center;
  overflow: hidden;
  max-height: 200px;
  position: relative;
}
.panel-portfolio .portfolio-grid .item {
  overflow: hidden !important;
}
.panel-portfolio .navigator {
  position: relative;
  text-align: center;
  margin-top: -40px;
  margin-bottom: 10px;
  z-index: 101;
}

.panel-transparent, .partition-transparent {
  background: none;
  position: relative;
  box-shadow: none;
  color: #5b5b60;
}
.panel-transparent .panel-tools .btn, .panel-transparent .panel-tools > a, .partition-transparent .panel-tools .btn, .partition-transparent .panel-tools > a {
  color: rgba(72, 71, 77, 0.7) !important;
}
.panel-transparent .panel-tools .btn:hover, .panel-transparent .panel-tools > a:hover, .partition-transparent .panel-tools .btn:hover, .partition-transparent .panel-tools > a:hover {
  color: #48474d !important;
}

.panel-primary, .partition-primary {
  background-color: #007AFF;
  position: relative;
  color: #ffffff;
}
.panel-primary .panel-heading, .partition-primary .panel-heading {
  background-color: #007AFF;
  border: none;
}
.panel-primary .panel-tools .btn, .panel-primary .panel-tools > a, .partition-primary .panel-tools .btn, .partition-primary .panel-tools > a {
  color: rgba(255, 255, 255, 0.8) !important;
}
.panel-primary .panel-tools .btn:hover, .panel-primary .panel-tools > a:hover, .partition-primary .panel-tools .btn:hover, .partition-primary .panel-tools > a:hover {
  color: #ffffff !important;
}

.panel-light-primary, .partition-light-primary {
  background-color: #3395ff;
  position: relative;
  color: #ffffff;
}
.panel-light-primary .panel-heading, .partition-light-primary .panel-heading {
  background-color: #3395ff;
  border: none;
}
.panel-light-primary .panel-tools .btn, .panel-light-primary .panel-tools > a, .partition-light-primary .panel-tools .btn, .partition-light-primary .panel-tools > a {
  color: rgba(255, 255, 255, 0.8) !important;
}
.panel-light-primary .panel-tools .btn:hover, .panel-light-primary .panel-tools > a:hover, .partition-light-primary .panel-tools .btn:hover, .partition-light-primary .panel-tools > a:hover {
  color: #ffffff !important;
}

.panel-dark-primary, .partition-dark-primary {
  background-color: #0062cc;
  position: relative;
  color: #ffffff;
}
.panel-dark-primary .panel-heading, .partition-dark-primary .panel-heading {
  background-color: #0062cc;
  border: none;
}
.panel-dark-primary .panel-tools .btn, .panel-dark-primary .panel-tools > a, .partition-dark-primary .panel-tools .btn, .partition-dark-primary .panel-tools > a {
  color: rgba(255, 255, 255, 0.8) !important;
}
.panel-dark-primary .panel-tools .btn:hover, .panel-dark-primary .panel-tools > a:hover, .partition-dark-primary .panel-tools .btn:hover, .partition-dark-primary .panel-tools > a:hover {
  color: #ffffff !important;
}

.panel-grey, .partition-grey {
  background-color: #c8c7cc;
  position: relative;
  color: #48474d;
}
.panel-grey .panel-tools .btn, .panel-grey .panel-tools > a, .partition-grey .panel-tools .btn, .partition-grey .panel-tools > a {
  color: rgba(72, 71, 77, 0.7) !important;
}
.panel-grey .panel-tools .btn:hover, .panel-grey .panel-tools > a:hover, .partition-grey .panel-tools .btn:hover, .partition-grey .panel-tools > a:hover {
  color: #48474d !important;
}

.panel-light-grey, .partition-light-grey {
  background-color: #f7f7f8;
  position: relative;
  color: #8e8e93;
}
.panel-light-grey .panel-tools .btn, .panel-light-grey .panel-tools > a, .partition-light-grey .panel-tools .btn, .partition-light-grey .panel-tools > a {
  color: rgba(142, 142, 147, 0.7) !important;
}
.panel-light-grey .panel-tools .btn:hover, .panel-light-grey .panel-tools > a:hover, .partition-light-grey .panel-tools .btn:hover, .partition-light-grey .panel-tools > a:hover {
  color: #8e8e93 !important;
}

.panel-orange, .partition-orange {
  background-color: #FF6600;
  position: relative;
  color: #ffffff;
}
.panel-orange .panel-tools .btn, .panel-orange .panel-tools > a, .partition-orange .panel-tools .btn, .partition-orange .panel-tools > a {
  color: rgba(255, 255, 255, 0.7) !important;
}
.panel-orange .panel-tools .btn:hover, .panel-orange .panel-tools > a:hover, .partition-orange .panel-tools .btn:hover, .partition-orange .panel-tools > a:hover {
  color: #ffffff !important;
}

.panel-purple, .partition-purple {
  background-color: #804C75;
  position: relative;
  color: #ffffff;
}
.panel-purple .panel-tools .btn, .panel-purple .panel-tools > a, .partition-purple .panel-tools .btn, .partition-purple .panel-tools > a {
  color: rgba(255, 255, 255, 0.7) !important;
}
.panel-purple .panel-tools .btn:hover, .panel-purple .panel-tools > a:hover, .partition-purple .panel-tools .btn:hover, .partition-purple .panel-tools > a:hover {
  color: #ffffff !important;
}

.panel-pink, .partition-pink {
  background-color: #DD5A82;
  position: relative;
  color: #ffffff;
}
.panel-pink .panel-tools .btn, .panel-pink .panel-tools > a, .partition-pink .panel-tools .btn, .partition-pink .panel-tools > a {
  color: rgba(255, 255, 255, 0.7) !important;
}
.panel-pink .panel-tools .btn:hover, .panel-pink .panel-tools > a:hover, .partition-pink .panel-tools .btn:hover, .partition-pink .panel-tools > a:hover {
  color: #ffffff !important;
}

.panel-azure, .partition-azure {
  background-color: #0095C8;
  position: relative;
  color: #ffffff;
}
.panel-azure .panel-tools .btn, .panel-azure .panel-tools > a, .partition-azure .panel-tools .btn, .partition-azure .panel-tools > a {
  color: rgba(255, 255, 255, 0.7) !important;
}
.panel-azure .panel-tools .btn:hover, .panel-azure .panel-tools > a:hover, .partition-azure .panel-tools .btn:hover, .partition-azure .panel-tools > a:hover {
  color: #ffffff !important;
}

.panel-bricky, .partition-bricky {
  background-color: #894550;
  position: relative;
  color: #ffffff;
}
.panel-bricky .panel-tools .btn, .panel-bricky .panel-tools > a, .partition-bricky .panel-tools .btn, .partition-bricky .panel-tools > a {
  color: rgba(255, 255, 255, 0.7) !important;
}
.panel-bricky .panel-tools .btn:hover, .panel-bricky .panel-tools > a:hover, .partition-bricky .panel-tools .btn:hover, .partition-bricky .panel-tools > a:hover {
  color: #ffffff !important;
}

.panel-green, .partition-green {
  background-color: #1FBBA6;
  position: relative;
  color: #ffffff;
}
.panel-green .panel-tools .btn, .panel-green .panel-tools > a, .partition-green .panel-tools .btn, .partition-green .panel-tools > a {
  color: rgba(255, 255, 255, 0.7) !important;
}
.panel-green .panel-tools .btn:hover, .panel-green .panel-tools > a:hover, .partition-green .panel-tools .btn:hover, .partition-green .panel-tools > a:hover {
  color: #ffffff !important;
}

.panel-red, .partition-red {
  background-color: #C82E29;
  position: relative;
  color: #ffffff;
}
.panel-red .panel-tools .btn, .panel-red .panel-tools > a, .partition-red .panel-tools .btn, .partition-red .panel-tools > a {
  color: rgba(255, 255, 255, 0.7) !important;
}
.panel-red .panel-tools .btn:hover, .panel-red .panel-tools > a:hover, .partition-red .panel-tools .btn:hover, .partition-red .panel-tools > a:hover {
  color: #ffffff !important;
}

.panel-blue, .partition-blue {
  background-color: #5F8295;
  position: relative;
  color: #ffffff;
}
.panel-blue .panel-tools .btn, .panel-blue .panel-tools > a, .partition-blue .panel-tools .btn, .partition-blue .panel-tools > a {
  color: rgba(255, 255, 255, 0.7) !important;
}
.panel-blue .panel-tools .btn:hover, .panel-blue .panel-tools > a:hover, .partition-blue .panel-tools .btn:hover, .partition-blue .panel-tools > a:hover {
  color: #ffffff !important;
}

.panel-yellow, .partition-yellow {
  background-color: #FFB848;
  position: relative;
  color: #2C2F3B;
}
.panel-yellow .panel-tools .btn, .panel-yellow .panel-tools > a, .partition-yellow .panel-tools .btn, .partition-yellow .panel-tools > a {
  color: rgba(44, 47, 59, 0.7) !important;
}
.panel-yellow .panel-tools .btn:hover, .panel-yellow .panel-tools > a:hover, .partition-yellow .panel-tools .btn:hover, .partition-yellow .panel-tools > a:hover {
  color: #2C2F3B !important;
}

.panel-white, .partition-white {
  background-color: #ffffff;
  position: relative;
  color: #5b5b60;
  border: 1px solid rgba(0, 0, 0, 0.07);
}

.panel-dark, .partition-dark {
  background-color: #2C2F3B;
  position: relative;
  color: #ffffff;
}
.panel-dark .panel-tools .btn, .panel-dark .panel-tools > a, .partition-dark .panel-tools .btn, .partition-dark .panel-tools > a {
  color: rgba(255, 255, 255, 0.7) !important;
}
.panel-dark .panel-tools .btn:hover, .panel-dark .panel-tools > a:hover, .partition-dark .panel-tools .btn:hover, .partition-dark .panel-tools > a:hover {
  color: #ffffff !important;
}

.panel-scroll {
  overflow: hidden;
  position: relative;
  width: auto;
  padding-right: 10px !important;
}

.panel-note {
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
  border: none;
}
.panel-note .panel-heading {
  border-bottom: 0 none;
  padding-top: 30px;
  padding-bottom: 10px;
  top: 0;
  z-index: 3;
  position: relative;
}
.panel-note .panel-heading h3 {
  margin: 0;
}
.panel-note .panel-body {
  padding: 0;
  margin: 0 15px;
  line-height: 18px;
}
.panel-note .panel-footer {
  background: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-top: none;
  padding: 10px 15px;
  margin-bottom: 0;
}
.panel-note .panel-footer .avatar-note {
  width: 30px;
  height: 30px;
  float: left;
  margin-right: 10px;
}
.panel-note .panel-footer .avatar-note img {
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
}
.panel-note .panel-footer .author-note {
  display: block;
  line-height: 14px;
}
.panel-note .panel-footer .timestamp {
  color: #999999;
  display: inline-block;
  font-size: 11px;
}
.panel-note .note-options a {
  margin-left: 10px;
  font-size: 12px;
}
.panel-note .note-options a:hover {
  text-decoration: none;
}

[class^="partition-"]:after {
  content: " ";
  visibility: hidden;
  display: block;
  height: 0;
  clear: both;
}

/* ---------------------------------------------------------------------- */
/*  Backdrop
/* ---------------------------------------------------------------------- */
.full-white-backdrop {
  background-color: #FFFFFF;
  display: none;
  bottom: 0;
  left: 0;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1049;
}

.panel.panel-full-screen {
  bottom: 0;
  left: 10px;
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 1048;
  background-color: #FFFFFF;
}

.tmp-tool {
  position: fixed;
  right: 40px;
  top: 20px;
}

/* ---------------------------------------------------------------------- */
/*  Spinner
/* ---------------------------------------------------------------------- */
.csspinner {
  position: relative;
}

.csspinner:before {
  content: "";
  z-index: 1;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.8);
}

.csspinner:after {
  z-index: 2;
  content: "";
  position: absolute;
  -webkit-transform-origin: center;
  -ms-transform-origin: center;
  transform-origin: center;
}

.load1:after {
  width: 30px;
  height: 30px;
  top: 50%;
  left: 50%;
  margin: -15px 0 0 -15px;
  border-radius: 50%;
  border-top: 2px solid rgba(0, 0, 0, 0.2);
  border-right: 2px solid rgba(0, 0, 0, 0.2);
  border-bottom: 2px solid rgba(0, 0, 0, 0.2);
  border-left: 2px solid #007AFF;
  -webkit-animation: load1 1.1s infinite linear;
  animation: load1 1.1s infinite linear;
}

@-webkit-keyframes load1 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load1 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.load2:after {
  width: 30px;
  height: 30px;
  background-color: #007AFF;
  top: 50%;
  left: 50%;
  margin: -15px 0 0 -15px;
  -webkit-animation: load2 1.2s infinite ease-in-out;
  animation: load2 1.2s infinite ease-in-out;
}

@-webkit-keyframes load2 {
  0% {
    -webkit-transform: perspective(120px);
  }
  50% {
    -webkit-transform: perspective(120px) rotateY(180deg);
  }
  100% {
    -webkit-transform: perspective(120px) rotateY(180deg) rotateX(180deg);
  }
}
@keyframes load2 {
  0% {
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg);
  }
  50% {
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
  }
  100% {
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  }
}
/* ---------------------------------------------------------------------- */
/*  Pagination
/* ---------------------------------------------------------------------- */

.dataTables_filter
{
  float: left;
}

.dataTables_filter input
{
  margin-left: 10px;
}

.pagination > li > a, .pagination > li > span {
  margin-left: 5px;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  color: #007AFF;
}

.pagination > .active > a, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus {
  background-color: #007AFF;
  border-color: #007AFF;
}

.pagination.squared {
  border-radius: 0 !important;
}

.pagination.squared li:first-child a, .pagination.squared li:first-child > span {
  border-bottom-left-radius: 0px !important;
  border-left-width: 1px;
  border-top-left-radius: 0px !important;
}

.pagination.squared li:last-child > a, .pagination.squared li:last-child > span {
  border-bottom-right-radius: 0px !important;
  border-top-right-radius: 0px !important;
}

.pagination-blue li a {
  background: #f7f7f8;
  border: none !important;
  color: #8e8e93;
  display: inline-block;
  margin-right: 1px;
}
.pagination-blue li a:hover {
  background: #e7e7e9;
  color: #424246;
  cursor: pointer;
}
.pagination-blue li:first-child a {
  border: none !important;
}
.pagination-blue li.disabled a {
  background: #f7f7f8;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=20);
  opacity: 0.2;
}
.pagination-blue li.disabled a:hover, .pagination-blue li.disabled a:focus {
  background: #f7f7f8;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=20);
  opacity: 0.2;
  cursor: default !important;
}
.pagination-blue li.active a {
  background: #5F8295 !important;
  border: none !important;
  color: #ffffff !important;
  cursor: default !important;
  filter: progid:DXImageTransform.Microsoft.Alpha(enabled=false);
  opacity: 1;
}
.pagination-blue li [class^="fa-"], .pagination-blue li [class*=" fa-"], .pagination-blue li [class*="ti-"] {
  color: #5F8295;
}

.pagination-green li a {
  background: #f7f7f8;
  border: none !important;
  color: #8e8e93;
  display: inline-block;
  margin-right: 1px;
}
.pagination-green li a:hover {
  background: #e7e7e9;
  color: #424246;
  cursor: pointer;
}
.pagination-green li:first-child a {
  border: none !important;
}
.pagination-green li.disabled a {
  background: #f7f7f8;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=20);
  opacity: 0.2;
}
.pagination-green li.disabled a:hover, .pagination-green li.disabled a:focus {
  background: #f7f7f8;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=20);
  opacity: 0.2;
  cursor: default !important;
}
.pagination-green li.active a {
  background: #1FBBA6 !important;
  border: none !important;
  color: #ffffff !important;
  cursor: default !important;
  filter: progid:DXImageTransform.Microsoft.Alpha(enabled=false);
  opacity: 1;
}
.pagination-green li [class^="fa-"], .pagination-green li [class*=" fa-"], .pagination-green li [class*="ti-"] {
  color: #1FBBA6;
}

.pagination-red li a {
  background: #f7f7f8;
  border: none !important;
  color: #8e8e93;
  display: inline-block;
  margin-right: 1px;
}
.pagination-red li a:hover {
  background: #e7e7e9;
  color: #424246;
  cursor: pointer;
}
.pagination-red li:first-child a {
  border: none !important;
}
.pagination-red li.disabled a {
  background: #f7f7f8;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=20);
  opacity: 0.2;
}
.pagination-red li.disabled a:hover, .pagination-red li.disabled a:focus {
  background: #f7f7f8;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=20);
  opacity: 0.2;
  cursor: default !important;
}
.pagination-red li.active a {
  background: #C82E29 !important;
  border: none !important;
  color: #ffffff !important;
  cursor: default !important;
  filter: progid:DXImageTransform.Microsoft.Alpha(enabled=false);
  opacity: 1;
}
.pagination-red li [class^="fa-"], .pagination-red li [class*=" fa-"], .pagination-red li [class*="ti-"] {
  color: #C82E29;
}

/* ---------------------------------------------------------------------- */
/*  Accordion
/* ---------------------------------------------------------------------- */
.accordion .panel {
  border: none;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
  -moz-box-shadow: 0, 0, 0, transparent;
  -webkit-box-shadow: 0, 0, 0, transparent;
  box-shadow: 0, 0, 0, transparent;
}
.accordion .panel-heading {
  padding: 0;
  background-image: none;
  height: auto;
  min-height: 0;
  -moz-box-shadow: 0, 0, 0, transparent;
  -webkit-box-shadow: 0, 0, 0, transparent;
  box-shadow: 0, 0, 0, transparent;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
}
.accordion .panel-heading .accordion-toggle {
  background-color: #E6E8E8;
  color: #5b5b60;
  display: block;
  font-size: 13px;
  line-height: 20px;
  padding: 10px;
  position: relative;
}
.accordion .panel-heading .accordion-toggle:hover, .accordion .panel-heading .accordion-toggle:focus {
  text-decoration: none;
}
.accordion .panel-heading .accordion-toggle.collapsed > .icon-arrow:before {
  content: "\f105" !important;
}
.accordion .panel-heading .accordion-toggle > .icon-arrow {
  font-family: FontAwesome;
  font-size: 11px;
  padding: 0 5px;
  display: inline-block;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.accordion .panel-heading .accordion-toggle > .icon-arrow:before {
  content: "\f107";
  color: #007AFF;
}
.accordion .panel-body {
  border: none !important;
}
.accordion.panel-group .panel + .panel {
  margin-top: 1px;
}
.accordion.accordion-white .panel-heading .accordion-toggle {
  background-color: #ffffff;
}
.accordion.accordion-white .panel-heading .accordion-toggle.collapsed {
  background-color: #ffffff;
}
.accordion.accordion-white.panel-group .panel + .panel {
  margin-top: 0;
  border-top: 1px solid #a8a8ac;
}
.accordion.heading-white .accordion-toggle {
  background-color: #ffffff;
  color: #8e8e93;
}
.accordion.heading-white .panel-group .panel + .panel {
  margin-top: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.07);
}
.accordion.no-padding .panel-body {
  padding: 0;
}
.accordion.body-light-grey .panel-body {
  background: #f7f7f8 !important;
}
.accordion .panel-group {
  margin-bottom: 0;
}
.accordion .panel-group .panel + .panel {
  margin-top: 0;
  border-top: 1px solid transparent;
}

/* ---------------------------------------------------------------------- */
/*  Animations
/* ---------------------------------------------------------------------- */
.smooth.ng-animate {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.fade-in-right-big.ng-enter {
  -webkit-animation: fadeInRightBig 0.5s;
  animation: fadeInRightBig 0.5s;
}

.fade-in-right-big.ng-leave {
  -webkit-animation: fadeOutLeftBig 0.5s;
  animation: fadeOutLeftBig 0.5s;
}

.fade-in-left-big.ng-enter {
  -webkit-animation: fadeInLeftBig 0.5s;
  animation: fadeInLeftBig 0.5s;
}

.fade-in-left-big.ng-leave {
  -webkit-animation: fadeOutRightBig 0.5s;
  animation: fadeOutRightBig 0.5s;
}

.fade-in-up-big.ng-enter {
  -webkit-animation: fadeInUpBig 0.5s;
  animation: fadeInUpBig 0.5s;
}

.fade-in-up-big.ng-leave {
  -webkit-animation: fadeOutUpBig 0.5s;
  animation: fadeOutUpBig 0.5s;
}

.fade-in-down-big.ng-enter {
  -webkit-animation: fadeInDownBig 0.5s;
  animation: fadeInDownBig 0.5s;
}

.fade-in-down-big.ng-leave {
  -webkit-animation: fadeOutDownBig 0.5s;
  animation: fadeOutDownBig 0.5s;
}

.fade-in.ng-enter {
  -webkit-animation: fadeIn 0.5s;
  animation: fadeIn 0.5s;
}

.fade-in.ng-leave {
  -webkit-animation: fadeOut 0.5s;
  animation: fadeOut 0.5s;
}

.fade-in-right.ng-enter {
  -webkit-animation: fadeInRight 0.5s;
  animation: fadeInRight 0.5s;
}

.fade-in-right.ng-leave {
  -webkit-animation: fadeOutLeft 0.5s;
  animation: fadeOutLeft 0.5s;
}

.fade-in-left.ng-enter {
  -webkit-animation: fadeInLeft 0.5s;
  animation: fadeInLeft 0.5s;
}

.fade-in-left.ng-leave {
  -webkit-animation: fadeOutRight 0.5s;
  animation: fadeOutRight 0.5s;
}

.fade-in-up.ng-enter {
  -webkit-animation: fadeInUp 0.5s;
  animation: fadeInUp 0.5s;
}

.fade-in-up.ng-leave {
  -webkit-animation: fadeOutUp 0.5s;
  animation: fadeOutUp 0.5s;
}

.fade-in-down.ng-enter {
  -webkit-animation: fadeInDown 0.5s;
  animation: fadeInDown 0.5s;
}

.fade-in-down.ng-leave {
  -webkit-animation: fadeOutDown 0.5s;
  animation: fadeOutDown 0.5s;
}

.heart-pulse {
  -webkit-animation: heart-pulse 1s linear infinite;
  -moz-animation: heart-pulse 1s linear infinite;
  -ms-animation: heart-pulse 1s linear infinite;
  animation: heart-pulse 1s linear infinite;
}

@keyframes "heart-pulse" {
  0% {
    color: #a51919;
  }
  90% {
    color: rgba(255, 0, 0, 0);
  }
  100% {
    color: red;
  }
}
@-moz-keyframes heart-pulse {
  0% {
    color: #a51919;
  }
  90% {
    color: rgba(255, 0, 0, 0);
  }
  100% {
    color: red;
  }
}
@-webkit-keyframes "heart-pulse" {
  0% {
    color: #a51919;
  }
  90% {
    color: rgba(255, 0, 0, 0);
  }
  100% {
    color: red;
  }
}
@-ms-keyframes "heart-pulse" {
  0% {
    color: #a51919;
  }
  90% {
    color: rgba(255, 0, 0, 0);
  }
  100% {
    color: red;
  }
}
/* ---------------------------------------------------------------------- */
/* Progress Bar
/* ---------------------------------------------------------------------- */
.progress {
  box-shadow: none;
}

.progress.progress-xs {
  height: 7px;
}

.progress.progress-sm {
  height: 12px;
}

.progress.progress-lg {
  height: 20px;
}

.progress.transparent-black {
  background-color: rgba(0, 0, 0, 0.2);
}

.progress-bar {
  background-color: #007AFF;
}

.progress-bar-success {
  background-color: #5cb85c;
}

.progress-bar-info {
  background-color: #46b8da;
}

.progress-bar-warning {
  background-color: #eea236;
}

.progress-bar-danger {
  background-color: #d43f3a;
}

.progress.vertical {
  width: 60px !important;
}

/* ---------------------------------------------------------------------- */
/*  Labels and Badges
/* ---------------------------------------------------------------------- */
.badge {
  background-color: #007AFF;
}

.badge-success {
  background-color: #5CB85C;
}

.badge-success[href]:hover, .badge-success[href]:focus {
  background-color: #449D44;
}

.badge-warning {
  background-color: #F0AD4E;
}

.badge-warning[href]:hover, .badge-warning[href]:focus {
  background-color: #EC971F;
}

.badge-info {
  background-color: #5BC0DE;
}

.badge-info[href]:hover, .badge-info[href]:focus {
  background-color: #31B0D5;
}

.badge-danger {
  background-color: #D9534F;
}

.badge-danger[href]:hover, .badge-danger[href]:focus {
  background-color: #C9302C;
}

.badge-new {
  background-color: #007AFF;
  border-radius: 12px 12px 12px 12px !important;
  font-size: 11px !important;
  font-weight: 300;
  height: 18px;
  padding: 3px 6px;
  text-align: center;
  text-shadow: none !important;
  vertical-align: middle;
}

.label-default {
  background-color: #007AFF;
}

.label {
  font-size: 85% !important;
  padding: 0.4em 0.6em !important;
}

.label-inverse, .badge-inverse {
  background-color: #555555;
}

/* ---------------------------------------------------------------------- */
/*  Forms
/* ---------------------------------------------------------------------- */
fieldset {
  background: #ffffff;
  border: 1px solid #e6e8e8;
  border-radius: 5px;
  margin: 20px 0 20px 0;
  padding: 25px;
  position: relative;
}
fieldset .form-group {
  margin-left: 0;
  margin-right: 0;
}
fieldset legend {
  background: inherit;
  font-family: "Lato", sans-serif;
  color: #007AFF;
  font-size: 15px;
  left: 10px;
  padding: 0 10px;
  position: absolute;
  top: -12px;
  font-weight: 400;
  width: auto !important;
  border: none !important;
}

.form-control::-moz-placeholder {
  color: #c2c2c5;
  opacity: 1;
}

.form-control:-ms-input-placeholder {
  color: #c2c2c5;
  opacity: 1;
}

.form-control::-webkit-input-placeholder {
  color: #c2c2c5;
  opacity: 1;
}
textarea.form-control, select.form-control {
  color: #000;
}

textarea, select, input[type="text"], input[type="password"], input[type="datetime"], input[type="datetime-local"], input[type="date"], input[type="month"], input[type="time"], input[type="week"], input[type="number"], input[type="email"], input[type="url"], input[type="search"], input[type="tel"], input[type="color"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #FFFFFF;
  background-image: none;
  border: 1px solid #c8c7cc;
  border-radius: 0 0 0 0 !important;
  color: #000;
  font-family: inherit;
  font-size: 14px;
  line-height: 1.2;
  padding: 5px 4px;
  transition-duration: 0.1s;
  box-shadow: none !important;
  -webkit-transition: 300ms ease-in-out;
  -moz-transition: 300ms ease-in-out;
  -ms-transition: 300ms ease-in-out;
  -o-transition: 300ms ease-in-out;
  transition: 300ms ease-in-out;
}

textarea:hover, select:hover, input[type="text"]:hover, input[type="password"]:hover, input[type="datetime"]:hover, input[type="datetime-local"]:hover, input[type="date"]:hover, input[type="month"]:hover, input[type="time"]:hover, input[type="week"]:hover, input[type="number"]:hover, input[type="email"]:hover, input[type="url"]:hover, input[type="search"]:hover, input[type="tel"]:hover, input[type="color"]:hover {
  border-color: #aeacb4;
}
textarea:focus, select:focus, input[type="text"]:focus, input[type="password"]:focus, input[type="datetime"]:focus, input[type="datetime-local"]:focus, input[type="date"]:focus, input[type="month"]:focus, input[type="time"]:focus, input[type="week"]:focus, input[type="number"]:focus, input[type="email"]:focus, input[type="url"]:focus, input[type="search"]:focus, input[type="tel"]:focus, input[type="color"]:focus {
  box-shadow: none !important;
  border-color: #bbbac0 !important;
  background-color: #FFFFCC !important;
}

textarea.underline, select.underline, input[type="text"].underline, input[type="password"].underline, input[type="datetime"].underline, input[type="datetime-local"].underline, input[type="date"].underline, input[type="month"].underline, input[type="time"].underline, input[type="week"].underline, input[type="number"].underline, input[type="email"].underline, input[type="url"].underline, input[type="search"].underline, input[type="tel"].underline, input[type="color"].underline {
  background: none !important;
  background-image: none;
  border: 1px solid #c8c7cc;
  border-top: none;
  border-left: none;
  border-right: none;
  border-radius: 0 0 0 0 !important;
  color: #000;
  font-family: inherit;
  font-size: 14px;
  line-height: 1.2;
  padding: 5px 4px;
  transition-duration: 0.1s;
  box-shadow: none !important;
  -moz-transition: border 300ms ease-out;
  -o-transition: border 300ms ease-out;
  -webkit-transition: border 300ms ease-out;
  transition: border 300ms ease-out;
}
textarea.underline:hover, select.underline:hover, input[type="text"].underline:hover, input[type="password"].underline:hover, input[type="datetime"].underline:hover, input[type="datetime-local"].underline:hover, input[type="date"].underline:hover, input[type="month"].underline:hover, input[type="time"].underline:hover, input[type="week"].underline:hover, input[type="number"].underline:hover, input[type="email"].underline:hover, input[type="url"].underline:hover, input[type="search"].underline:hover, input[type="tel"].underline:hover, input[type="color"].underline:hover {
  border-color: #aeacb4;
}
textarea.underline:focus, select.underline:focus, input[type="text"].underline:focus, input[type="password"].underline:focus, input[type="datetime"].underline:focus, input[type="datetime-local"].underline:focus, input[type="date"].underline:focus, input[type="month"].underline:focus, input[type="time"].underline:focus, input[type="week"].underline:focus, input[type="number"].underline:focus, input[type="email"].underline:focus, input[type="url"].underline:focus, input[type="search"].underline:focus, input[type="tel"].underline:focus, input[type="color"].underline:focus {
  box-shadow: none !important;
  border-color: #007AFF !important;
  background-color: none !important;
}

textarea[disabled], input[disabled], select[disabled] {
  background: #f7f7f8 !important;
  border-color: #F8F8F8 !important;
}
textarea[disabled]:hover, textarea[disabled]:focus, input[disabled]:hover, input[disabled]:focus, select[disabled]:hover, select[disabled]:focus {
  background: #f7f7f8 !important;
  border-color: #F8F8F8;
}

legend {
  color: inherit;
  font-size: 16px;
  font-weight: 300;
}

.input-group-addon {
  background-color: #007AFF;
  border: 1px solid #007AFF;
  color: #ffffff;
}

select[multiple="multiple"]:focus option {
  background: #F8F8F8 !important;
}

.has-error .form-control:focus, .has-error .form-control:hover, .has-warning .form-control:focus, .has-warning .form-control:hover, .has-success .form-control:focus, .has-success .form-control:hover {
  box-shadow: none;
}

.validation-invalid, .validation-valid {
  font-size: 12px;
  padding: 0 5px;
}

.has-error .control-label {
  color: #8e8e93 !important;
}
.has-error .error {
  color: #a94442;
}
.has-error .form-control {
  color: #5b5b60;
  border-color: #F8F8F8;
  border-bottom-color: #a94442 !important;
  border-bottom-width: 1px !important;
  border-bottom-style: dotted;
  border-color: #a94442 !important;
  border-width: 1px !important;
  border-style: dotted;
}
.has-error .form-control:focus, .has-error .form-control:hover {
  border-color: #F8F8F8;
  border-bottom-color: #a94442 !important;
}
.has-error label {
  color: #a94442 !important;
}

.has-warning {
  color: #eea236;
}
.has-warning .control-label {
  color: #8e8e93 !important;
}
.has-warning .form-control {
  color: #5b5b60;
  border-color: #F8F8F8;
  border-bottom-color: #8a6d3b !important;
  border-bottom-width: 1px !important;
  border-bottom-style: dotted;
  border-color: #8a6d3b !important;
  border-width: 1px !important;
  border-style: dotted;
}
.has-warning .form-control:focus, .has-warning .form-control:hover {
  border-color: #F8F8F8;
  border-bottom-color: #8a6d3b !important;
}
.has-warning label {
  color: #8a6d3b;
}

.has-success {
  color: #5cb85c;
}
.has-success .control-label {
  color: #8e8e93 !important;
}
.has-success .form-control {
  color: #5b5b60;
  border-color: #F8F8F8;
  border-bottom-color: #3c763d !important;
  border-bottom-width: 1px !important;
  border-bottom-style: dotted;
  border-color: #3c763d !important;
  border-width: 1px !important;
  border-style: dotted;
}
.has-success .form-control:focus, .has-success .form-control:hover {
  border-color: #F8F8F8;
  border-bottom-color: #3c763d !important;
}
.has-success label {
  color: #3c763d;
}

label {
  font-weight: normal;
  color: #858585;
}

span.input-icon, span.input-help {
  display: block;
  position: relative;
}

.input-icon > input {
  padding-left: 30px !important;
  padding-right: 6px;
}

.input-icon.input-icon-right > input {
  padding-left: 6px !important;
  padding-right: 30px !important;
}

span.input-help > input {
  padding-left: 30px;
  padding-right: 6px;
}

.input-icon > [class*="fa-"], .input-icon > [class*="ti-"] {
  bottom: 0;
  color: #007AFF;
  display: inline-block;
  left: 5px;
  line-height: 35px;
  padding: 0 3px;
  position: absolute;
  top: -1px;
  z-index: 2;
}

.input-icon.input-icon-right > [class*="fa-"], .input-icon.input-icon-right > [class*="ti-"] {
  left: auto;
  right: 4px;
}

.help-button {
  background-color: #65BCDA;
  border-radius: 100% 100% 100% 100%;
  color: #FFFFFF;
  cursor: default;
  position: absolute;
  font-size: 14px;
  font-weight: bold;
  height: 20px;
  padding: 0;
  text-align: center;
  width: 20px;
  line-height: 20px;
  top: 7px;
  left: 7px;
}

.help-button:before {
  content: "\f128";
  display: inline;
  font-family: FontAwesome;
  font-weight: 300;
  height: auto;
  text-shadow: none;
  font-style: normal;
}

.form-control {
  color: inherit;
}

select.form-control {
  color: #000 !important;
  background-color: #FFFFFF;
  border: 1px solid #c8c7cc;
  border-radius: 0 0 0 0;

}

select.form-control option {
  padding: 3px 4px;
}

.form-control.search-select {
  padding: 0 !important;
  box-shadow: none;
  border: none;
}

textarea.autosize {
  min-height: 71px;
}
textarea.autosize.area-animated:focus {
  vertical-align: top;
  transition: height 0.2s;
  -webkit-transition: height 0.2s;
  -moz-transition: height 0.2s;
  transition: height 0.2s;
  overflow: hidden;
  word-wrap: break-word;
  resize: horizontal;
}

.note-editor {
  position: relative;
  padding-top: 12px;
  background: #ffffff;
}
.note-editor textarea {
  line-height: 20px;
  margin-top: 10px;
  min-height: 140px;
  border: none;
  padding: 0;
  position: relative;
}
.note-editor textarea:focus {
  background-color: #ffffff !important;
}
.note-editor:before {
  position: absolute;
  height: 12px;
  top: 0;
  left: 0;
  right: 0;
  content: "";
  padding-top: 1px;
}

.radio label.radio-inline, .checkbox label.checkbox-inline {
  display: inline-block;
}

.radio-inline, .radio-inline + .radio-inline, .checkbox-inline, .checkbox-inline + .checkbox-inline {
  margin-right: 10px !important;
  margin-top: 5px !important;
  margin-left: 0 !important;
  margin-bottom: 10px !important;
}

.checkbox-table {
  display: inline-block;
  margin: 2px 0 0 0;
  line-height: 10px;
}

.checkbox-table label {
  margin-bottom: 0;
}

.help-inline {
  margin-top: 6px;
  color: #737373;
}

.help-block.error {
  color: #B94A48;
}

.symbol.required:before {
  content: "*";
  display: inline;
  color: #E6674A;
}

.has-success .symbol:before {
  content: "\f00c";
  display: inline;
  font-family: FontAwesome;
  color: #468847;
}

.has-error .symbol:before {
  content: "\f00d";
  display: inline;
  font-family: FontAwesome;
  color: #C82E29;
}

.has-warning .symbol:before {
  content: "\f071";
  display: inline;
  font-family: FontAwesome;
  color: #eea236;
}

.has-error .note-editor, .has-error .cke_chrome {
  border-color: #B94A48 !important;
}

.form-group {
  position: relative;
}

.form-group .text {
  display: block;
  font-size: 14px;
  font-weight: 400;
  margin-top: 7px;
}

.form-control[disabled], .form-control[readonly], fieldset[disabled] .form-control {
  cursor: default;
}

.ui-select-multiple.ui-select-bootstrap input.ui-select-search {
  max-width: 50px;
}

/* Default custom select inspired by tympanus.net */
div.cs-select {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  text-align: left;
  background: #fff;
  z-index: 100;
  font-size: 100%;
  width: 100%;
  max-width: 1500px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

div.cs-select.disabled {
  opacity: 0.5;
}
div.cs-select.disabled span {
  cursor: default !important;
}

div.cs-select:focus {
  outline: none;
  /* For better accessibility add a style for this in your skin */
}

.cs-select select {
  display: none;
}

.cs-select span {
  display: block;
  position: relative;
  cursor: pointer;
  padding: 5px 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Placeholder and selected option */
.cs-select > span {
  padding-right: 3em;
}

.cs-select > span::after,
.cs-select .cs-selected span::after {
  speak: none;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.cs-select > span::after {
  content: '\25BE';
  right: 1em;
}

.cs-select .cs-selected span::after {
  content: '\e64c';
  margin-left: 1em;
  font-family: "themify";
  font-size: 60%;
}

.cs-select.cs-active > span::after {
  -webkit-transform: translateY(-50%) rotate(180deg);
  transform: translateY(-50%) rotate(180deg);
}

.cs-select.cs-active {
  z-index: 101;
}

/* Options */
.cs-select .cs-options {
  position: absolute;
  overflow: hidden;
  width: 100%;
  background: #fff;
  visibility: hidden;
}

.cs-select.cs-active .cs-options {
  visibility: visible;
}

.cs-select ul {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
}

.cs-select ul span {
  padding: 1em;
}

.cs-select ul li.cs-focus span {
  background-color: #ddd;
}

/* Optgroup and optgroup label */
.cs-select li.cs-optgroup ul {
  padding-left: 1em;
}

.cs-select li.cs-optgroup > span {
  cursor: default;
}

div.cs-skin-slide {
  color: #5b5b60;
  font-size: 14px;
  width: 100%;
}

div.cs-skin-slide::before {
  content: '';
  background: #ffffff;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
}

.cs-skin-slide.cs-active::before {
  -webkit-transform: scale3d(1, 4, 1);
  transform: scale3d(1, 4, 1);
  background: #f7f7f8;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
}

.cs-skin-slide > span {
  height: 34px;
  line-height: 20px;
  border: 1px solid #c8c7cc;
  -webkit-transition: text-indent 0.3s, opacity 0.3s;
  transition: text-indent 0.3s, opacity 0.3s;
}

.cs-skin-slide.cs-active > span {
  text-indent: -290px;
  opacity: 0;
}

.cs-skin-slide > span::after,
.cs-skin-slide.cs-active > span::after {
  font-family: 'themify';
  content: '\e68e';
  color: #007AFF;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  font-size: 17px;
}

.cs-skin-slide.cs-active > span::after {
  -webkit-transform: translate3d(0, -50%, 0);
  transform: translate3d(0, -50%, 0);
}

.cs-skin-slide .cs-options {
  background: transparent;
  width: 100%;
  height: 400%;
  padding: 5px;
  top: 50%;
  left: 50%;
  -webkit-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0);
}

.cs-skin-slide.cs-active .cs-options {
  overflow-y: auto;
}

.cs-skin-slide .cs-options li {
  opacity: 0;
  -webkit-transform: translate3d(30%, 0, 0);
  transform: translate3d(30%, 0, 0);
  -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
  transition: transform 0.3s, opacity 0.3s;
  margin-bottom: 5px;
}

.cs-skin-slide.cs-active .cs-options li {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  opacity: 1;
}

.cs-skin-slide.cs-active .cs-options li:first-child {
  -webkit-transition-delay: 0.05s;
  transition-delay: 0.05s;
}

.cs-skin-slide.cs-active .cs-options li:nth-child(2) {
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
}

.cs-skin-slide.cs-active .cs-options li:nth-child(3) {
  -webkit-transition-delay: 0.15s;
  transition-delay: 0.15s;
}

.cs-skin-slide.cs-active .cs-options li:nth-child(4) {
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
}

.cs-skin-slide.cs-active .cs-options li:nth-child(5) {
  -webkit-transition-delay: 0.25s;
  transition-delay: 0.25s;
}

.cs-skin-slide.cs-active .cs-options li:nth-child(6) {
  -webkit-transition-delay: 0.3s;
  transition-delay: 0.3s;
}

.cs-skin-slide.cs-active .cs-options li:nth-child(7) {
  -webkit-transition-delay: 0.35s;
  transition-delay: 0.35s;
}

/* more options need more delay declaration */
.cs-skin-slide .cs-options li span {
  padding: 0.8em 1em;
}

.cs-skin-slide .cs-options li span:empty {
  display: none;
}

.cs-skin-slide .cs-options li:hover,
.cs-skin-slide .cs-options li.cs-focus {
  background: #eaeaec;
}

.cs-skin-slide .cs-options li.cs-selected {
  color: #ffffff;
  background: #007AFF;
}

.cs-select [class^="ti-"],
.cs-select [class*=" ti-"],
.cs-select [class^="fa-"],
.cs-select [class*=" fa-"] {
  line-height: inherit !important;
  display: block !important;
}
.cs-select [class^="ti-"] span,
.cs-select [class*=" ti-"] span,
.cs-select [class^="fa-"] span,
.cs-select [class*=" fa-"] span {
  font-family: Helvetica, Arial, sans-serif;
  padding-left: 30px !important;
}
.cs-select [class^="ti-"]:before,
.cs-select [class*=" ti-"]:before,
.cs-select [class^="fa-"]:before,
.cs-select [class*=" fa-"]:before {
  font-family: 'themify';
  position: absolute;
  left: 5px;
  top: 50%;
  -webkit-transform: translate3d(0, -50%, 0);
  transform: translate3d(0, -50%, 0);
  font-size: 17px;
}

.cs-select [class^="ti-"]:before,
.cs-select [class*=" ti-"]:before {
  font-family: 'themify';
}

.cs-select [class^="fa-"]:before,
.cs-select [class*=" fa-"]:before {
  font-family: 'fontawesome';
}

/* Skin Elastic */
div.cs-skin-elastic {
  background: transparent;
  font-size: 14px;
  color: #5b5b60;
}

.cs-skin-elastic > span {
  background-color: #fff;
  z-index: 100;
  border: 1px solid #c8c7cc;
  height: 34px;
  line-height: 20px;
}

.cs-skin-elastic > span::after {
  font-family: 'themify';
  content: '\e64b';
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  color: #007AFF;
}

.cs-skin-elastic .cs-options {
  overflow: visible;
  background: transparent;
  opacity: 1;
  visibility: visible;
  pointer-events: none;
}

.cs-skin-elastic.cs-active .cs-options {
  pointer-events: auto;
}

.cs-skin-elastic .cs-options > ul::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  -webkit-transform: scale3d(1, 0, 1);
  transform: scale3d(1, 0, 1);
  background: #f7f7f8;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
  -webkit-transform-origin: 50% 0%;
  transform-origin: 50% 0%;
  -webkit-transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
}

.cs-skin-elastic.cs-active .cs-options > ul::before {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  -webkit-transition: none;
  transition: none;
  -webkit-animation: expand 0.6s ease-out;
  animation: expand 0.6s ease-out;
}

.cs-skin-elastic .cs-options ul li {
  opacity: 0;
  -webkit-transform: translate3d(0, -25px, 0);
  transform: translate3d(0, -25px, 0);
  -webkit-transition: opacity 0.15s, -webkit-transform 0.15s;
  transition: opacity 0.15s, transform 0.15s;
}

.cs-skin-elastic.cs-active .cs-options ul li {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  opacity: 1;
  -webkit-transition: none;
  transition: none;
  -webkit-animation: bounce 0.6s ease-out;
  animation: bounce 0.6s ease-out;
}

.cs-skin-elastic .cs-options span {
  background-repeat: no-repeat;
  background-position: 1.5em 50%;
  background-size: 2em auto;
  padding: 0.8em 1em 0.8em 1em;
}

.cs-skin-elastic .cs-options li:hover,
.cs-skin-elastic .cs-options li.cs-focus {
  background: #eaeaec;
}

.cs-skin-elastic .cs-options .cs-selected,
.cs-skin-elastic .cs-options .cs-selected:hover {
  color: #ffffff !important;
  background: #007AFF !important;
}

@-webkit-keyframes expand {
  0% {
    -webkit-transform: scale3d(1, 0, 1);
  }
  25% {
    -webkit-transform: scale3d(1, 1.2, 1);
  }
  50% {
    -webkit-transform: scale3d(1, 0.85, 1);
  }
  75% {
    -webkit-transform: scale3d(1, 1.05, 1);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
  }
}
@keyframes expand {
  0% {
    -webkit-transform: scale3d(1, 0, 1);
    transform: scale3d(1, 0, 1);
  }
  25% {
    -webkit-transform: scale3d(1, 1.2, 1);
    transform: scale3d(1, 1.2, 1);
  }
  50% {
    -webkit-transform: scale3d(1, 0.85, 1);
    transform: scale3d(1, 0.85, 1);
  }
  75% {
    -webkit-transform: scale3d(1, 1.05, 1);
    transform: scale3d(1, 1.05, 1);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
@-webkit-keyframes bounce {
  0% {
    -webkit-transform: translate3d(0, -25px, 0);
    opacity: 0;
  }
  25% {
    -webkit-transform: translate3d(0, 10px, 0);
  }
  50% {
    -webkit-transform: translate3d(0, -6px, 0);
  }
  75% {
    -webkit-transform: translate3d(0, 2px, 0);
  }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@keyframes bounce {
  0% {
    -webkit-transform: translate3d(0, -25px, 0);
    transform: translate3d(0, -25px, 0);
    opacity: 0;
  }
  25% {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0);
  }
  50% {
    -webkit-transform: translate3d(0, -6px, 0);
    transform: translate3d(0, -6px, 0);
  }
  75% {
    -webkit-transform: translate3d(0, 2px, 0);
    transform: translate3d(0, 2px, 0);
  }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
input[type="radio"], input[type="checkbox"] {
  box-sizing: border-box;
  cursor: pointer;
  line-height: normal;
}

.clip-check {
  margin-bottom: 10px;
  margin-top: 10px;
  padding-left: 0;
}
.clip-check label {
  cursor: pointer;
  display: inline-block;
  font-size: 13px;
  margin-right: 15px;
  padding-left: 30px !important;
  position: relative;
  line-height: 23px;
  transition: border 0.2s linear 0s, color 0.2s linear 0s;
  white-space: nowrap;
}
.clip-check label:before {
  background-color: #ffffff;
  border: 1px solid #c8c7cc;
  content: "";
  display: inline-block;
  height: 20px;
  left: 0;
  margin-right: 10px;
  position: absolute;
  width: 20px;
  border-radius: 0;
  top: 1px;
  transition: border 0.2s linear 0s, color 0.2s linear 0s;
}
.clip-check label:after {
  display: inline-block;
  font-size: 11px;
  height: 19px;
  left: 4px;
  position: absolute;
  top: -1px;
  transition: border 0.2s linear 0s, color 0.2s linear 0s;
  width: 19px;
}
.clip-check input[type="checkbox"] {
  display: none;
}
.clip-check input[type="checkbox"]:checked + label:before {
  border-width: 10px;
}
.clip-check input[type="checkbox"]:checked + label:after {
  color: #fff;
  content: "\f00c";
  font-family: "FontAwesome";
}
.clip-check input[type="checkbox"][disabled] + label {
  opacity: 0.65;
}
.clip-check input[type="checkbox"][disabled] + label:before {
  background-color: #F8F8F8;
}
.clip-check.check-md label {
  margin-right: 15px;
  padding-left: 35px !important;
  line-height: 28px;
}
.clip-check.check-md label:before {
  height: 25px;
  width: 25px;
}
.clip-check.check-md label:after {
  font-size: 14px;
  height: 24px;
  left: 5px;
  width: 24px;
}
.clip-check.check-md input[type="checkbox"]:checked + label:before {
  border-width: 12px;
}
.clip-check.check-lg label {
  margin-right: 15px;
  padding-left: 40px !important;
  line-height: 33px;
}
.clip-check.check-lg label:before {
  height: 30px;
  width: 30px;
}
.clip-check.check-lg label:after {
  font-size: 17px;
  height: 29px;
  left: 6px;
  width: 29px;
}
.clip-check.check-lg input[type="checkbox"]:checked + label:before {
  border-width: 15px;
}

.clip-check.check-success input[type="checkbox"]:checked + label:before {
  border-color: #5cb85c;
}

.clip-check.check-primary input[type="checkbox"]:checked + label:before {
  border-color: #007AFF;
}

.clip-check.check-warning input[type="checkbox"]:checked + label:before {
  border-color: #eea236;
}

.clip-check.check-danger input[type="checkbox"]:checked + label:before {
  border-color: #d43f3a;
}

.clip-check.check-info input[type="checkbox"]:checked + label:before {
  border-color: #46b8da;
}

.clip-check.check-purple input[type="checkbox"]:checked + label:before {
  border-color: #804C75;
}

.clip-radio {
  margin-bottom: 10px;
  margin-top: 10px;
  padding-left: 0;
}
.clip-radio label {
  cursor: pointer;
  display: inline-block;
  font-size: 13px;
  margin-right: 15px;
  padding-left: 25px !important;
  position: relative;
  margin-bottom: 6px;
  line-height: 23px;
}
.clip-radio label:before {
  background-color: #ffffff;
  border: 1px solid #d0d0d0;
  content: "";
  display: inline-block;
  height: 20px;
  left: 0;
  margin-right: 10px;
  position: absolute;
  width: 20px;
  border-radius: 99px;
  bottom: 2px;
  transition: all 0.3s cubic-bezier(0.455, 0.03, 0.215, 1.33) 0s;
}
.clip-radio label:after {
  border: 1px solid #c8c7cc;
  content: "";
  display: inline-block;
  height: 20px;
  left: 0;
  margin-right: 10px;
  position: absolute;
  width: 20px;
  border-radius: 99px;
  bottom: 2px;
  transition: all 0.3s cubic-bezier(0.455, 0.03, 0.215, 1.33) 0s;
}
.clip-radio input[type="radio"] {
  display: none;
}
.clip-radio input[type="radio"]:checked + label:before {
  border-width: 6px;
  background: #F8F8F8;
  border-color: #ffffff;
}
.clip-radio input[type="radio"][disabled] + label {
  opacity: 0.65;
}
.clip-radio.radio-md label {
  padding-left: 30px !important;
  line-height: 28px;
}
.clip-radio.radio-md label:before {
  height: 25px;
  width: 25px;
}
.clip-radio.radio-md label:after {
  height: 25px;
  width: 25px;
}
.clip-radio.radio-md input[type="radio"] {
  display: none;
}
.clip-radio.radio-md input[type="radio"]:checked + label:before {
  border-width: 7px;
}
.clip-radio.radio-lg label {
  padding-left: 35px !important;
  line-height: 33px;
}
.clip-radio.radio-lg label:before {
  height: 30px;
  width: 30px;
}
.clip-radio.radio-lg label:after {
  height: 30px;
  width: 30px;
}
.clip-radio.radio-lg input[type="radio"] {
  display: none;
}
.clip-radio.radio-lg input[type="radio"]:checked + label:before {
  border-width: 8px;
}

.clip-radio.radio-success input[type="radio"]:checked + label:before {
  background: #5cb85c;
}

.clip-radio.radio-primary input[type="radio"]:checked + label:before {
  background: #007AFF;
}

.clip-radio.radio-info input[type="radio"]:checked + label:before {
  background: #46b8da;
}

.clip-radio.radio-warning input[type="radio"]:checked + label:before {
  background: #eea236;
}

.clip-radio.radio-danger input[type="radio"]:checked + label:before {
  background: #d43f3a;
}

.clip-radio.radio-purple input[type="radio"]:checked + label:before {
  background: #804C75;
}

/* ---------------------------------------------------------------------- */
/*  Icons
/* ---------------------------------------------------------------------- */
.fa-round {
  border: 0.1em solid #eee;
  border-radius: 100%;
  padding: 0.2em 0.25em 0.15em;
}

.the-icons {
  list-style: none;
  margin: 20px 0;
  padding: 0;
}
.the-icons .fa-hover {
  display: block;
  height: 32px;
  line-height: 32px;
  padding-left: 10px;
  color: #8B91A0;
  cursor: pointer;
}
.no-touch .the-icons .fa-hover {
  -moz-transition: font-size, 0.2s, ease 0s;
  -o-transition: font-size, 0.2s, ease 0s;
  -webkit-transition: font-size, 0.2s, ease 0s;
  transition: font-size, 0.2s, ease 0s;
}
.no-touch .the-icons .fa-hover:hover {
  font-size: 20px;
}
.no-touch .the-icons .fa-hover:hover .fa, .no-touch .the-icons .fa-hover:hover [class*="ti-"] {
  font-size: 20px;
}
.the-icons .fa-hover .fa, .the-icons .fa-hover [class*="ti-"] {
  display: inline-block;
  font-size: 14px;
  margin-right: 10px;
  text-align: right;
  width: 32px;
  color: #007AFF;
}

.big-icon {
  font-size: 100px;
}

/* ---------------------------------------------------------------------- */
/*  Buttons
/* ---------------------------------------------------------------------- */
.btn {
  transition: all 0.3s ease 0s !important;
  background-image: none !important;
  box-shadow: none !important;
  outline: none !important;
  position: relative;
}

.btn:after {
  content: "";
  position: absolute;
  transition: all 0.3s ease 0s;
  z-index: -1;
}

.btn-wide {
  min-width: 120px;
}

.ladda-button[data-style="contract"][data-loading] {
  border-radius: 50%;
  min-width: 38px !important;
  overflow: hidden;
  width: 38px !important;
}

.btn:hover, .btn:focus, .btn:active, .btn.active, .btn.disabled, .btn[disabled] {
  box-shadow: none;
}

.btn-o {
  background: none !important;
}

.btn-default {
  background-color: #ffffff;
  border-color: #c8c7cc;
  color: #8e8e93;
}
.btn-default:hover {
  background-color: #ffffff;
  border-color: #bbbac0;
  color: #8e8e93 !important;
}
.btn-default:active, .btn-default.active, .btn-default.active:focus, .btn-default:active:focus, .btn-default:active:hover {
  background-color: #f8f8f8;
  border-color: #d5d4d8;
  color: #5b5b60 !important;
}
.btn-default:focus {
  background-color: #ffffff;
  border-color: #bbbac0;
  color: #5b5b60 !important;
}
.btn-default.disabled:hover, .btn-default.disabled:focus, .btn-default.disabled:active, .btn-default.disabled.active, .btn-default[disabled], .btn-default[disabled]:hover, .btn-default[disabled]:focus, .btn-default[disabled]:active, .btn-default[disabled].active, fieldset[disabled] .btn-default:hover, fieldset[disabled] .btn-default:focus, fieldset[disabled] .btn-default:active, fieldset[disabled] .btn-default.active {
  background-color: #ffffff;
  border-color: #e2e2e4;
  color: #cfcfd1;
}
.btn-default .caret {
  border-top-color: #8e8e93;
}
.dropup .btn-default .caret {
  border-bottom: 4px solid #8e8e93;
}

.btn-primary {
  background-color: #007AFF;
  border-color: #007AFF;
  color: #ffffff;
}
.open .btn-primary.dropdown-toggle {
  background-color: #4da2ff;
  border-color: #4da2ff;
}
.btn-primary:hover {
  background-color: #3395ff !important;
  border-color: #3395ff;
  color: #ffffff;
}
.btn-primary:active, .btn-primary.active, .btn-primary.active:focus, .btn-primary:active:focus, .btn-primary:active:hover, .btn-primary.dropdown-toggle:active:hover {
  background-color: #006ee6 !important;
  border-color: #006ee6;
}
.btn-primary:focus {
  background-color: #4da2ff;
  border-color: #4da2ff;
}
.btn-primary.disabled:hover, .btn-primary.disabled:focus, .btn-primary.disabled:active, .btn-primary.disabled.active, .btn-primary[disabled], .btn-primary[disabled]:hover, .btn-primary[disabled]:focus, .btn-primary[disabled]:active, .btn-primary[disabled].active, fieldset[disabled] .btn-primary:hover, fieldset[disabled] .btn-primary:focus, fieldset[disabled] .btn-primary:active, fieldset[disabled] .btn-primary.active {
  background-color: #66afff;
  border-color: #66afff;
  color: #ffffff;
}
.btn-primary.btn-o {
  border: 1px solid #007AFF;
  color: #007AFF;
}
.btn-primary.btn-o:hover {
  color: #3395ff;
  border-color: #3395ff;
  background: none !important;
}
.btn-primary.btn-o:active, .btn-primary.btn-o.active, .btn-primary.btn-o.active:focus, .btn-primary.btn-o:active:focus, .btn-primary.btn-o:active:hover {
  background-color: #eaeaec !important;
  border-color: #0055b3 !important;
  color: #0055b3 !important;
}
.btn-primary.btn-o:focus {
  background-color: #66afff;
  border-color: #66afff;
  color: #3395ff;
}
.btn-primary.btn-o.disabled:hover, .btn-primary.btn-o.disabled:focus, .btn-primary.btn-o.disabled:active, .btn-primary.btn-o.disabled.active, .btn-primary.btn-o[disabled], .btn-primary.btn-o[disabled]:hover, .btn-primary.btn-o[disabled]:focus, .btn-primary.btn-o[disabled]:active, .btn-primary.btn-o[disabled].active, fieldset[disabled] .btn-primary.btn-o:hover, fieldset[disabled] .btn-primary.btn-o:focus, fieldset[disabled] .btn-primary.btn-o:active, fieldset[disabled] .btn-primary.btn-o.active {
  border-color: #66afff;
  color: #66afff;
}
.btn-primary.btn-o .caret {
  border-top-color: #007AFF;
}
.btn-primary .caret {
  border-top-color: #ffffff;
}
.dropup .btn-primary .caret {
  border-bottom: 4px solid #ffffff;
}

.btn-success {
  background-color: #5cb85c;
  border-color: #5cb85c;
  color: #ffffff;
}
.open .btn-success.dropdown-toggle {
  background-color: #91cf91;
  border-color: #91cf91;
}
.btn-success:hover {
  background-color: #80c780 !important;
  border-color: #80c780;
  color: #ffffff;
}
.btn-success:active, .btn-success.active, .btn-success.active:focus, .btn-success:active:focus, .btn-success:active:hover, .btn-success.dropdown-toggle:active:hover {
  background-color: #4cae4c !important;
  border-color: #4cae4c;
}
.btn-success:focus {
  background-color: #91cf91;
  border-color: #91cf91;
}
.btn-success.disabled:hover, .btn-success.disabled:focus, .btn-success.disabled:active, .btn-success.disabled.active, .btn-success[disabled], .btn-success[disabled]:hover, .btn-success[disabled]:focus, .btn-success[disabled]:active, .btn-success[disabled].active, fieldset[disabled] .btn-success:hover, fieldset[disabled] .btn-success:focus, fieldset[disabled] .btn-success:active, fieldset[disabled] .btn-success.active {
  background-color: #a3d7a3;
  border-color: #a3d7a3;
  color: #ffffff;
}
.btn-success.btn-o {
  border: 1px solid #5cb85c;
  color: #5cb85c;
}
.btn-success.btn-o:hover {
  color: #80c780;
  border-color: #80c780;
  background: none !important;
}
.btn-success.btn-o:active, .btn-success.btn-o.active, .btn-success.btn-o.active:focus, .btn-success.btn-o:active:focus, .btn-success.btn-o:active:hover {
  background-color: #eaeaec !important;
  border-color: #3d8b3d !important;
  color: #3d8b3d !important;
}
.btn-success.btn-o:focus {
  background-color: #a3d7a3;
  border-color: #a3d7a3;
  color: #80c780;
}
.btn-success.btn-o.disabled:hover, .btn-success.btn-o.disabled:focus, .btn-success.btn-o.disabled:active, .btn-success.btn-o.disabled.active, .btn-success.btn-o[disabled], .btn-success.btn-o[disabled]:hover, .btn-success.btn-o[disabled]:focus, .btn-success.btn-o[disabled]:active, .btn-success.btn-o[disabled].active, fieldset[disabled] .btn-success.btn-o:hover, fieldset[disabled] .btn-success.btn-o:focus, fieldset[disabled] .btn-success.btn-o:active, fieldset[disabled] .btn-success.btn-o.active {
  border-color: #a3d7a3;
  color: #a3d7a3;
}
.btn-success.btn-o .caret {
  border-top-color: #5cb85c;
}
.btn-success .caret {
  border-top-color: #ffffff;
}
.dropup .btn-success .caret {
  border-bottom: 4px solid #ffffff;
}

.btn-info {
  background-color: #46b8da;
  border-color: #46b8da;
  color: #ffffff;
}
.open .btn-info.dropdown-toggle {
  background-color: #86d0e7;
  border-color: #86d0e7;
}
.btn-info:hover {
  background-color: #71c8e2 !important;
  border-color: #71c8e2;
  color: #ffffff;
}
.btn-info:active, .btn-info.active, .btn-info.active:focus, .btn-info:active:focus, .btn-info:active:hover, .btn-info.dropdown-toggle:active:hover {
  background-color: #31b0d6 !important;
  border-color: #31b0d6;
}
.btn-info:focus {
  background-color: #86d0e7;
  border-color: #86d0e7;
}
.btn-info.disabled:hover, .btn-info.disabled:focus, .btn-info.disabled:active, .btn-info.disabled.active, .btn-info[disabled], .btn-info[disabled]:hover, .btn-info[disabled]:focus, .btn-info[disabled]:active, .btn-info[disabled].active, fieldset[disabled] .btn-info:hover, fieldset[disabled] .btn-info:focus, fieldset[disabled] .btn-info:active, fieldset[disabled] .btn-info.active {
  background-color: #9bd9eb;
  border-color: #9bd9eb;
  color: #ffffff;
}
.btn-info.btn-o {
  border: 1px solid #46b8da;
  color: #46b8da;
}
.btn-info.btn-o:hover {
  color: #71c8e2;
  border-color: #71c8e2;
  background: none !important;
}
.btn-info.btn-o:active, .btn-info.btn-o.active, .btn-info.btn-o.active:focus, .btn-info.btn-o:active:focus, .btn-info.btn-o:active:hover {
  background-color: #eaeaec !important;
  border-color: #2390b0 !important;
  color: #2390b0 !important;
}
.btn-info.btn-o:focus {
  background-color: #9bd9eb;
  border-color: #9bd9eb;
  color: #71c8e2;
}
.btn-info.btn-o.disabled:hover, .btn-info.btn-o.disabled:focus, .btn-info.btn-o.disabled:active, .btn-info.btn-o.disabled.active, .btn-info.btn-o[disabled], .btn-info.btn-o[disabled]:hover, .btn-info.btn-o[disabled]:focus, .btn-info.btn-o[disabled]:active, .btn-info.btn-o[disabled].active, fieldset[disabled] .btn-info.btn-o:hover, fieldset[disabled] .btn-info.btn-o:focus, fieldset[disabled] .btn-info.btn-o:active, fieldset[disabled] .btn-info.btn-o.active {
  border-color: #9bd9eb;
  color: #9bd9eb;
}
.btn-info.btn-o .caret {
  border-top-color: #46b8da;
}
.btn-info .caret {
  border-top-color: #ffffff;
}
.dropup .btn-info .caret {
  border-bottom: 4px solid #ffffff;
}

.btn-warning {
  background-color: #eea236;
  border-color: #eea236;
  color: #ffffff;
}
.open .btn-warning.dropdown-toggle {
  background-color: #f4c37d;
  border-color: #f4c37d;
}
.btn-warning:hover {
  background-color: #f2b865 !important;
  border-color: #f2b865;
  color: #ffffff;
}
.btn-warning:active, .btn-warning.active, .btn-warning.active:focus, .btn-warning:active:focus, .btn-warning:active:hover, .btn-warning.dropdown-toggle:active:hover {
  background-color: #ec971e !important;
  border-color: #ec971e;
}
.btn-warning:focus {
  background-color: #f4c37d;
  border-color: #f4c37d;
}
.btn-warning.disabled:hover, .btn-warning.disabled:focus, .btn-warning.disabled:active, .btn-warning.disabled.active, .btn-warning[disabled], .btn-warning[disabled]:hover, .btn-warning[disabled]:focus, .btn-warning[disabled]:active, .btn-warning[disabled].active, fieldset[disabled] .btn-warning:hover, fieldset[disabled] .btn-warning:focus, fieldset[disabled] .btn-warning:active, fieldset[disabled] .btn-warning.active {
  background-color: #f6ce94;
  border-color: #f6ce94;
  color: #ffffff;
}
.btn-warning.btn-o {
  border: 1px solid #eea236;
  color: #eea236;
}
.btn-warning.btn-o:hover {
  color: #f2b865;
  border-color: #f2b865;
  background: none !important;
}
.btn-warning.btn-o:active, .btn-warning.btn-o.active, .btn-warning.btn-o.active:focus, .btn-warning.btn-o:active:focus, .btn-warning.btn-o:active:hover {
  background-color: #eaeaec !important;
  border-color: #c77c11 !important;
  color: #c77c11 !important;
}
.btn-warning.btn-o:focus {
  background-color: #f6ce94;
  border-color: #f6ce94;
  color: #f2b865;
}
.btn-warning.btn-o.disabled:hover, .btn-warning.btn-o.disabled:focus, .btn-warning.btn-o.disabled:active, .btn-warning.btn-o.disabled.active, .btn-warning.btn-o[disabled], .btn-warning.btn-o[disabled]:hover, .btn-warning.btn-o[disabled]:focus, .btn-warning.btn-o[disabled]:active, .btn-warning.btn-o[disabled].active, fieldset[disabled] .btn-warning.btn-o:hover, fieldset[disabled] .btn-warning.btn-o:focus, fieldset[disabled] .btn-warning.btn-o:active, fieldset[disabled] .btn-warning.btn-o.active {
  border-color: #f6ce94;
  color: #f6ce94;
}
.btn-warning.btn-o .caret {
  border-top-color: #eea236;
}
.btn-warning .caret {
  border-top-color: #ffffff;
}
.dropup .btn-warning .caret {
  border-bottom: 4px solid #ffffff;
}

.btn-danger {
  background-color: #d43f3a;
  border-color: #d43f3a;
  color: #ffffff;
}
.open .btn-danger.dropdown-toggle {
  background-color: #e27c79;
  border-color: #e27c79;
}
.btn-danger:hover {
  background-color: #dd6864 !important;
  border-color: #dd6864;
  color: #ffffff;
}
.btn-danger:active, .btn-danger.active, .btn-danger.active:focus, .btn-danger:active:focus, .btn-danger:active:hover, .btn-danger.dropdown-toggle:active:hover {
  background-color: #c9312c !important;
  border-color: #c9312c;
}
.btn-danger:focus {
  background-color: #e27c79;
  border-color: #e27c79;
}
.btn-danger.disabled:hover, .btn-danger.disabled:focus, .btn-danger.disabled:active, .btn-danger.disabled.active, .btn-danger[disabled], .btn-danger[disabled]:hover, .btn-danger[disabled]:focus, .btn-danger[disabled]:active, .btn-danger[disabled].active, fieldset[disabled] .btn-danger:hover, fieldset[disabled] .btn-danger:focus, fieldset[disabled] .btn-danger:active, fieldset[disabled] .btn-danger.active {
  background-color: #e6918e;
  border-color: #e6918e;
  color: #ffffff;
}
.btn-danger.btn-o {
  border: 1px solid #d43f3a;
  color: #d43f3a;
}
.btn-danger.btn-o:hover {
  color: #dd6864;
  border-color: #dd6864;
  background: none !important;
}
.btn-danger.btn-o:active, .btn-danger.btn-o.active, .btn-danger.btn-o.active:focus, .btn-danger.btn-o:active:focus, .btn-danger.btn-o:active:hover {
  background-color: #eaeaec !important;
  border-color: #9f2723 !important;
  color: #9f2723 !important;
}
.btn-danger.btn-o:focus {
  background-color: #e6918e;
  border-color: #e6918e;
  color: #dd6864;
}
.btn-danger.btn-o.disabled:hover, .btn-danger.btn-o.disabled:focus, .btn-danger.btn-o.disabled:active, .btn-danger.btn-o.disabled.active, .btn-danger.btn-o[disabled], .btn-danger.btn-o[disabled]:hover, .btn-danger.btn-o[disabled]:focus, .btn-danger.btn-o[disabled]:active, .btn-danger.btn-o[disabled].active, fieldset[disabled] .btn-danger.btn-o:hover, fieldset[disabled] .btn-danger.btn-o:focus, fieldset[disabled] .btn-danger.btn-o:active, fieldset[disabled] .btn-danger.btn-o.active {
  border-color: #e6918e;
  color: #e6918e;
}
.btn-danger.btn-o .caret {
  border-top-color: #d43f3a;
}
.btn-danger .caret {
  border-top-color: #ffffff;
}
.dropup .btn-danger .caret {
  border-bottom: 4px solid #ffffff;
}

.btn-azure {
  background-color: #0095C8;
  border-color: #0095C8;
  color: #ffffff;
}
.open .btn-azure.dropdown-toggle {
  background-color: #16c3ff;
  border-color: #16c3ff;
}
.btn-azure:hover {
  background-color: #00bbfb !important;
  border-color: #00bbfb;
  color: #ffffff;
}
.btn-azure:active, .btn-azure.active, .btn-azure.active:focus, .btn-azure:active:focus, .btn-azure:active:hover, .btn-azure.dropdown-toggle:active:hover {
  background-color: #0082af !important;
  border-color: #0082af;
}
.btn-azure:focus {
  background-color: #16c3ff;
  border-color: #16c3ff;
  color: #ffffff;
}
.btn-azure.disabled:hover, .btn-azure.disabled:focus, .btn-azure.disabled:active, .btn-azure.disabled.active, .btn-azure[disabled], .btn-azure[disabled]:hover, .btn-azure[disabled]:focus, .btn-azure[disabled]:active, .btn-azure[disabled].active, fieldset[disabled] .btn-azure:hover, fieldset[disabled] .btn-azure:focus, fieldset[disabled] .btn-azure:active, fieldset[disabled] .btn-azure.active {
  background-color: #2fcaff;
  border-color: #2fcaff;
  color: #ffffff;
}
.btn-azure.btn-o {
  border: 1px solid #0095C8;
  color: #0095C8;
}
.btn-azure.btn-o:hover {
  color: #00bbfb;
  border-color: #00bbfb;
  background: none !important;
}
.btn-azure.btn-o:active, .btn-azure.btn-o.active, .btn-azure.btn-o.active:focus, .btn-azure.btn-o:active:focus, .btn-azure.btn-o:active:hover {
  background-color: #eaeaec !important;
  border-color: #005c7c !important;
  color: #005c7c !important;
}
.btn-azure.btn-o:focus {
  background-color: #2fcaff;
  border-color: #2fcaff;
  color: #00bbfb;
}
.btn-azure.btn-o.disabled:hover, .btn-azure.btn-o.disabled:focus, .btn-azure.btn-o.disabled:active, .btn-azure.btn-o.disabled.active, .btn-azure.btn-o[disabled], .btn-azure.btn-o[disabled]:hover, .btn-azure.btn-o[disabled]:focus, .btn-azure.btn-o[disabled]:active, .btn-azure.btn-o[disabled].active, fieldset[disabled] .btn-azure.btn-o:hover, fieldset[disabled] .btn-azure.btn-o:focus, fieldset[disabled] .btn-azure.btn-o:active, fieldset[disabled] .btn-azure.btn-o.active {
  border-color: #2fcaff;
  color: #2fcaff;
}
.btn-azure.btn-o .caret {
  border-top-color: #0095C8;
}
.btn-azure .caret {
  border-top-color: #ffffff;
}
.dropup .btn-azure .caret {
  border-bottom: 4px solid #ffffff;
}

.btn-light-azure {
  background-color: #00a8e2;
  border-color: #00a8e2;
  color: #ffffff;
}
.open .btn-light-azure.dropdown-toggle {
  background-color: #2fcaff;
  border-color: #2fcaff;
}
.btn-light-azure:hover {
  background-color: #16c3ff !important;
  border-color: #16c3ff;
  color: #ffffff;
}
.btn-light-azure:active, .btn-light-azure.active, .btn-light-azure.active:focus, .btn-light-azure:active:focus, .btn-light-azure:active:hover, .btn-light-azure.dropdown-toggle:active:hover {
  background-color: #0095c8 !important;
  border-color: #0095c8;
}
.btn-light-azure:focus {
  background-color: #2fcaff;
  border-color: #2fcaff;
  color: #ffffff;
}
.btn-light-azure.disabled:hover, .btn-light-azure.disabled:focus, .btn-light-azure.disabled:active, .btn-light-azure.disabled.active, .btn-light-azure[disabled], .btn-light-azure[disabled]:hover, .btn-light-azure[disabled]:focus, .btn-light-azure[disabled]:active, .btn-light-azure[disabled].active, fieldset[disabled] .btn-light-azure:hover, fieldset[disabled] .btn-light-azure:focus, fieldset[disabled] .btn-light-azure:active, fieldset[disabled] .btn-light-azure.active {
  background-color: #49d0ff;
  border-color: #49d0ff;
  color: #ffffff;
}
.btn-light-azure.btn-o {
  border: 1px solid #00a8e2;
  color: #00a8e2;
}
.btn-light-azure.btn-o:hover {
  color: #16c3ff;
  border-color: #16c3ff;
  background: none !important;
}
.btn-light-azure.btn-o:active, .btn-light-azure.btn-o.active, .btn-light-azure.btn-o.active:focus, .btn-light-azure.btn-o:active:focus, .btn-light-azure.btn-o:active:hover {
  background-color: #eaeaec !important;
  border-color: #006f95 !important;
  color: #006f95 !important;
}
.btn-light-azure.btn-o:focus {
  background-color: #49d0ff;
  border-color: #49d0ff;
  color: #16c3ff;
}
.btn-light-azure.btn-o.disabled:hover, .btn-light-azure.btn-o.disabled:focus, .btn-light-azure.btn-o.disabled:active, .btn-light-azure.btn-o.disabled.active, .btn-light-azure.btn-o[disabled], .btn-light-azure.btn-o[disabled]:hover, .btn-light-azure.btn-o[disabled]:focus, .btn-light-azure.btn-o[disabled]:active, .btn-light-azure.btn-o[disabled].active, fieldset[disabled] .btn-light-azure.btn-o:hover, fieldset[disabled] .btn-light-azure.btn-o:focus, fieldset[disabled] .btn-light-azure.btn-o:active, fieldset[disabled] .btn-light-azure.btn-o.active {
  border-color: #49d0ff;
  color: #49d0ff;
}
.btn-light-azure.btn-o .caret {
  border-top-color: #00a8e2;
}
.btn-light-azure .caret {
  border-top-color: #ffffff;
}
.dropup .btn-light-azure .caret {
  border-bottom: 4px solid #ffffff;
}

.btn-dark-azure {
  background-color: #006f95;
  border-color: #006f95;
  color: #ffffff;
}
.open .btn-dark-azure.dropdown-toggle {
  background-color: #00a8e2;
  border-color: #00a8e2;
}
.btn-dark-azure:hover {
  background-color: #0095c8 !important;
  border-color: #0095c8;
  color: #ffffff;
}
.btn-dark-azure:active, .btn-dark-azure.active, .btn-dark-azure.active:focus, .btn-dark-azure:active:focus, .btn-dark-azure:active:hover, .btn-dark-azure.dropdown-toggle:active:hover {
  background-color: #005c7c !important;
  border-color: #005c7c;
}
.btn-dark-azure:focus {
  background-color: #00a8e2;
  border-color: #00a8e2;
  color: #ffffff;
}
.btn-dark-azure.disabled:hover, .btn-dark-azure.disabled:focus, .btn-dark-azure.disabled:active, .btn-dark-azure.disabled.active, .btn-dark-azure[disabled], .btn-dark-azure[disabled]:hover, .btn-dark-azure[disabled]:focus, .btn-dark-azure[disabled]:active, .btn-dark-azure[disabled].active, fieldset[disabled] .btn-dark-azure:hover, fieldset[disabled] .btn-dark-azure:focus, fieldset[disabled] .btn-dark-azure:active, fieldset[disabled] .btn-dark-azure.active {
  background-color: #00bbfb;
  border-color: #00bbfb;
  color: #ffffff;
}
.btn-dark-azure.btn-o {
  border: 1px solid #006f95;
  color: #006f95;
}
.btn-dark-azure.btn-o:hover {
  color: #0095c8;
  border-color: #0095c8;
  background: none !important;
}
.btn-dark-azure.btn-o:active, .btn-dark-azure.btn-o.active, .btn-dark-azure.btn-o.active:focus, .btn-dark-azure.btn-o:active:focus, .btn-dark-azure.btn-o:active:hover {
  background-color: #eaeaec !important;
  border-color: #003649 !important;
  color: #003649 !important;
}
.btn-dark-azure.btn-o:focus {
  background-color: #00bbfb;
  border-color: #00bbfb;
  color: #0095c8;
}
.btn-dark-azure.btn-o.disabled:hover, .btn-dark-azure.btn-o.disabled:focus, .btn-dark-azure.btn-o.disabled:active, .btn-dark-azure.btn-o.disabled.active, .btn-dark-azure.btn-o[disabled], .btn-dark-azure.btn-o[disabled]:hover, .btn-dark-azure.btn-o[disabled]:focus, .btn-dark-azure.btn-o[disabled]:active, .btn-dark-azure.btn-o[disabled].active, fieldset[disabled] .btn-dark-azure.btn-o:hover, fieldset[disabled] .btn-dark-azure.btn-o:focus, fieldset[disabled] .btn-dark-azure.btn-o:active, fieldset[disabled] .btn-dark-azure.btn-o.active {
  border-color: #00bbfb;
  color: #00bbfb;
}
.btn-dark-azure.btn-o .caret {
  border-top-color: #006f95;
}
.btn-dark-azure .caret {
  border-top-color: #ffffff;
}
.dropup .btn-dark-azure .caret {
  border-bottom: 4px solid #ffffff;
}

.btn-blue {
  background-color: #5F8295;
  border-color: #5F8295;
  color: #ffffff;
}
.open .btn-blue.dropdown-toggle {
  background-color: #8ba6b5;
  border-color: #8ba6b5;
}
.btn-blue:hover {
  background-color: #7c9bab !important;
  border-color: #7c9bab;
  color: #ffffff;
}
.btn-blue:active, .btn-blue.active, .btn-blue.active:focus, .btn-blue:active:focus, .btn-blue:active:hover, .btn-blue.dropdown-toggle:active:hover {
  background-color: #557485 !important;
  border-color: #557485;
}
.btn-blue:focus {
  background-color: #8ba6b5;
  border-color: #8ba6b5;
  color: #ffffff;
}
.btn-blue.disabled:hover, .btn-blue.disabled:focus, .btn-blue.disabled:active, .btn-blue.disabled.active, .btn-blue[disabled], .btn-blue[disabled]:hover, .btn-blue[disabled]:focus, .btn-blue[disabled]:active, .btn-blue[disabled].active, fieldset[disabled] .btn-blue:hover, fieldset[disabled] .btn-blue:focus, fieldset[disabled] .btn-blue:active, fieldset[disabled] .btn-blue.active {
  background-color: #9bb2bf;
  border-color: #9bb2bf;
  color: #ffffff;
}
.btn-blue.btn-o {
  border: 1px solid #5F8295;
  color: #5F8295;
}
.btn-blue.btn-o:hover {
  color: #7c9bab;
  border-color: #7c9bab;
  background: none !important;
}
.btn-blue.btn-o:active, .btn-blue.btn-o.active, .btn-blue.btn-o.active:focus, .btn-blue.btn-o:active:focus, .btn-blue.btn-o:active:hover {
  background-color: #eaeaec !important;
  border-color: #415966 !important;
  color: #415966 !important;
}
.btn-blue.btn-o:focus {
  background-color: #9bb2bf;
  border-color: #9bb2bf;
  color: #7c9bab;
}
.btn-blue.btn-o.disabled:hover, .btn-blue.btn-o.disabled:focus, .btn-blue.btn-o.disabled:active, .btn-blue.btn-o.disabled.active, .btn-blue.btn-o[disabled], .btn-blue.btn-o[disabled]:hover, .btn-blue.btn-o[disabled]:focus, .btn-blue.btn-o[disabled]:active, .btn-blue.btn-o[disabled].active, fieldset[disabled] .btn-blue.btn-o:hover, fieldset[disabled] .btn-blue.btn-o:focus, fieldset[disabled] .btn-blue.btn-o:active, fieldset[disabled] .btn-blue.btn-o.active {
  border-color: #9bb2bf;
  color: #9bb2bf;
}
.btn-blue.btn-o .caret {
  border-top-color: #5F8295;
}
.btn-blue .caret {
  border-top-color: #ffffff;
}
.dropup .btn-blue .caret {
  border-bottom: 4px solid #ffffff;
}

.btn-light-blue {
  background-color: #7c9bab;
  border-color: #7c9bab;
  color: #ffffff;
}
.open .btn-light-blue.dropdown-toggle {
  background-color: #aabec9;
  border-color: #aabec9;
}
.btn-light-blue:hover {
  background-color: #9bb2bf !important;
  border-color: #9bb2bf;
  color: #ffffff;
}
.btn-light-blue:active, .btn-light-blue.active, .btn-light-blue.active:focus, .btn-light-blue:active:focus, .btn-light-blue:active:hover, .btn-light-blue.dropdown-toggle:active:hover {
  background-color: #6c8fa1 !important;
  border-color: #6c8fa1;
}
.btn-light-blue:focus {
  background-color: #aabec9;
  border-color: #aabec9;
  color: #ffffff;
}
.btn-light-blue.disabled:hover, .btn-light-blue.disabled:focus, .btn-light-blue.disabled:active, .btn-light-blue.disabled.active, .btn-light-blue[disabled], .btn-light-blue[disabled]:hover, .btn-light-blue[disabled]:focus, .btn-light-blue[disabled]:active, .btn-light-blue[disabled].active, fieldset[disabled] .btn-light-blue:hover, fieldset[disabled] .btn-light-blue:focus, fieldset[disabled] .btn-light-blue:active, fieldset[disabled] .btn-light-blue.active {
  background-color: #bacad3;
  border-color: #bacad3;
  color: #ffffff;
}
.btn-light-blue.btn-o {
  border: 1px solid #7c9bab;
  color: #7c9bab;
}
.btn-light-blue.btn-o:hover {
  color: #9bb2bf;
  border-color: #9bb2bf;
  background: none !important;
}
.btn-light-blue.btn-o:active, .btn-light-blue.btn-o.active, .btn-light-blue.btn-o.active:focus, .btn-light-blue.btn-o:active:focus, .btn-light-blue.btn-o:active:hover {
  background-color: #eaeaec !important;
  border-color: #557485 !important;
  color: #557485 !important;
}
.btn-light-blue.btn-o:focus {
  background-color: #bacad3;
  border-color: #bacad3;
  color: #9bb2bf;
}
.btn-light-blue.btn-o.disabled:hover, .btn-light-blue.btn-o.disabled:focus, .btn-light-blue.btn-o.disabled:active, .btn-light-blue.btn-o.disabled.active, .btn-light-blue.btn-o[disabled], .btn-light-blue.btn-o[disabled]:hover, .btn-light-blue.btn-o[disabled]:focus, .btn-light-blue.btn-o[disabled]:active, .btn-light-blue.btn-o[disabled].active, fieldset[disabled] .btn-light-blue.btn-o:hover, fieldset[disabled] .btn-light-blue.btn-o:focus, fieldset[disabled] .btn-light-blue.btn-o:active, fieldset[disabled] .btn-light-blue.btn-o.active {
  border-color: #bacad3;
  color: #bacad3;
}
.btn-light-blue.btn-o .caret {
  border-top-color: #7c9bab;
}
.btn-light-blue .caret {
  border-top-color: #ffffff;
}
.dropup .btn-light-blue .caret {
  border-bottom: 4px solid #ffffff;
}

.btn-dark-blue {
  background-color: #4b6776;
  border-color: #4b6776;
  color: #ffffff;
}
.open .btn-dark-blue.dropdown-toggle {
  background-color: #6c8fa1;
  border-color: #6c8fa1;
}
.btn-dark-blue:hover {
  background-color: #5f8295 !important;
  border-color: #5f8295;
  color: #ffffff;
}
.btn-dark-blue:active, .btn-dark-blue.active, .btn-dark-blue.active:focus, .btn-dark-blue:active:focus, .btn-dark-blue:active:hover, .btn-dark-blue.dropdown-toggle:active:hover {
  background-color: #415966 !important;
  border-color: #415966;
}
.btn-dark-blue:focus {
  background-color: #6c8fa1;
  border-color: #6c8fa1;
  color: #ffffff;
}
.btn-dark-blue.disabled:hover, .btn-dark-blue.disabled:focus, .btn-dark-blue.disabled:active, .btn-dark-blue.disabled.active, .btn-dark-blue[disabled], .btn-dark-blue[disabled]:hover, .btn-dark-blue[disabled]:focus, .btn-dark-blue[disabled]:active, .btn-dark-blue[disabled].active, fieldset[disabled] .btn-dark-blue:hover, fieldset[disabled] .btn-dark-blue:focus, fieldset[disabled] .btn-dark-blue:active, fieldset[disabled] .btn-dark-blue.active {
  background-color: #7c9bab;
  border-color: #7c9bab;
  color: #ffffff;
}
.btn-dark-blue.btn-o {
  border: 1px solid #4b6776;
  color: #4b6776;
}
.btn-dark-blue.btn-o:hover {
  color: #5f8295;
  border-color: #5f8295;
  background: none !important;
}
.btn-dark-blue.btn-o:active, .btn-dark-blue.btn-o.active, .btn-dark-blue.btn-o.active:focus, .btn-dark-blue.btn-o:active:focus, .btn-dark-blue.btn-o:active:hover {
  background-color: #eaeaec !important;
  border-color: #2d3e47 !important;
  color: #2d3e47 !important;
}
.btn-dark-blue.btn-o:focus {
  background-color: #7c9bab;
  border-color: #7c9bab;
  color: #5f8295;
}
.btn-dark-blue.btn-o.disabled:hover, .btn-dark-blue.btn-o.disabled:focus, .btn-dark-blue.btn-o.disabled:active, .btn-dark-blue.btn-o.disabled.active, .btn-dark-blue.btn-o[disabled], .btn-dark-blue.btn-o[disabled]:hover, .btn-dark-blue.btn-o[disabled]:focus, .btn-dark-blue.btn-o[disabled]:active, .btn-dark-blue.btn-o[disabled].active, fieldset[disabled] .btn-dark-blue.btn-o:hover, fieldset[disabled] .btn-dark-blue.btn-o:focus, fieldset[disabled] .btn-dark-blue.btn-o:active, fieldset[disabled] .btn-dark-blue.btn-o.active {
  border-color: #7c9bab;
  color: #7c9bab;
}
.btn-dark-blue.btn-o .caret {
  border-top-color: #4b6776;
}
.btn-dark-blue .caret {
  border-top-color: #ffffff;
}
.dropup .btn-dark-blue .caret {
  border-bottom: 4px solid #ffffff;
}

.btn-green {
  background-color: #1FBBA6;
  border-color: #1FBBA6;
  color: #ffffff;
}
.open .btn-green.dropdown-toggle {
  background-color: #46e0cc;
  border-color: #46e0cc;
}
.btn-green:hover {
  background-color: #30ddc6 !important;
  border-color: #30ddc6;
  color: #ffffff;
}
.btn-green:active, .btn-green.active, .btn-green.active:focus, .btn-green:active:focus, .btn-green:active:hover, .btn-green.dropdown-toggle:active:hover {
  background-color: #1ba593 !important;
  border-color: #1ba593;
}
.btn-green:focus {
  background-color: #46e0cc;
  border-color: #46e0cc;
  color: #ffffff;
}
.btn-green.disabled:hover, .btn-green.disabled:focus, .btn-green.disabled:active, .btn-green.disabled.active, .btn-green[disabled], .btn-green[disabled]:hover, .btn-green[disabled]:focus, .btn-green[disabled]:active, .btn-green[disabled].active, fieldset[disabled] .btn-green:hover, fieldset[disabled] .btn-green:focus, fieldset[disabled] .btn-green:active, fieldset[disabled] .btn-green.active {
  background-color: #5ce4d2;
  border-color: #5ce4d2;
  color: #ffffff;
}
.btn-green.btn-o {
  border: 1px solid #1FBBA6;
  color: #1FBBA6;
}
.btn-green.btn-o:hover {
  color: #30ddc6;
  border-color: #30ddc6;
  background: none !important;
}
.btn-green.btn-o:active, .btn-green.btn-o.active, .btn-green.btn-o.active:focus, .btn-green.btn-o:active:focus, .btn-green.btn-o:active:hover {
  background-color: #eaeaec !important;
  border-color: #14796c !important;
  color: #14796c !important;
}
.btn-green.btn-o:focus {
  background-color: #5ce4d2;
  border-color: #5ce4d2;
  color: #30ddc6;
}
.btn-green.btn-o.disabled:hover, .btn-green.btn-o.disabled:focus, .btn-green.btn-o.disabled:active, .btn-green.btn-o.disabled.active, .btn-green.btn-o[disabled], .btn-green.btn-o[disabled]:hover, .btn-green.btn-o[disabled]:focus, .btn-green.btn-o[disabled]:active, .btn-green.btn-o[disabled].active, fieldset[disabled] .btn-green.btn-o:hover, fieldset[disabled] .btn-green.btn-o:focus, fieldset[disabled] .btn-green.btn-o:active, fieldset[disabled] .btn-green.btn-o.active {
  border-color: #5ce4d2;
  color: #5ce4d2;
}
.btn-green.btn-o .caret {
  border-top-color: #1FBBA6;
}
.btn-green .caret {
  border-top-color: #ffffff;
}
.dropup .btn-green .caret {
  border-bottom: 4px solid #ffffff;
}

.btn-light-green {
  background-color: #30ddc6;
  border-color: #30ddc6;
  color: #ffffff;
}
.open .btn-light-green.dropdown-toggle {
  background-color: #72e8d8;
  border-color: #72e8d8;
}
.btn-light-green:hover {
  background-color: #5ce4d2 !important;
  border-color: #5ce4d2;
  color: #ffffff;
}
.btn-light-green:active, .btn-light-green.active, .btn-light-green.active:focus, .btn-light-green:active:focus, .btn-light-green:active:hover, .btn-light-green.dropdown-toggle:active:hover {
  background-color: #23d1b9 !important;
  border-color: #23d1b9;
}
.btn-light-green:focus {
  background-color: #72e8d8;
  border-color: #72e8d8;
  color: #ffffff;
}
.btn-light-green.disabled:hover, .btn-light-green.disabled:focus, .btn-light-green.disabled:active, .btn-light-green.disabled.active, .btn-light-green[disabled], .btn-light-green[disabled]:hover, .btn-light-green[disabled]:focus, .btn-light-green[disabled]:active, .btn-light-green[disabled].active, fieldset[disabled] .btn-light-green:hover, fieldset[disabled] .btn-light-green:focus, fieldset[disabled] .btn-light-green:active, fieldset[disabled] .btn-light-green.active {
  background-color: #88ebde;
  border-color: #88ebde;
  color: #ffffff;
}
.btn-light-green.btn-o {
  border: 1px solid #30ddc6;
  color: #30ddc6;
}
.btn-light-green.btn-o:hover {
  color: #5ce4d2;
  border-color: #5ce4d2;
  background: none !important;
}
.btn-light-green.btn-o:active, .btn-light-green.btn-o.active, .btn-light-green.btn-o.active:focus, .btn-light-green.btn-o:active:focus, .btn-light-green.btn-o:active:hover {
  background-color: #eaeaec !important;
  border-color: #1ba593 !important;
  color: #1ba593 !important;
}
.btn-light-green.btn-o:focus {
  background-color: #88ebde;
  border-color: #88ebde;
  color: #5ce4d2;
}
.btn-light-green.btn-o.disabled:hover, .btn-light-green.btn-o.disabled:focus, .btn-light-green.btn-o.disabled:active, .btn-light-green.btn-o.disabled.active, .btn-light-green.btn-o[disabled], .btn-light-green.btn-o[disabled]:hover, .btn-light-green.btn-o[disabled]:focus, .btn-light-green.btn-o[disabled]:active, .btn-light-green.btn-o[disabled].active, fieldset[disabled] .btn-light-green.btn-o:hover, fieldset[disabled] .btn-light-green.btn-o:focus, fieldset[disabled] .btn-light-green.btn-o:active, fieldset[disabled] .btn-light-green.btn-o.active {
  border-color: #88ebde;
  color: #88ebde;
}
.btn-light-green.btn-o .caret {
  border-top-color: #30ddc6;
}
.btn-light-green .caret {
  border-top-color: #ffffff;
}
.dropup .btn-light-green .caret {
  border-bottom: 4px solid #ffffff;
}

.btn-dark-green {
  background-color: #188f7f;
  border-color: #188f7f;
  color: #ffffff;
}
.open .btn-dark-green.dropdown-toggle {
  background-color: #23d1b9;
  border-color: #23d1b9;
}
.btn-dark-green:hover {
  background-color: #1fbba6 !important;
  border-color: #1fbba6;
  color: #ffffff;
}
.btn-dark-green:active, .btn-dark-green.active, .btn-dark-green.active:focus, .btn-dark-green:active:focus, .btn-dark-green:active:hover, .btn-dark-green.dropdown-toggle:active:hover {
  background-color: #14796c !important;
  border-color: #14796c;
}
.btn-dark-green:focus {
  background-color: #23d1b9;
  border-color: #23d1b9;
  color: #ffffff;
}
.btn-dark-green.disabled:hover, .btn-dark-green.disabled:focus, .btn-dark-green.disabled:active, .btn-dark-green.disabled.active, .btn-dark-green[disabled], .btn-dark-green[disabled]:hover, .btn-dark-green[disabled]:focus, .btn-dark-green[disabled]:active, .btn-dark-green[disabled].active, fieldset[disabled] .btn-dark-green:hover, fieldset[disabled] .btn-dark-green:focus, fieldset[disabled] .btn-dark-green:active, fieldset[disabled] .btn-dark-green.active {
  background-color: #30ddc6;
  border-color: #30ddc6;
  color: #ffffff;
}
.btn-dark-green.btn-o {
  border: 1px solid #188f7f;
  color: #188f7f;
}
.btn-dark-green.btn-o:hover {
  color: #1fbba6;
  border-color: #1fbba6;
  background: none !important;
}
.btn-dark-green.btn-o:active, .btn-dark-green.btn-o.active, .btn-dark-green.btn-o.active:focus, .btn-dark-green.btn-o:active:focus, .btn-dark-green.btn-o:active:hover {
  background-color: #eaeaec !important;
  border-color: #0d4e45 !important;
  color: #0d4e45 !important;
}
.btn-dark-green.btn-o:focus {
  background-color: #30ddc6;
  border-color: #30ddc6;
  color: #1fbba6;
}
.btn-dark-green.btn-o.disabled:hover, .btn-dark-green.btn-o.disabled:focus, .btn-dark-green.btn-o.disabled:active, .btn-dark-green.btn-o.disabled.active, .btn-dark-green.btn-o[disabled], .btn-dark-green.btn-o[disabled]:hover, .btn-dark-green.btn-o[disabled]:focus, .btn-dark-green.btn-o[disabled]:active, .btn-dark-green.btn-o[disabled].active, fieldset[disabled] .btn-dark-green.btn-o:hover, fieldset[disabled] .btn-dark-green.btn-o:focus, fieldset[disabled] .btn-dark-green.btn-o:active, fieldset[disabled] .btn-dark-green.btn-o.active {
  border-color: #30ddc6;
  color: #30ddc6;
}
.btn-dark-green.btn-o .caret {
  border-top-color: #188f7f;
}
.btn-dark-green .caret {
  border-top-color: #ffffff;
}
.dropup .btn-dark-green .caret {
  border-bottom: 4px solid #ffffff;
}

.btn-orange {
  background-color: #FF6600;
  border-color: #FF6600;
  color: #ffffff;
}
.open .btn-orange.dropdown-toggle {
  background-color: #ff944d;
  border-color: #ff944d;
}
.btn-orange:hover {
  background-color: #ff8533 !important;
  border-color: #ff8533;
  color: #ffffff;
}
.btn-orange:active, .btn-orange.active, .btn-orange.active:focus, .btn-orange:active:focus, .btn-orange:active:hover, .btn-orange.dropdown-toggle:active:hover {
  background-color: #e65c00 !important;
  border-color: #e65c00;
}
.btn-orange:focus {
  background-color: #ff944d;
  border-color: #ff944d;
  color: #ffffff;
}
.btn-orange.disabled:hover, .btn-orange.disabled:focus, .btn-orange.disabled:active, .btn-orange.disabled.active, .btn-orange[disabled], .btn-orange[disabled]:hover, .btn-orange[disabled]:focus, .btn-orange[disabled]:active, .btn-orange[disabled].active, fieldset[disabled] .btn-orange:hover, fieldset[disabled] .btn-orange:focus, fieldset[disabled] .btn-orange:active, fieldset[disabled] .btn-orange.active {
  background-color: #ffa366;
  border-color: #ffa366;
  color: #ffffff;
}
.btn-orange.btn-o {
  border: 1px solid #FF6600;
  color: #FF6600;
}
.btn-orange.btn-o:hover {
  color: #ff8533;
  border-color: #ff8533;
  background: none !important;
}
.btn-orange.btn-o:active, .btn-orange.btn-o.active, .btn-orange.btn-o.active:focus, .btn-orange.btn-o:active:focus, .btn-orange.btn-o:active:hover {
  background-color: #eaeaec !important;
  border-color: #b34700 !important;
  color: #b34700 !important;
}
.btn-orange.btn-o:focus {
  background-color: #ffa366;
  border-color: #ffa366;
  color: #ff8533;
}
.btn-orange.btn-o.disabled:hover, .btn-orange.btn-o.disabled:focus, .btn-orange.btn-o.disabled:active, .btn-orange.btn-o.disabled.active, .btn-orange.btn-o[disabled], .btn-orange.btn-o[disabled]:hover, .btn-orange.btn-o[disabled]:focus, .btn-orange.btn-o[disabled]:active, .btn-orange.btn-o[disabled].active, fieldset[disabled] .btn-orange.btn-o:hover, fieldset[disabled] .btn-orange.btn-o:focus, fieldset[disabled] .btn-orange.btn-o:active, fieldset[disabled] .btn-orange.btn-o.active {
  border-color: #ffa366;
  color: #ffa366;
}
.btn-orange.btn-o .caret {
  border-top-color: #FF6600;
}
.btn-orange .caret {
  border-top-color: #ffffff;
}
.dropup .btn-orange .caret {
  border-bottom: 4px solid #ffffff;
}

.btn-light-orange {
  background-color: #ff8533;
  border-color: #ff8533;
  color: #ffffff;
}
.open .btn-light-orange.dropdown-toggle {
  background-color: #ffb380;
  border-color: #ffb380;
}
.btn-light-orange:hover {
  background-color: #ffa366 !important;
  border-color: #ffa366;
  color: #ffffff;
}
.btn-light-orange:active, .btn-light-orange.active, .btn-light-orange.active:focus, .btn-light-orange:active:focus, .btn-light-orange:active:hover, .btn-light-orange.dropdown-toggle:active:hover {
  background-color: #ff751a !important;
  border-color: #ff751a;
}
.btn-light-orange:focus {
  background-color: #ffb380;
  border-color: #ffb380;
  color: #ffffff;
}
.btn-light-orange.disabled:hover, .btn-light-orange.disabled:focus, .btn-light-orange.disabled:active, .btn-light-orange.disabled.active, .btn-light-orange[disabled], .btn-light-orange[disabled]:hover, .btn-light-orange[disabled]:focus, .btn-light-orange[disabled]:active, .btn-light-orange[disabled].active, fieldset[disabled] .btn-light-orange:hover, fieldset[disabled] .btn-light-orange:focus, fieldset[disabled] .btn-light-orange:active, fieldset[disabled] .btn-light-orange.active {
  background-color: #ffc299;
  border-color: #ffc299;
  color: #ffffff;
}
.btn-light-orange.btn-o {
  border: 1px solid #ff8533;
  color: #ff8533;
}
.btn-light-orange.btn-o:hover {
  color: #ffa366;
  border-color: #ffa366;
  background: none !important;
}
.btn-light-orange.btn-o:active, .btn-light-orange.btn-o.active, .btn-light-orange.btn-o.active:focus, .btn-light-orange.btn-o:active:focus, .btn-light-orange.btn-o:active:hover {
  background-color: #eaeaec !important;
  border-color: #e65c00 !important;
  color: #e65c00 !important;
}
.btn-light-orange.btn-o:focus {
  background-color: #ffc299;
  border-color: #ffc299;
  color: #ffa366;
}
.btn-light-orange.btn-o.disabled:hover, .btn-light-orange.btn-o.disabled:focus, .btn-light-orange.btn-o.disabled:active, .btn-light-orange.btn-o.disabled.active, .btn-light-orange.btn-o[disabled], .btn-light-orange.btn-o[disabled]:hover, .btn-light-orange.btn-o[disabled]:focus, .btn-light-orange.btn-o[disabled]:active, .btn-light-orange.btn-o[disabled].active, fieldset[disabled] .btn-light-orange.btn-o:hover, fieldset[disabled] .btn-light-orange.btn-o:focus, fieldset[disabled] .btn-light-orange.btn-o:active, fieldset[disabled] .btn-light-orange.btn-o.active {
  border-color: #ffc299;
  color: #ffc299;
}
.btn-light-orange.btn-o .caret {
  border-top-color: #ff8533;
}
.btn-light-orange .caret {
  border-top-color: #ffffff;
}
.dropup .btn-light-orange .caret {
  border-bottom: 4px solid #ffffff;
}

.btn-dark-orange {
  background-color: #cc5200;
  border-color: #cc5200;
  color: #ffffff;
}
.open .btn-dark-orange.dropdown-toggle {
  background-color: #ff751a;
  border-color: #ff751a;
}
.btn-dark-orange:hover {
  background-color: #ff6600 !important;
  border-color: #ff6600;
  color: #ffffff;
}
.btn-dark-orange:active, .btn-dark-orange.active, .btn-dark-orange.active:focus, .btn-dark-orange:active:focus, .btn-dark-orange:active:hover, .btn-dark-orange.dropdown-toggle:active:hover {
  background-color: #b34700 !important;
  border-color: #b34700;
}
.btn-dark-orange:focus {
  background-color: #ff751a;
  border-color: #ff751a;
  color: #ffffff;
}
.btn-dark-orange.disabled:hover, .btn-dark-orange.disabled:focus, .btn-dark-orange.disabled:active, .btn-dark-orange.disabled.active, .btn-dark-orange[disabled], .btn-dark-orange[disabled]:hover, .btn-dark-orange[disabled]:focus, .btn-dark-orange[disabled]:active, .btn-dark-orange[disabled].active, fieldset[disabled] .btn-dark-orange:hover, fieldset[disabled] .btn-dark-orange:focus, fieldset[disabled] .btn-dark-orange:active, fieldset[disabled] .btn-dark-orange.active {
  background-color: #ff8533;
  border-color: #ff8533;
  color: #ffffff;
}
.btn-dark-orange.btn-o {
  border: 1px solid #cc5200;
  color: #cc5200;
}
.btn-dark-orange.btn-o:hover {
  color: #ff6600;
  border-color: #ff6600;
  background: none !important;
}
.btn-dark-orange.btn-o:active, .btn-dark-orange.btn-o.active, .btn-dark-orange.btn-o.active:focus, .btn-dark-orange.btn-o:active:focus, .btn-dark-orange.btn-o:active:hover {
  background-color: #eaeaec !important;
  border-color: #803300 !important;
  color: #803300 !important;
}
.btn-dark-orange.btn-o:focus {
  background-color: #ff8533;
  border-color: #ff8533;
  color: #ff6600;
}
.btn-dark-orange.btn-o.disabled:hover, .btn-dark-orange.btn-o.disabled:focus, .btn-dark-orange.btn-o.disabled:active, .btn-dark-orange.btn-o.disabled.active, .btn-dark-orange.btn-o[disabled], .btn-dark-orange.btn-o[disabled]:hover, .btn-dark-orange.btn-o[disabled]:focus, .btn-dark-orange.btn-o[disabled]:active, .btn-dark-orange.btn-o[disabled].active, fieldset[disabled] .btn-dark-orange.btn-o:hover, fieldset[disabled] .btn-dark-orange.btn-o:focus, fieldset[disabled] .btn-dark-orange.btn-o:active, fieldset[disabled] .btn-dark-orange.btn-o.active {
  border-color: #ff8533;
  color: #ff8533;
}
.btn-dark-orange.btn-o .caret {
  border-top-color: #cc5200;
}
.btn-dark-orange .caret {
  border-top-color: #ffffff;
}
.dropup .btn-dark-orange .caret {
  border-bottom: 4px solid #ffffff;
}

.btn-red {
  background-color: #C82E29;
  border-color: #C82E29;
  color: #ffffff;
}
.open .btn-red.dropdown-toggle {
  background-color: #de635f;
  border-color: #de635f;
}
.btn-red:hover {
  background-color: #da4f4a !important;
  border-color: #da4f4a;
  color: #ffffff;
}
.btn-red:active, .btn-red.active, .btn-red.active:focus, .btn-red:active:focus, .btn-red:active:hover, .btn-red.dropdown-toggle:active:hover {
  background-color: #b32925 !important;
  border-color: #b32925;
}
.btn-red:focus {
  background-color: #de635f;
  border-color: #de635f;
  color: #ffffff;
}
.btn-red.disabled:hover, .btn-red.disabled:focus, .btn-red.disabled:active, .btn-red.disabled.active, .btn-red[disabled], .btn-red[disabled]:hover, .btn-red[disabled]:focus, .btn-red[disabled]:active, .btn-red[disabled].active, fieldset[disabled] .btn-red:hover, fieldset[disabled] .btn-red:focus, fieldset[disabled] .btn-red:active, fieldset[disabled] .btn-red.active {
  background-color: #e37874;
  border-color: #e37874;
  color: #ffffff;
}
.btn-red.btn-o {
  border: 1px solid #C82E29;
  color: #C82E29;
}
.btn-red.btn-o:hover {
  color: #da4f4a;
  border-color: #da4f4a;
  background: none !important;
}
.btn-red.btn-o:active, .btn-red.btn-o.active, .btn-red.btn-o.active:focus, .btn-red.btn-o:active:focus, .btn-red.btn-o:active:hover {
  background-color: #eaeaec !important;
  border-color: #891f1c !important;
  color: #891f1c !important;
}
.btn-red.btn-o:focus {
  background-color: #e37874;
  border-color: #e37874;
  color: #da4f4a;
}
.btn-red.btn-o.disabled:hover, .btn-red.btn-o.disabled:focus, .btn-red.btn-o.disabled:active, .btn-red.btn-o.disabled.active, .btn-red.btn-o[disabled], .btn-red.btn-o[disabled]:hover, .btn-red.btn-o[disabled]:focus, .btn-red.btn-o[disabled]:active, .btn-red.btn-o[disabled].active, fieldset[disabled] .btn-red.btn-o:hover, fieldset[disabled] .btn-red.btn-o:focus, fieldset[disabled] .btn-red.btn-o:active, fieldset[disabled] .btn-red.btn-o.active {
  border-color: #e37874;
  color: #e37874;
}
.btn-red.btn-o .caret {
  border-top-color: #C82E29;
}
.btn-red .caret {
  border-top-color: #ffffff;
}
.dropup .btn-red .caret {
  border-bottom: 4px solid #ffffff;
}

.btn-light-red {
  background-color: #da4f4a;
  border-color: #da4f4a;
  color: #ffffff;
}
.open .btn-light-red.dropdown-toggle {
  background-color: #e78d8a;
  border-color: #e78d8a;
}
.btn-light-red:hover {
  background-color: #e37874 !important;
  border-color: #e37874;
  color: #ffffff;
}
.btn-light-red:active, .btn-light-red.active, .btn-light-red.active:focus, .btn-light-red:active:focus, .btn-light-red:active:hover, .btn-light-red.dropdown-toggle:active:hover {
  background-color: #d63a35 !important;
  border-color: #d63a35;
}
.btn-light-red:focus {
  background-color: #e78d8a;
  border-color: #e78d8a;
  color: #ffffff;
}
.btn-light-red.disabled:hover, .btn-light-red.disabled:focus, .btn-light-red.disabled:active, .btn-light-red.disabled.active, .btn-light-red[disabled], .btn-light-red[disabled]:hover, .btn-light-red[disabled]:focus, .btn-light-red[disabled]:active, .btn-light-red[disabled].active, fieldset[disabled] .btn-light-red:hover, fieldset[disabled] .btn-light-red:focus, fieldset[disabled] .btn-light-red:active, fieldset[disabled] .btn-light-red.active {
  background-color: #eba19f;
  border-color: #eba19f;
  color: #ffffff;
}
.btn-light-red.btn-o {
  border: 1px solid #da4f4a;
  color: #da4f4a;
}
.btn-light-red.btn-o:hover {
  color: #e37874;
  border-color: #e37874;
  background: none !important;
}
.btn-light-red.btn-o:active, .btn-light-red.btn-o.active, .btn-light-red.btn-o.active:focus, .btn-light-red.btn-o:active:focus, .btn-light-red.btn-o:active:hover {
  background-color: #eaeaec !important;
  border-color: #b32925 !important;
  color: #b32925 !important;
}
.btn-light-red.btn-o:focus {
  background-color: #eba19f;
  border-color: #eba19f;
  color: #e37874;
}
.btn-light-red.btn-o.disabled:hover, .btn-light-red.btn-o.disabled:focus, .btn-light-red.btn-o.disabled:active, .btn-light-red.btn-o.disabled.active, .btn-light-red.btn-o[disabled], .btn-light-red.btn-o[disabled]:hover, .btn-light-red.btn-o[disabled]:focus, .btn-light-red.btn-o[disabled]:active, .btn-light-red.btn-o[disabled].active, fieldset[disabled] .btn-light-red.btn-o:hover, fieldset[disabled] .btn-light-red.btn-o:focus, fieldset[disabled] .btn-light-red.btn-o:active, fieldset[disabled] .btn-light-red.btn-o.active {
  border-color: #eba19f;
  color: #eba19f;
}
.btn-light-red.btn-o .caret {
  border-top-color: #da4f4a;
}
.btn-light-red .caret {
  border-top-color: #ffffff;
}
.dropup .btn-light-red .caret {
  border-bottom: 4px solid #ffffff;
}

.btn-dark-red {
  background-color: #9e2420;
  border-color: #9e2420;
  color: #ffffff;
}
.open .btn-dark-red.dropdown-toggle {
  background-color: #d63a35;
  border-color: #d63a35;
}
.btn-dark-red:hover {
  background-color: #c82e29 !important;
  border-color: #c82e29;
  color: #ffffff;
}
.btn-dark-red:active, .btn-dark-red.active, .btn-dark-red.active:focus, .btn-dark-red:active:focus, .btn-dark-red:active:hover, .btn-dark-red.dropdown-toggle:active:hover {
  background-color: #891f1c !important;
  border-color: #891f1c;
}
.btn-dark-red:focus {
  background-color: #d63a35;
  border-color: #d63a35;
  color: #ffffff;
}
.btn-dark-red.disabled:hover, .btn-dark-red.disabled:focus, .btn-dark-red.disabled:active, .btn-dark-red.disabled.active, .btn-dark-red[disabled], .btn-dark-red[disabled]:hover, .btn-dark-red[disabled]:focus, .btn-dark-red[disabled]:active, .btn-dark-red[disabled].active, fieldset[disabled] .btn-dark-red:hover, fieldset[disabled] .btn-dark-red:focus, fieldset[disabled] .btn-dark-red:active, fieldset[disabled] .btn-dark-red.active {
  background-color: #da4f4a;
  border-color: #da4f4a;
  color: #ffffff;
}
.btn-dark-red.btn-o {
  border: 1px solid #9e2420;
  color: #9e2420;
}
.btn-dark-red.btn-o:hover {
  color: #c82e29;
  border-color: #c82e29;
  background: none !important;
}
.btn-dark-red.btn-o:active, .btn-dark-red.btn-o.active, .btn-dark-red.btn-o.active:focus, .btn-dark-red.btn-o:active:focus, .btn-dark-red.btn-o:active:hover {
  background-color: #eaeaec !important;
  border-color: #5e1613 !important;
  color: #5e1613 !important;
}
.btn-dark-red.btn-o:focus {
  background-color: #da4f4a;
  border-color: #da4f4a;
  color: #c82e29;
}
.btn-dark-red.btn-o.disabled:hover, .btn-dark-red.btn-o.disabled:focus, .btn-dark-red.btn-o.disabled:active, .btn-dark-red.btn-o.disabled.active, .btn-dark-red.btn-o[disabled], .btn-dark-red.btn-o[disabled]:hover, .btn-dark-red.btn-o[disabled]:focus, .btn-dark-red.btn-o[disabled]:active, .btn-dark-red.btn-o[disabled].active, fieldset[disabled] .btn-dark-red.btn-o:hover, fieldset[disabled] .btn-dark-red.btn-o:focus, fieldset[disabled] .btn-dark-red.btn-o:active, fieldset[disabled] .btn-dark-red.btn-o.active {
  border-color: #da4f4a;
  color: #da4f4a;
}
.btn-dark-red.btn-o .caret {
  border-top-color: #9e2420;
}
.btn-dark-red .caret {
  border-top-color: #ffffff;
}
.dropup .btn-dark-red .caret {
  border-bottom: 4px solid #ffffff;
}

.btn-purple {
  background-color: #804C75;
  border-color: #804C75;
  color: #ffffff;
}
.open .btn-purple.dropdown-toggle {
  background-color: #aa6f9d;
  border-color: #aa6f9d;
}
.btn-purple:hover {
  background-color: #a05f92 !important;
  border-color: #a05f92;
  color: #ffffff;
}
.btn-purple:active, .btn-purple.active, .btn-purple.active:focus, .btn-purple:active:focus, .btn-purple:active:hover, .btn-purple.dropdown-toggle:active:hover {
  background-color: #704366 !important;
  border-color: #704366;
}
.btn-purple:focus {
  background-color: #aa6f9d;
  border-color: #aa6f9d;
  color: #ffffff;
}
.btn-purple.disabled:hover, .btn-purple.disabled:focus, .btn-purple.disabled:active, .btn-purple.disabled.active, .btn-purple[disabled], .btn-purple[disabled]:hover, .btn-purple[disabled]:focus, .btn-purple[disabled]:active, .btn-purple[disabled].active, fieldset[disabled] .btn-purple:hover, fieldset[disabled] .btn-purple:focus, fieldset[disabled] .btn-purple:active, fieldset[disabled] .btn-purple.active {
  background-color: #b37fa8;
  border-color: #b37fa8;
  color: #ffffff;
}
.btn-purple.btn-o {
  border: 1px solid #804C75;
  color: #804C75;
}
.btn-purple.btn-o:hover {
  color: #a05f92;
  border-color: #a05f92;
  background: none !important;
}
.btn-purple.btn-o:active, .btn-purple.btn-o.active, .btn-purple.btn-o.active:focus, .btn-purple.btn-o:active:focus, .btn-purple.btn-o:active:hover {
  background-color: #eaeaec !important;
  border-color: #503049 !important;
  color: #503049 !important;
}
.btn-purple.btn-o:focus {
  background-color: #b37fa8;
  border-color: #b37fa8;
  color: #a05f92;
}
.btn-purple.btn-o.disabled:hover, .btn-purple.btn-o.disabled:focus, .btn-purple.btn-o.disabled:active, .btn-purple.btn-o.disabled.active, .btn-purple.btn-o[disabled], .btn-purple.btn-o[disabled]:hover, .btn-purple.btn-o[disabled]:focus, .btn-purple.btn-o[disabled]:active, .btn-purple.btn-o[disabled].active, fieldset[disabled] .btn-purple.btn-o:hover, fieldset[disabled] .btn-purple.btn-o:focus, fieldset[disabled] .btn-purple.btn-o:active, fieldset[disabled] .btn-purple.btn-o.active {
  border-color: #b37fa8;
  color: #b37fa8;
}
.btn-purple.btn-o .caret {
  border-top-color: #804C75;
}
.btn-purple .caret {
  border-top-color: #ffffff;
}
.dropup .btn-purple .caret {
  border-bottom: 4px solid #ffffff;
}

.btn-light-purple {
  background-color: #a05f92;
  border-color: #a05f92;
  color: #ffffff;
}
.open .btn-light-purple.dropdown-toggle {
  background-color: #bd8fb3;
  border-color: #bd8fb3;
}
.btn-light-purple:hover {
  background-color: #b37fa8 !important;
  border-color: #b37fa8;
  color: #ffffff;
}
.btn-light-purple:active, .btn-light-purple.active, .btn-light-purple.active:focus, .btn-light-purple:active:focus, .btn-light-purple:active:hover, .btn-light-purple.dropdown-toggle:active:hover {
  background-color: #905684 !important;
  border-color: #905684;
}
.btn-light-purple:focus {
  background-color: #bd8fb3;
  border-color: #bd8fb3;
  color: #ffffff;
}
.btn-light-purple.disabled:hover, .btn-light-purple.disabled:focus, .btn-light-purple.disabled:active, .btn-light-purple.disabled.active, .btn-light-purple[disabled], .btn-light-purple[disabled]:hover, .btn-light-purple[disabled]:focus, .btn-light-purple[disabled]:active, .btn-light-purple[disabled].active, fieldset[disabled] .btn-light-purple:hover, fieldset[disabled] .btn-light-purple:focus, fieldset[disabled] .btn-light-purple:active, fieldset[disabled] .btn-light-purple.active {
  background-color: #c69fbe;
  border-color: #c69fbe;
  color: #ffffff;
}
.btn-light-purple.btn-o {
  border: 1px solid #a05f92;
  color: #a05f92;
}
.btn-light-purple.btn-o:hover {
  color: #b37fa8;
  border-color: #b37fa8;
  background: none !important;
}
.btn-light-purple.btn-o:active, .btn-light-purple.btn-o.active, .btn-light-purple.btn-o.active:focus, .btn-light-purple.btn-o:active:focus, .btn-light-purple.btn-o:active:hover {
  background-color: #eaeaec !important;
  border-color: #704366 !important;
  color: #704366 !important;
}
.btn-light-purple.btn-o:focus {
  background-color: #c69fbe;
  border-color: #c69fbe;
  color: #b37fa8;
}
.btn-light-purple.btn-o.disabled:hover, .btn-light-purple.btn-o.disabled:focus, .btn-light-purple.btn-o.disabled:active, .btn-light-purple.btn-o.disabled.active, .btn-light-purple.btn-o[disabled], .btn-light-purple.btn-o[disabled]:hover, .btn-light-purple.btn-o[disabled]:focus, .btn-light-purple.btn-o[disabled]:active, .btn-light-purple.btn-o[disabled].active, fieldset[disabled] .btn-light-purple.btn-o:hover, fieldset[disabled] .btn-light-purple.btn-o:focus, fieldset[disabled] .btn-light-purple.btn-o:active, fieldset[disabled] .btn-light-purple.btn-o.active {
  border-color: #c69fbe;
  color: #c69fbe;
}
.btn-light-purple.btn-o .caret {
  border-top-color: #a05f92;
}
.btn-light-purple .caret {
  border-top-color: #ffffff;
}
.dropup .btn-light-purple .caret {
  border-bottom: 4px solid #ffffff;
}

.btn-dark-purple {
  background-color: #603958;
  border-color: #603958;
  color: #ffffff;
}
.open .btn-dark-purple.dropdown-toggle {
  background-color: #905684;
  border-color: #905684;
}
.btn-dark-purple:hover {
  background-color: #804c75 !important;
  border-color: #804c75;
  color: #ffffff;
}
.btn-dark-purple:active, .btn-dark-purple.active, .btn-dark-purple.active:focus, .btn-dark-purple:active:focus, .btn-dark-purple:active:hover, .btn-dark-purple.dropdown-toggle:active:hover {
  background-color: #503049 !important;
  border-color: #503049;
}
.btn-dark-purple:focus {
  background-color: #905684;
  border-color: #905684;
  color: #ffffff;
}
.btn-dark-purple.disabled:hover, .btn-dark-purple.disabled:focus, .btn-dark-purple.disabled:active, .btn-dark-purple.disabled.active, .btn-dark-purple[disabled], .btn-dark-purple[disabled]:hover, .btn-dark-purple[disabled]:focus, .btn-dark-purple[disabled]:active, .btn-dark-purple[disabled].active, fieldset[disabled] .btn-dark-purple:hover, fieldset[disabled] .btn-dark-purple:focus, fieldset[disabled] .btn-dark-purple:active, fieldset[disabled] .btn-dark-purple.active {
  background-color: #a05f92;
  border-color: #a05f92;
  color: #ffffff;
}
.btn-dark-purple.btn-o {
  border: 1px solid #603958;
  color: #603958;
}
.btn-dark-purple.btn-o:hover {
  color: #804c75;
  border-color: #804c75;
  background: none !important;
}
.btn-dark-purple.btn-o:active, .btn-dark-purple.btn-o.active, .btn-dark-purple.btn-o.active:focus, .btn-dark-purple.btn-o:active:focus, .btn-dark-purple.btn-o:active:hover {
  background-color: #eaeaec !important;
  border-color: #301c2c !important;
  color: #301c2c !important;
}
.btn-dark-purple.btn-o:focus {
  background-color: #a05f92;
  border-color: #a05f92;
  color: #804c75;
}
.btn-dark-purple.btn-o.disabled:hover, .btn-dark-purple.btn-o.disabled:focus, .btn-dark-purple.btn-o.disabled:active, .btn-dark-purple.btn-o.disabled.active, .btn-dark-purple.btn-o[disabled], .btn-dark-purple.btn-o[disabled]:hover, .btn-dark-purple.btn-o[disabled]:focus, .btn-dark-purple.btn-o[disabled]:active, .btn-dark-purple.btn-o[disabled].active, fieldset[disabled] .btn-dark-purple.btn-o:hover, fieldset[disabled] .btn-dark-purple.btn-o:focus, fieldset[disabled] .btn-dark-purple.btn-o:active, fieldset[disabled] .btn-dark-purple.btn-o.active {
  border-color: #a05f92;
  color: #a05f92;
}
.btn-dark-purple.btn-o .caret {
  border-top-color: #603958;
}
.btn-dark-purple .caret {
  border-top-color: #ffffff;
}
.dropup .btn-dark-purple .caret {
  border-bottom: 4px solid #ffffff;
}

.btn-beige {
  background-color: #D2BD8E;
  border-color: #D2BD8E;
  color: #ffffff;
}
.open .btn-beige.dropdown-toggle {
  background-color: #e8ddc5;
  border-color: #e8ddc5;
}
.btn-beige:hover {
  background-color: #e1d2b2 !important;
  border-color: #e1d2b2;
  color: #ffffff;
}
.btn-beige:active, .btn-beige.active, .btn-beige.active:focus, .btn-beige:active:focus, .btn-beige:active:hover, .btn-beige.dropdown-toggle:active:hover {
  background-color: #cbb27c !important;
  border-color: #cbb27c;
}
.btn-beige:focus {
  background-color: #e8ddc5;
  border-color: #e8ddc5;
  color: #ffffff;
}
.btn-beige.disabled:hover, .btn-beige.disabled:focus, .btn-beige.disabled:active, .btn-beige.disabled.active, .btn-beige[disabled], .btn-beige[disabled]:hover, .btn-beige[disabled]:focus, .btn-beige[disabled]:active, .btn-beige[disabled].active, fieldset[disabled] .btn-beige:hover, fieldset[disabled] .btn-beige:focus, fieldset[disabled] .btn-beige:active, fieldset[disabled] .btn-beige.active {
  background-color: #efe8d7;
  border-color: #efe8d7;
  color: #ffffff;
}
.btn-beige.btn-o {
  border: 1px solid #D2BD8E;
  color: #D2BD8E;
}
.btn-beige.btn-o:hover {
  color: #e1d2b2;
  border-color: #e1d2b2;
  background: none !important;
}
.btn-beige.btn-o:active, .btn-beige.btn-o.active, .btn-beige.btn-o.active:focus, .btn-beige.btn-o:active:focus, .btn-beige.btn-o:active:hover {
  background-color: #eaeaec !important;
  border-color: #bc9d57 !important;
  color: #bc9d57 !important;
}
.btn-beige.btn-o:focus {
  background-color: #efe8d7;
  border-color: #efe8d7;
  color: #e1d2b2;
}
.btn-beige.btn-o.disabled:hover, .btn-beige.btn-o.disabled:focus, .btn-beige.btn-o.disabled:active, .btn-beige.btn-o.disabled.active, .btn-beige.btn-o[disabled], .btn-beige.btn-o[disabled]:hover, .btn-beige.btn-o[disabled]:focus, .btn-beige.btn-o[disabled]:active, .btn-beige.btn-o[disabled].active, fieldset[disabled] .btn-beige.btn-o:hover, fieldset[disabled] .btn-beige.btn-o:focus, fieldset[disabled] .btn-beige.btn-o:active, fieldset[disabled] .btn-beige.btn-o.active {
  border-color: #efe8d7;
  color: #efe8d7;
}
.btn-beige.btn-o .caret {
  border-top-color: #D2BD8E;
}
.btn-beige .caret {
  border-top-color: #ffffff;
}
.dropup .btn-beige .caret {
  border-bottom: 4px solid #ffffff;
}

.btn-light-beige {
  background-color: #d9c8a0;
  border-color: #d9c8a0;
  color: #ffffff;
}
.open .btn-light-beige.dropdown-toggle {
  background-color: #efe8d7;
  border-color: #efe8d7;
}
.btn-light-beige:hover {
  background-color: #e8ddc5 !important;
  border-color: #e8ddc5;
  color: #ffffff;
}
.btn-light-beige:active, .btn-light-beige.active, .btn-light-beige.active:focus, .btn-light-beige:active:focus, .btn-light-beige:active:hover, .btn-light-beige.dropdown-toggle:active:hover {
  background-color: #d2bd8e !important;
  border-color: #d2bd8e;
}
.btn-light-beige:focus {
  background-color: #efe8d7;
  border-color: #efe8d7;
  color: #ffffff;
}
.btn-light-beige.disabled:hover, .btn-light-beige.disabled:focus, .btn-light-beige.disabled:active, .btn-light-beige.disabled.active, .btn-light-beige[disabled], .btn-light-beige[disabled]:hover, .btn-light-beige[disabled]:focus, .btn-light-beige[disabled]:active, .btn-light-beige[disabled].active, fieldset[disabled] .btn-light-beige:hover, fieldset[disabled] .btn-light-beige:focus, fieldset[disabled] .btn-light-beige:active, fieldset[disabled] .btn-light-beige.active {
  background-color: #f6f2e9;
  border-color: #f6f2e9;
  color: #ffffff;
}
.btn-light-beige.btn-o {
  border: 1px solid #d9c8a0;
  color: #d9c8a0;
}
.btn-light-beige.btn-o:hover {
  color: #e8ddc5;
  border-color: #e8ddc5;
  background: none !important;
}
.btn-light-beige.btn-o:active, .btn-light-beige.btn-o.active, .btn-light-beige.btn-o.active:focus, .btn-light-beige.btn-o:active:focus, .btn-light-beige.btn-o:active:hover {
  background-color: #eaeaec !important;
  border-color: #c3a86a !important;
  color: #c3a86a !important;
}
.btn-light-beige.btn-o:focus {
  background-color: #f6f2e9;
  border-color: #f6f2e9;
  color: #e8ddc5;
}
.btn-light-beige.btn-o.disabled:hover, .btn-light-beige.btn-o.disabled:focus, .btn-light-beige.btn-o.disabled:active, .btn-light-beige.btn-o.disabled.active, .btn-light-beige.btn-o[disabled], .btn-light-beige.btn-o[disabled]:hover, .btn-light-beige.btn-o[disabled]:focus, .btn-light-beige.btn-o[disabled]:active, .btn-light-beige.btn-o[disabled].active, fieldset[disabled] .btn-light-beige.btn-o:hover, fieldset[disabled] .btn-light-beige.btn-o:focus, fieldset[disabled] .btn-light-beige.btn-o:active, fieldset[disabled] .btn-light-beige.btn-o.active {
  border-color: #f6f2e9;
  color: #f6f2e9;
}
.btn-light-beige.btn-o .caret {
  border-top-color: #d9c8a0;
}
.btn-light-beige .caret {
  border-top-color: #ffffff;
}
.dropup .btn-light-beige .caret {
  border-bottom: 4px solid #ffffff;
}

.btn-dark-beige {
  background-color: #c3a86a;
  border-color: #c3a86a;
  color: #ffffff;
}
.open .btn-dark-beige.dropdown-toggle {
  background-color: #d9c8a0;
  border-color: #d9c8a0;
}
.btn-dark-beige:hover {
  background-color: #d2bd8e !important;
  border-color: #d2bd8e;
  color: #ffffff;
}
.btn-dark-beige:active, .btn-dark-beige.active, .btn-dark-beige.active:focus, .btn-dark-beige:active:focus, .btn-dark-beige:active:hover, .btn-dark-beige.dropdown-toggle:active:hover {
  background-color: #bc9d57 !important;
  border-color: #bc9d57;
}
.btn-dark-beige:focus {
  background-color: #d9c8a0;
  border-color: #d9c8a0;
  color: #ffffff;
}
.btn-dark-beige.disabled:hover, .btn-dark-beige.disabled:focus, .btn-dark-beige.disabled:active, .btn-dark-beige.disabled.active, .btn-dark-beige[disabled], .btn-dark-beige[disabled]:hover, .btn-dark-beige[disabled]:focus, .btn-dark-beige[disabled]:active, .btn-dark-beige[disabled].active, fieldset[disabled] .btn-dark-beige:hover, fieldset[disabled] .btn-dark-beige:focus, fieldset[disabled] .btn-dark-beige:active, fieldset[disabled] .btn-dark-beige.active {
  background-color: #e1d2b2;
  border-color: #e1d2b2;
  color: #ffffff;
}
.btn-dark-beige.btn-o {
  border: 1px solid #c3a86a;
  color: #c3a86a;
}
.btn-dark-beige.btn-o:hover {
  color: #d2bd8e;
  border-color: #d2bd8e;
  background: none !important;
}
.btn-dark-beige.btn-o:active, .btn-dark-beige.btn-o.active, .btn-dark-beige.btn-o.active:focus, .btn-dark-beige.btn-o:active:focus, .btn-dark-beige.btn-o:active:hover {
  background-color: #eaeaec !important;
  border-color: #a18340 !important;
  color: #a18340 !important;
}
.btn-dark-beige.btn-o:focus {
  background-color: #e1d2b2;
  border-color: #e1d2b2;
  color: #d2bd8e;
}
.btn-dark-beige.btn-o.disabled:hover, .btn-dark-beige.btn-o.disabled:focus, .btn-dark-beige.btn-o.disabled:active, .btn-dark-beige.btn-o.disabled.active, .btn-dark-beige.btn-o[disabled], .btn-dark-beige.btn-o[disabled]:hover, .btn-dark-beige.btn-o[disabled]:focus, .btn-dark-beige.btn-o[disabled]:active, .btn-dark-beige.btn-o[disabled].active, fieldset[disabled] .btn-dark-beige.btn-o:hover, fieldset[disabled] .btn-dark-beige.btn-o:focus, fieldset[disabled] .btn-dark-beige.btn-o:active, fieldset[disabled] .btn-dark-beige.btn-o.active {
  border-color: #e1d2b2;
  color: #e1d2b2;
}
.btn-dark-beige.btn-o .caret {
  border-top-color: #c3a86a;
}
.btn-dark-beige .caret {
  border-top-color: #ffffff;
}
.dropup .btn-dark-beige .caret {
  border-bottom: 4px solid #ffffff;
}

.btn-yellow {
  background-color: #FFB848;
  border-color: #FFB848;
  color: #ffffff;
}
.open .btn-yellow.dropdown-toggle {
  background-color: #ffd694;
  border-color: #ffd694;
}
.btn-yellow:hover {
  background-color: #ffcc7b !important;
  border-color: #ffcc7b;
  color: #ffffff;
}
.btn-yellow:active, .btn-yellow.active, .btn-yellow.active:focus, .btn-yellow:active:focus, .btn-yellow:active:hover, .btn-yellow.dropdown-toggle:active:hover {
  background-color: #ffae2e !important;
  border-color: #ffae2e;
}
.btn-yellow:focus {
  background-color: #ffd694;
  border-color: #ffd694;
  color: #ffffff;
}
.btn-yellow.disabled:hover, .btn-yellow.disabled:focus, .btn-yellow.disabled:active, .btn-yellow.disabled.active, .btn-yellow[disabled], .btn-yellow[disabled]:hover, .btn-yellow[disabled]:focus, .btn-yellow[disabled]:active, .btn-yellow[disabled].active, fieldset[disabled] .btn-yellow:hover, fieldset[disabled] .btn-yellow:focus, fieldset[disabled] .btn-yellow:active, fieldset[disabled] .btn-yellow.active {
  background-color: #ffe0ae;
  border-color: #ffe0ae;
  color: #ffffff;
}
.btn-yellow.btn-o {
  border: 1px solid #FFB848;
  color: #FFB848;
}
.btn-yellow.btn-o:hover {
  color: #ffcc7b;
  border-color: #ffcc7b;
  background: none !important;
}
.btn-yellow.btn-o:active, .btn-yellow.btn-o.active, .btn-yellow.btn-o.active:focus, .btn-yellow.btn-o:active:focus, .btn-yellow.btn-o:active:hover {
  background-color: #eaeaec !important;
  border-color: #fa9900 !important;
  color: #fa9900 !important;
}
.btn-yellow.btn-o:focus {
  background-color: #ffe0ae;
  border-color: #ffe0ae;
  color: #ffcc7b;
}
.btn-yellow.btn-o.disabled:hover, .btn-yellow.btn-o.disabled:focus, .btn-yellow.btn-o.disabled:active, .btn-yellow.btn-o.disabled.active, .btn-yellow.btn-o[disabled], .btn-yellow.btn-o[disabled]:hover, .btn-yellow.btn-o[disabled]:focus, .btn-yellow.btn-o[disabled]:active, .btn-yellow.btn-o[disabled].active, fieldset[disabled] .btn-yellow.btn-o:hover, fieldset[disabled] .btn-yellow.btn-o:focus, fieldset[disabled] .btn-yellow.btn-o:active, fieldset[disabled] .btn-yellow.btn-o.active {
  border-color: #ffe0ae;
  color: #ffe0ae;
}
.btn-yellow.btn-o .caret {
  border-top-color: #FFB848;
}
.btn-yellow .caret {
  border-top-color: #ffffff;
}
.dropup .btn-yellow .caret {
  border-bottom: 4px solid #ffffff;
}

.btn-light-yellow {
  background-color: #ffcc7b;
  border-color: #ffcc7b;
  color: #ffffff;
}
.open .btn-light-yellow.dropdown-toggle {
  background-color: #ffe9c7;
  border-color: #ffe9c7;
}
.btn-light-yellow:hover {
  background-color: #ffe0ae !important;
  border-color: #ffe0ae;
  color: #ffffff;
}
.btn-light-yellow:active, .btn-light-yellow.active, .btn-light-yellow.active:focus, .btn-light-yellow:active:focus, .btn-light-yellow:active:hover, .btn-light-yellow.dropdown-toggle:active:hover {
  background-color: #ffc261 !important;
  border-color: #ffc261;
}
.btn-light-yellow:focus {
  background-color: #ffe9c7;
  border-color: #ffe9c7;
  color: #ffffff;
}
.btn-light-yellow.disabled:hover, .btn-light-yellow.disabled:focus, .btn-light-yellow.disabled:active, .btn-light-yellow.disabled.active, .btn-light-yellow[disabled], .btn-light-yellow[disabled]:hover, .btn-light-yellow[disabled]:focus, .btn-light-yellow[disabled]:active, .btn-light-yellow[disabled].active, fieldset[disabled] .btn-light-yellow:hover, fieldset[disabled] .btn-light-yellow:focus, fieldset[disabled] .btn-light-yellow:active, fieldset[disabled] .btn-light-yellow.active {
  background-color: #fff3e1;
  border-color: #fff3e1;
  color: #ffffff;
}
.btn-light-yellow.btn-o {
  border: 1px solid #ffcc7b;
  color: #ffcc7b;
}
.btn-light-yellow.btn-o:hover {
  color: #ffe0ae;
  border-color: #ffe0ae;
  background: none !important;
}
.btn-light-yellow.btn-o:active, .btn-light-yellow.btn-o.active, .btn-light-yellow.btn-o.active:focus, .btn-light-yellow.btn-o:active:focus, .btn-light-yellow.btn-o:active:hover {
  background-color: #eaeaec !important;
  border-color: #ffae2e !important;
  color: #ffae2e !important;
}
.btn-light-yellow.btn-o:focus {
  background-color: #fff3e1;
  border-color: #fff3e1;
  color: #ffe0ae;
}
.btn-light-yellow.btn-o.disabled:hover, .btn-light-yellow.btn-o.disabled:focus, .btn-light-yellow.btn-o.disabled:active, .btn-light-yellow.btn-o.disabled.active, .btn-light-yellow.btn-o[disabled], .btn-light-yellow.btn-o[disabled]:hover, .btn-light-yellow.btn-o[disabled]:focus, .btn-light-yellow.btn-o[disabled]:active, .btn-light-yellow.btn-o[disabled].active, fieldset[disabled] .btn-light-yellow.btn-o:hover, fieldset[disabled] .btn-light-yellow.btn-o:focus, fieldset[disabled] .btn-light-yellow.btn-o:active, fieldset[disabled] .btn-light-yellow.btn-o.active {
  border-color: #fff3e1;
  color: #fff3e1;
}
.btn-light-yellow.btn-o .caret {
  border-top-color: #ffcc7b;
}
.btn-light-yellow .caret {
  border-top-color: #ffffff;
}
.dropup .btn-light-yellow .caret {
  border-bottom: 4px solid #ffffff;
}

.btn-dark-yellow {
  background-color: #ffa415;
  border-color: #ffa415;
  color: #ffffff;
}
.open .btn-dark-yellow.dropdown-toggle {
  background-color: #ffc261;
  border-color: #ffc261;
}
.btn-dark-yellow:hover {
  background-color: #ffb848 !important;
  border-color: #ffb848;
  color: #ffffff;
}
.btn-dark-yellow:active, .btn-dark-yellow.active, .btn-dark-yellow.active:focus, .btn-dark-yellow:active:focus, .btn-dark-yellow:active:hover, .btn-dark-yellow.dropdown-toggle:active:hover {
  background-color: #fa9900 !important;
  border-color: #fa9900;
}
.btn-dark-yellow:focus {
  background-color: #ffc261;
  border-color: #ffc261;
  color: #ffffff;
}
.btn-dark-yellow.disabled:hover, .btn-dark-yellow.disabled:focus, .btn-dark-yellow.disabled:active, .btn-dark-yellow.disabled.active, .btn-dark-yellow[disabled], .btn-dark-yellow[disabled]:hover, .btn-dark-yellow[disabled]:focus, .btn-dark-yellow[disabled]:active, .btn-dark-yellow[disabled].active, fieldset[disabled] .btn-dark-yellow:hover, fieldset[disabled] .btn-dark-yellow:focus, fieldset[disabled] .btn-dark-yellow:active, fieldset[disabled] .btn-dark-yellow.active {
  background-color: #ffcc7b;
  border-color: #ffcc7b;
  color: #ffffff;
}
.btn-dark-yellow.btn-o {
  border: 1px solid #ffa415;
  color: #ffa415;
}
.btn-dark-yellow.btn-o:hover {
  color: #ffb848;
  border-color: #ffb848;
  background: none !important;
}
.btn-dark-yellow.btn-o:active, .btn-dark-yellow.btn-o.active, .btn-dark-yellow.btn-o.active:focus, .btn-dark-yellow.btn-o:active:focus, .btn-dark-yellow.btn-o:active:hover {
  background-color: #eaeaec !important;
  border-color: #c77a00 !important;
  color: #c77a00 !important;
}
.btn-dark-yellow.btn-o:focus {
  background-color: #ffcc7b;
  border-color: #ffcc7b;
  color: #ffb848;
}
.btn-dark-yellow.btn-o.disabled:hover, .btn-dark-yellow.btn-o.disabled:focus, .btn-dark-yellow.btn-o.disabled:active, .btn-dark-yellow.btn-o.disabled.active, .btn-dark-yellow.btn-o[disabled], .btn-dark-yellow.btn-o[disabled]:hover, .btn-dark-yellow.btn-o[disabled]:focus, .btn-dark-yellow.btn-o[disabled]:active, .btn-dark-yellow.btn-o[disabled].active, fieldset[disabled] .btn-dark-yellow.btn-o:hover, fieldset[disabled] .btn-dark-yellow.btn-o:focus, fieldset[disabled] .btn-dark-yellow.btn-o:active, fieldset[disabled] .btn-dark-yellow.btn-o.active {
  border-color: #ffcc7b;
  color: #ffcc7b;
}
.btn-dark-yellow.btn-o .caret {
  border-top-color: #ffa415;
}
.btn-dark-yellow .caret {
  border-top-color: #ffffff;
}
.dropup .btn-dark-yellow .caret {
  border-bottom: 4px solid #ffffff;
}

.btn-grey {
  background-color: #c8c7cc;
  border-color: #c8c7cc;
  color: #ffffff;
}
.open .btn-grey.dropdown-toggle {
  background-color: #efeff0;
  border-color: #efeff0;
}
.btn-grey:hover {
  background-color: #e2e2e4 !important;
  border-color: #e2e2e4;
  color: #ffffff;
}
.btn-grey:active, .btn-grey.active, .btn-grey.active:focus, .btn-grey:active:focus, .btn-grey:active:hover, .btn-grey.dropdown-toggle:active:hover {
  background-color: #bbbac0 !important;
  border-color: #bbbac0;
}
.btn-grey:focus {
  background-color: #efeff0;
  border-color: #efeff0;
  color: #ffffff;
}
.btn-grey.disabled:hover, .btn-grey.disabled:focus, .btn-grey.disabled:active, .btn-grey.disabled.active, .btn-grey[disabled], .btn-grey[disabled]:hover, .btn-grey[disabled]:focus, .btn-grey[disabled]:active, .btn-grey[disabled].active, fieldset[disabled] .btn-grey:hover, fieldset[disabled] .btn-grey:focus, fieldset[disabled] .btn-grey:active, fieldset[disabled] .btn-grey.active {
  background-color: #fcfcfd;
  border-color: #fcfcfd;
  color: #ffffff;
}
.btn-grey.btn-o {
  border: 1px solid #c8c7cc;
  color: #c8c7cc;
}
.btn-grey.btn-o:hover {
  color: #e2e2e4;
  border-color: #e2e2e4;
  background: none !important;
}
.btn-grey.btn-o:active, .btn-grey.btn-o.active, .btn-grey.btn-o.active:focus, .btn-grey.btn-o:active:focus, .btn-grey.btn-o:active:hover {
  background-color: #eaeaec !important;
  border-color: #a19fa8 !important;
  color: #a19fa8 !important;
}
.btn-grey.btn-o:focus {
  background-color: #fcfcfd;
  border-color: #fcfcfd;
  color: #e2e2e4;
}
.btn-grey.btn-o.disabled:hover, .btn-grey.btn-o.disabled:focus, .btn-grey.btn-o.disabled:active, .btn-grey.btn-o.disabled.active, .btn-grey.btn-o[disabled], .btn-grey.btn-o[disabled]:hover, .btn-grey.btn-o[disabled]:focus, .btn-grey.btn-o[disabled]:active, .btn-grey.btn-o[disabled].active, fieldset[disabled] .btn-grey.btn-o:hover, fieldset[disabled] .btn-grey.btn-o:focus, fieldset[disabled] .btn-grey.btn-o:active, fieldset[disabled] .btn-grey.btn-o.active {
  border-color: #fcfcfd;
  color: #fcfcfd;
}
.btn-grey.btn-o .caret {
  border-top-color: #c8c7cc;
}
.btn-grey .caret {
  border-top-color: #ffffff;
}
.dropup .btn-grey .caret {
  border-bottom: 4px solid #ffffff;
}

.btn-light-grey {
  background-color: #f7f7f8;
  border-color: #f7f7f8;
  color: #8e8e93;
}
.open .btn-light-grey.dropdown-toggle {
  background-color: white;
  border-color: white;
}
.btn-light-grey:hover {
  background-color: white !important;
  border-color: white;
  color: #8e8e93;
}
.btn-light-grey:active, .btn-light-grey.active, .btn-light-grey.active:focus, .btn-light-grey:active:focus, .btn-light-grey:active:hover, .btn-light-grey.dropdown-toggle:active:hover {
  background-color: #eaeaec !important;
  border-color: #eaeaec;
}
.btn-light-grey:focus {
  background-color: white;
  border-color: white;
  color: #8e8e93;
}
.btn-light-grey.disabled:hover, .btn-light-grey.disabled:focus, .btn-light-grey.disabled:active, .btn-light-grey.disabled.active, .btn-light-grey[disabled], .btn-light-grey[disabled]:hover, .btn-light-grey[disabled]:focus, .btn-light-grey[disabled]:active, .btn-light-grey[disabled].active, fieldset[disabled] .btn-light-grey:hover, fieldset[disabled] .btn-light-grey:focus, fieldset[disabled] .btn-light-grey:active, fieldset[disabled] .btn-light-grey.active {
  background-color: white;
  border-color: white;
  color: #c2c2c5;
}
.btn-light-grey.btn-o {
  border: 1px solid #f7f7f8;
  color: #f7f7f8;
}
.btn-light-grey.btn-o:hover {
  color: #a8a8ac;
  border-color: white;
  background: none !important;
}
.btn-light-grey.btn-o:active, .btn-light-grey.btn-o.active, .btn-light-grey.btn-o.active:focus, .btn-light-grey.btn-o:active:focus, .btn-light-grey.btn-o:active:hover {
  background-color: #eaeaec !important;
  border-color: #d0cfd3 !important;
  color: #68686d !important;
}
.btn-light-grey.btn-o:focus {
  background-color: white;
  border-color: white;
  color: white;
}
.btn-light-grey.btn-o.disabled:hover, .btn-light-grey.btn-o.disabled:focus, .btn-light-grey.btn-o.disabled:active, .btn-light-grey.btn-o.disabled.active, .btn-light-grey.btn-o[disabled], .btn-light-grey.btn-o[disabled]:hover, .btn-light-grey.btn-o[disabled]:focus, .btn-light-grey.btn-o[disabled]:active, .btn-light-grey.btn-o[disabled].active, fieldset[disabled] .btn-light-grey.btn-o:hover, fieldset[disabled] .btn-light-grey.btn-o:focus, fieldset[disabled] .btn-light-grey.btn-o:active, fieldset[disabled] .btn-light-grey.btn-o.active {
  border-color: white;
  color: #c2c2c5;
}
.btn-light-grey.btn-o .caret {
  border-top-color: #8e8e93;
}
.btn-light-grey .caret {
  border-top-color: #8e8e93;
}
.dropup .btn-light-grey .caret {
  border-bottom: 4px solid #8e8e93;
}

.btn-dark-grey {
  background-color: #aeacb4;
  border-color: #aeacb4;
  color: #ffffff;
}
.open .btn-dark-grey.dropdown-toggle {
  background-color: #d5d4d8;
  border-color: #d5d4d8;
}
.btn-dark-grey:hover {
  background-color: #c8c7cc !important;
  border-color: #c8c7cc;
  color: #ffffff;
}
.btn-dark-grey:active, .btn-dark-grey.active, .btn-dark-grey.active:focus, .btn-dark-grey:active:focus, .btn-dark-grey:active:hover, .btn-dark-grey.dropdown-toggle:active:hover {
  background-color: #a19fa8 !important;
  border-color: #a19fa8;
}
.btn-dark-grey:focus {
  background-color: #d5d4d8;
  border-color: #d5d4d8;
  color: #ffffff;
}
.btn-dark-grey.disabled:hover, .btn-dark-grey.disabled:focus, .btn-dark-grey.disabled:active, .btn-dark-grey.disabled.active, .btn-dark-grey[disabled], .btn-dark-grey[disabled]:hover, .btn-dark-grey[disabled]:focus, .btn-dark-grey[disabled]:active, .btn-dark-grey[disabled].active, fieldset[disabled] .btn-dark-grey:hover, fieldset[disabled] .btn-dark-grey:focus, fieldset[disabled] .btn-dark-grey:active, fieldset[disabled] .btn-dark-grey.active {
  background-color: #e2e2e4;
  border-color: #e2e2e4;
  color: #ffffff;
}
.btn-dark-grey.btn-o {
  border: 1px solid #aeacb4;
  color: #aeacb4;
}
.btn-dark-grey.btn-o:hover {
  color: #c8c7cc;
  border-color: #c8c7cc;
  background: none !important;
}
.btn-dark-grey.btn-o:active, .btn-dark-grey.btn-o.active, .btn-dark-grey.btn-o.active:focus, .btn-dark-grey.btn-o:active:focus, .btn-dark-grey.btn-o:active:hover {
  background-color: #a19fa8 !important;
  border-color: #86848f !important;
  color: #86848f !important;
}
.btn-dark-grey.btn-o:focus {
  background-color: #e2e2e4;
  border-color: #e2e2e4;
  color: #c8c7cc;
}
.btn-dark-grey.btn-o.disabled:hover, .btn-dark-grey.btn-o.disabled:focus, .btn-dark-grey.btn-o.disabled:active, .btn-dark-grey.btn-o.disabled.active, .btn-dark-grey.btn-o[disabled], .btn-dark-grey.btn-o[disabled]:hover, .btn-dark-grey.btn-o[disabled]:focus, .btn-dark-grey.btn-o[disabled]:active, .btn-dark-grey.btn-o[disabled].active, fieldset[disabled] .btn-dark-grey.btn-o:hover, fieldset[disabled] .btn-dark-grey.btn-o:focus, fieldset[disabled] .btn-dark-grey.btn-o:active, fieldset[disabled] .btn-dark-grey.btn-o.active {
  border-color: #e2e2e4;
  color: #e2e2e4;
}
.btn-dark-grey.btn-o .caret {
  border-top-color: #aeacb4;
}
.btn-dark-grey .caret {
  border-top-color: #ffffff;
}
.dropup .btn-dark-grey .caret {
  border-bottom: 4px solid #ffffff;
}

.btn-transparent-white {
  background: none;
  border-color: rgba(255, 255, 255, 0.6);
  border-width: 1px;
  color: #ffffff !important;
}
.btn-transparent-white:hover, .btn-transparent-white:focus, .btn-transparent-white:active, .btn-transparent-white.active {
  background-color: rgba(44, 47, 59, 0.1);
  border-color: rgba(255, 255, 255, 0.8);
}
.btn-transparent-white.disabled:hover, .btn-transparent-white.disabled:focus, .btn-transparent-white.disabled:active, .btn-transparent-white.disabled.active, .btn-transparent-white[disabled]:hover, .btn-transparent-white[disabled]:focus, .btn-transparent-white[disabled]:active, .btn-transparent-white[disabled].active, fieldset[disabled] .btn-transparent-white:hover, fieldset[disabled] .btn-transparent-white:focus, fieldset[disabled] .btn-transparent-white:active, fieldset[disabled] .btn-transparent-white.active {
  background: none;
  border-color: rgba(255, 255, 255, 0.3);
  color: #ffffff;
}
.btn-transparent-white .caret {
  border-top-color: rgba(44, 47, 59, 0.1);
}
.dropup .btn-transparent-white .caret {
  border-bottom: 4px solid rgba(44, 47, 59, 0.1);
}

.btn-transparent-grey {
  background: none;
  border-color: rgba(200, 199, 204, 0.3);
  border-width: 1px;
  color: #c8c7cc !important;
}
.btn-transparent-grey:hover, .btn-transparent-grey:focus, .btn-transparent-grey:active, .btn-transparent-grey.active {
  background-color: rgba(200, 199, 204, 0.1);
  border-color: rgba(200, 199, 204, 0.6);
  box-shadow: none !important;
}
.btn-transparent-grey.disabled:hover, .btn-transparent-grey.disabled:focus, .btn-transparent-grey.disabled:active, .btn-transparent-grey.disabled.active, .btn-transparent-grey[disabled]:hover, .btn-transparent-grey[disabled]:focus, .btn-transparent-grey[disabled]:active, .btn-transparent-grey[disabled].active, fieldset[disabled] .btn-transparent-grey:hover, fieldset[disabled] .btn-transparent-grey:focus, fieldset[disabled] .btn-transparent-grey:active, fieldset[disabled] .btn-transparent-grey.active {
  background: none;
  border-color: rgba(200, 199, 204, 0.3);
  color: #c8c7cc;
}
.btn-transparent-grey .caret {
  border-top-color: #c8c7cc;
}
.dropup .btn-transparent-grey .caret {
  border-bottom: 4px solid #c8c7cc;
}

.btn-transparent-red {
  background: none;
  border-color: rgba(200, 46, 41, 0.6);
  border-width: 2px;
  color: #C82E29 !important;
}
.btn-transparent-red:hover, .btn-transparent-red:focus, .btn-transparent-red:active, .btn-transparent-red.active {
  background-color: rgba(200, 46, 41, 0.1);
  border-color: rgba(200, 46, 41, 0.8);
}
.btn-transparent-red.disabled:hover, .btn-transparent-red.disabled:focus, .btn-transparent-red.disabled:active, .btn-transparent-red.disabled.active, .btn-transparent-red[disabled]:hover, .btn-transparent-red[disabled]:focus, .btn-transparent-red[disabled]:active, .btn-transparent-red[disabled].active, fieldset[disabled] .btn-transparent-red:hover, fieldset[disabled] .btn-transparent-red:focus, fieldset[disabled] .btn-transparent-red:active, fieldset[disabled] .btn-transparent-red.active {
  background: none;
  border-color: rgba(200, 46, 41, 0.3);
  color: #C82E29;
}
.btn-transparent-red .caret {
  border-top-color: #ffffff;
}
.dropup .btn-transparent-red .caret {
  border-bottom: 4px solid #ffffff;
}

.btn-squared {
  border-radius: 0 !important;
}

.btn-icon {
  background-color: #ffffff;
  border: 1px solid #DDDDDD;
  border-radius: 2px 2px 2px 2px;
  box-shadow: 0 1px 0 rgba(255, 255, 255, 0.8);
  cursor: pointer;
  height: 80px;
  display: block;
  font-size: 14px;
  padding: 5px 0 0;
  position: relative;
  text-align: center;
  transition: all 0.3s ease 0s;
  color: #8e8e93;
}

.btn-icon:hover {
  border-color: #A5A5A5;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.25);
  color: #8e8e93;
  text-decoration: none;
  text-shadow: 0 1px 0 #FFFFFF;
}

.btn-icon .badge {
  border-radius: 12px 12px 12px 12px !important;
  border-style: solid;
  border-width: 0;
  box-shadow: none;
  color: #FFFFFF !important;
  font-size: 11px !important;
  font-weight: 300;
  padding: 3px 7px;
  position: absolute;
  right: -5px;
  text-shadow: none;
  top: -5px;
}

.btn-icon [class^="fa-"], .btn-icon [class*=" fa-"] {
  clear: both;
  display: block;
}

.btn-file {
  position: relative;
  overflow: hidden;
}
.btn-file .svg-inject {
  vertical-align: middle;
}

.iconic-elem-primary {
  fill: #007AFF !important;
}

.iconic-elem-secondary {
  stroke: #fff !important;
}

.btn-file input[type=file] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 100px;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  outline: none;
  background: white;
  cursor: inherit;
  display: block;
}

.buttons-widget .btn, .buttons-widget .bootstrap-switch, .buttons-widget button {
  margin-bottom: 10px;
}

.buttons-widget .btn-group button, .buttons-widget .btn-group-vertical button, .buttons-widget .btn-group .btn, .buttons-widget .btn-group-vertical .btn {
  margin-bottom: 0;
}

.button-o {
  background: none;
  border: 1px solid #007aff;
  border-radius: 5px;
  box-sizing: border-box;
  color: #007aff;
  cursor: pointer;
  display: block;
  font-family: inherit;
  font-size: 14px;
  height: 29px;
  line-height: 27px;
  margin: 0;
  overflow: hidden;
  padding: 0 10px;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/***
Dropdown checkboxes
***/
.dropdown-checkboxes {
  padding: 5px;
}
.dropdown-checkboxes label {
  display: block;
  font-weight: normal;
  line-height: 20px;
}

.btn-group .btn + .btn, .btn-group .btn + .btn-group, .btn-group .btn-group + .btn, .btn-group .btn-group + .btn-group {
  border-left-color: rgba(255, 255, 255, 0.5) !important;
  margin: 0;
}

.btn-group-vertical > .btn + .btn, .btn-group-vertical > .btn + .btn-group, .btn-group-vertical > .btn-group + .btn, .btn-group-vertical > .btn-group + .btn-group {
  border-top-color: rgba(255, 255, 255, 0.5) !important;
  margin: 0;
}

/***
Social Icons
***/
.social-icons ul {
  list-style: none;
  margin: 0 0 -1px 0;
  padding: 0;
}
.social-icons ul li {
  display: inline-block;
  margin: 0;
  padding: 0;
  height: 40px;
  overflow: hidden;
}
.social-icons ul li a {
  background-position: 0 0;
  background-repeat: no-repeat;
  display: block;
  height: 80px;
  opacity: 0.6;
  text-indent: -9999px;
  transition: all 0.2s ease 0s;
  width: 40px;
  top: 0;
  font-size: 16px;
  position: relative;
  -moz-transition: all, 0.3s, ease;
  -o-transition: all, 0.3s, ease;
  -webkit-transition: all, 0.3s, ease;
  transition: all, 0.3s, ease;
}
.social-icons ul li a:hover {
  top: -40px;
}
.social-icons ul li a:before {
  position: absolute;
  width: 40px;
  height: 40px;
  font-family: FontAwesome;
  text-indent: 0;
  line-height: 40px;
  top: 0;
  left: 0;
  text-align: center;
  color: #999999;
}
.social-icons ul li a:after {
  position: absolute;
  width: 40px;
  height: 40px;
  font-family: FontAwesome;
  text-indent: 0;
  line-height: 40px;
  top: 40px;
  left: 0;
  text-align: center;
  color: #FFFFFF;
}
.social-icons ul li.social-twitter a:before, .social-icons ul li.social-twitter a:after {
  content: "\f099";
}
.social-icons ul li.social-twitter a:after {
  background: #48C4D2;
}
.social-icons ul li.social-dribbble a:before, .social-icons ul li.social-dribbble a:after {
  content: "\f17d";
}
.social-icons ul li.social-dribbble a:after {
  background: #EF5B92;
}
.social-icons ul li.social-facebook a:before, .social-icons ul li.social-facebook a:after {
  content: "\f09a";
}
.social-icons ul li.social-facebook a:after {
  background: #3B5998;
}
.social-icons ul li.social-google a:before, .social-icons ul li.social-google a:after {
  content: "\f0d5";
}
.social-icons ul li.social-google a:after {
  background: #DD4B39;
}
.social-icons ul li.social-linkedin a:before, .social-icons ul li.social-linkedin a:after {
  content: "\f0e1";
}
.social-icons ul li.social-linkedin a:after {
  background: #71B2D0;
}
.social-icons ul li.social-youtube a:before, .social-icons ul li.social-youtube a:after {
  content: "\f167";
}
.social-icons ul li.social-youtube a:after {
  background: #F45750;
}
.social-icons ul li.social-rss a:before, .social-icons ul li.social-rss a:after {
  content: "\f09e";
}
.social-icons ul li.social-rss a:after {
  background: #FE9900;
}
.social-icons ul li.social-behance a:before, .social-icons ul li.social-behance a:after {
  content: "\f1b4";
}
.social-icons ul li.social-behance a:after {
  background: #5EB5FB;
}
.social-icons ul li.social-dropbox a:before, .social-icons ul li.social-dropbox a:after {
  content: "\f16b";
}
.social-icons ul li.social-dropbox a:after {
  background: #85BEEC;
}
.social-icons ul li.social-github a:before, .social-icons ul li.social-github a:after {
  content: "\f09b";
}
.social-icons ul li.social-github a:after {
  background: #94A5B0;
}
.social-icons ul li.social-skype a:before, .social-icons ul li.social-skype a:after {
  content: "\f17e";
}
.social-icons ul li.social-skype a:after {
  background: #70CCEF;
}
.social-icons ul li.social-spotify a:before, .social-icons ul li.social-spotify a:after {
  content: "\f1bc";
}
.social-icons ul li.social-spotify a:after {
  background: #8BD05A;
}
.social-icons ul li.social-stumbleupon a:before, .social-icons ul li.social-stumbleupon a:after {
  content: "\f1a4";
}
.social-icons ul li.social-stumbleupon a:after {
  background: #F08066;
}
.social-icons ul li.social-tumblr a:before, .social-icons ul li.social-tumblr a:after {
  content: "\f173";
}
.social-icons ul li.social-tumblr a:after {
  background: #85A5C3;
}
.social-icons ul li.social-vimeo a:before, .social-icons ul li.social-vimeo a:after {
  content: "\f194";
}
.social-icons ul li.social-vimeo a:after {
  background: #7C99A8;
}
.social-icons ul li.social-wordpress a:before, .social-icons ul li.social-wordpress a:after {
  content: "\f19a";
}
.social-icons ul li.social-wordpress a:after {
  background: #649EB9;
}
.social-icons ul li.social-xing a:before, .social-icons ul li.social-xing a:after {
  content: "\f168";
}
.social-icons ul li.social-xing a:after {
  background: #4F9394;
}
.social-icons ul li.social-yahoo a:before, .social-icons ul li.social-yahoo a:after {
  content: "\f19e";
}
.social-icons ul li.social-yahoo a:after {
  background: #C38DCF;
}
.social-icons ul li.social-vk a:before, .social-icons ul li.social-vk a:after {
  content: "\f189";
}
.social-icons ul li.social-vk a:after {
  background: #708FAC;
}
.social-icons ul li.social-instagram a:before, .social-icons ul li.social-instagram a:after {
  content: "\f16d";
}
.social-icons ul li.social-instagram a:after {
  background: #CBAA97;
}
.social-icons ul li.social-reddit a:before, .social-icons ul li.social-reddit a:after {
  content: "\f1a1";
}
.social-icons ul li.social-reddit a:after {
  background: #FF7A52;
}
.social-icons ul li.social-flickr a:before, .social-icons ul li.social-flickr a:after {
  content: "\f16e";
}
.social-icons ul li.social-flickr a:after {
  background: #FF4DA9;
}
.social-icons ul li.social-foursquare a:before, .social-icons ul li.social-foursquare a:after {
  content: "\f180";
}
.social-icons ul li.social-foursquare a:after {
  background: #6FCCF4;
}

.btn-scroll {
  position: relative;
  overflow: hidden;
  font-family: Helvetica, Arial, sans-serif !important;
  line-height: inherit !important;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  -webkit-transition: all 0.3s ease;
  -webkit-transition-delay: 0s;
  transition: all 0.3s ease 0s;
  font-size: 14px;
}
.btn-scroll[class*="fa-"]:before {
  font-family: FontAwesome;
}
.btn-scroll[class*="ti-"]:before {
  font-family: themify;
}
.btn-scroll:after {
  content: "";
  position: absolute;
  transition: all 0.3s ease 0s;
  z-index: -1;
}
.btn-scroll:before {
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1;
  position: relative;
  text-transform: none;
  font-size: 100%;
  height: 100%;
  line-height: 2.5;
  position: absolute;
  transition: all 0.3s ease 0s;
  width: 100%;
}
.btn-scroll span {
  backface-visibility: hidden;
  display: inline-block;
  height: 100%;
  transition: all 0.3s ease 0s;
  width: 100%;
}
.btn-scroll.btn-scroll-top:hover span {
  -moz-transform: translateY(300%);
  -ms-transform: translateY(300%);
  -webkit-transform: translateY(300%);
  transform: translateY(300%);
}
.btn-scroll.btn-scroll-top:hover:before {
  top: 0;
}
.btn-scroll.btn-scroll-top:before {
  left: 0;
  top: -100%;
}
.btn-scroll.btn-scroll-left:hover span {
  -moz-transform: translateX(200%);
  -ms-transform: translateX(200%);
  -webkit-transform: translateX(200%);
  transform: translateX(200%);
}
.btn-scroll.btn-scroll-left:hover:before {
  left: 0;
}
.btn-scroll.btn-scroll-left:before {
  left: -100%;
  top: 0;
}

/* ---------------------------------------------------------------------- */
/*  Links
/* ---------------------------------------------------------------------- */
/*from http://tympanus.net/Development/CreativeLinkEffects/*/
nav.links {
  text-align: center;
}
nav.links a {
  color: #8e8e93;
  display: inline-block;
  font-size: 1.35em;
  font-weight: 400;
  letter-spacing: 1px;
  margin: 15px 25px;
  outline: medium none;
  position: relative;
  text-decoration: none;
  text-shadow: 0 0 1px rgba(255, 255, 255, 0.3);
  text-transform: uppercase;
}

[class^="cl-effect-"] a, [class*=" cl-effect-"] a {
  position: relative;
}

/* Effect 1: Brackets */
.cl-effect-1 a::before,
.cl-effect-1 a::after {
  display: inline-block;
  opacity: 0;
  -webkit-transition: -webkit-transform 0.3s, opacity 0.2s;
  -moz-transition: -moz-transform 0.3s, opacity 0.2s;
  transition: transform 0.3s, opacity 0.2s;
}

.cl-effect-1 a::before {
  margin-right: 10px;
  content: '[';
  -webkit-transform: translateX(20px);
  -moz-transform: translateX(20px);
  transform: translateX(20px);
}

.cl-effect-1 a::after {
  margin-left: 10px;
  content: ']';
  -webkit-transform: translateX(-20px);
  -moz-transform: translateX(-20px);
  transform: translateX(-20px);
}

.cl-effect-1 a:hover::before,
.cl-effect-1 a:hover::after,
.cl-effect-1 a:focus::before,
.cl-effect-1 a:focus::after {
  opacity: 1;
  -webkit-transform: translateX(0px);
  -moz-transform: translateX(0px);
  transform: translateX(0px);
}

/* Effect 2: 3D rolling links, idea from http://hakim.se/thoughts/rolling-links */
.cl-effect-2 a {
  line-height: 44px;
  -webkit-perspective: 1000px;
  -moz-perspective: 1000px;
  perspective: 1000px;
}

.cl-effect-2 a span {
  position: relative;
  display: inline-block;
  padding: 0 14px;
  background: #007AFF;
  color: #ffffff;
  -webkit-transition: -webkit-transform 0.3s;
  -moz-transition: -moz-transform 0.3s;
  transition: transform 0.3s;
  -webkit-transform-origin: 50% 0;
  -moz-transform-origin: 50% 0;
  transform-origin: 50% 0;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.csstransforms3d .cl-effect-2 a span::before {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 100%;
  background: #0062cc;
  content: attr(data-hover);
  -webkit-transition: background 0.3s;
  -moz-transition: background 0.3s;
  transition: background 0.3s;
  -webkit-transform: rotateX(-90deg);
  -moz-transform: rotateX(-90deg);
  transform: rotateX(-90deg);
  -webkit-transform-origin: 50% 0;
  -moz-transform-origin: 50% 0;
  transform-origin: 50% 0;
}

.cl-effect-2 a:hover span,
.cl-effect-2 a:focus span {
  -webkit-transform: rotateX(90deg) translateY(-22px);
  -moz-transform: rotateX(90deg) translateY(-22px);
  transform: rotateX(90deg) translateY(-22px);
}

.csstransforms3d .cl-effect-2 a:hover span::before,
.csstransforms3d .cl-effect-2 a:focus span::before {
  background: #0062cc;
}

/* Effect 3: bottom line slides/fades in */
.cl-effect-3 a {
  padding: 8px 0;
}

.cl-effect-3 a::after {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 4px;
  background: rgba(0, 0, 0, 0.1);
  content: '';
  opacity: 0;
  -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
  -moz-transition: opacity 0.3s, -moz-transform 0.3s;
  transition: opacity 0.3s, transform 0.3s;
  -webkit-transform: translateY(10px);
  -moz-transform: translateY(10px);
  transform: translateY(10px);
}

.cl-effect-3 a:hover::after,
.cl-effect-3 a:focus::after {
  opacity: 1;
  -webkit-transform: translateY(0px);
  -moz-transform: translateY(0px);
  transform: translateY(0px);
}

/* Effect 4: bottom border enlarge */
.cl-effect-4 a {
  padding: 0 0 10px;
}

.cl-effect-4 a::after {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 1px;
  background: #007AFF;
  content: '';
  opacity: 0;
  -webkit-transition: height 0.3s, opacity 0.3s, -webkit-transform 0.3s;
  -moz-transition: height 0.3s, opacity 0.3s, -moz-transform 0.3s;
  transition: height 0.3s, opacity 0.3s, transform 0.3s;
  -webkit-transform: translateY(-10px);
  -moz-transform: translateY(-10px);
  transform: translateY(-10px);
}

.cl-effect-4 a:hover::after,
.cl-effect-4 a:focus::after {
  height: 5px;
  opacity: 1;
  -webkit-transform: translateY(0px);
  -moz-transform: translateY(0px);
  transform: translateY(0px);
}

/* Effect 5: same word slide in */
.cl-effect-5 a {
  overflow: hidden;
  padding: 0 4px;
  height: 1.35em;
}

.cl-effect-5 a span {
  position: relative;
  display: inline-block;
  -webkit-transition: -webkit-transform 0.3s;
  -moz-transition: -moz-transform 0.3s;
  transition: transform 0.3s;
}

.cl-effect-5 a span::before {
  position: absolute;
  top: 100%;
  content: attr(data-hover);
  font-weight: 700;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.cl-effect-5 a:hover span,
.cl-effect-5 a:focus span {
  -webkit-transform: translateY(-100%);
  -moz-transform: translateY(-100%);
  transform: translateY(-100%);
}

/* Effect 5: same word slide in and border bottom */
.cl-effect-6 a {
  margin: 0 10px;
  padding: 10px 20px;
}

.cl-effect-6 a::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background: #007AFF;
  content: '';
  -webkit-transition: top 0.3s;
  -moz-transition: top 0.3s;
  transition: top 0.3s;
}

.cl-effect-6 a::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 2px;
  height: 2px;
  background: #007AFF;
  content: '';
  -webkit-transition: height 0.3s;
  -moz-transition: height 0.3s;
  transition: height 0.3s;
}

.cl-effect-6 a:hover::before {
  top: 100%;
  opacity: 1;
}

.cl-effect-6 a:hover::after {
  height: 100%;
}

/* Effect 7: second border slides up */
.cl-effect-7 a {
  padding: 12px 10px 10px;
  color: #566473;
  text-shadow: none;
  font-weight: 700;
}

.cl-effect-7 a::before,
.cl-effect-7 a::after {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 3px;
  background: #566473;
  content: '';
  -webkit-transition: -webkit-transform 0.3s;
  -moz-transition: -moz-transform 0.3s;
  transition: transform 0.3s;
  -webkit-transform: scale(0.85);
  -moz-transform: scale(0.85);
  transform: scale(0.85);
}

.cl-effect-7 a::after {
  opacity: 0;
  -webkit-transition: top 0.3s, opacity 0.3s, -webkit-transform 0.3s;
  -moz-transition: top 0.3s, opacity 0.3s, -moz-transform 0.3s;
  transition: top 0.3s, opacity 0.3s, transform 0.3s;
}

.cl-effect-7 a:hover::before,
.cl-effect-7 a:hover::after,
.cl-effect-7 a:focus::before,
.cl-effect-7 a:focus::after {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  transform: scale(1);
}

.cl-effect-7 a:hover::after,
.cl-effect-7 a:focus::after {
  top: 0%;
  opacity: 1;
}

/* Effect 8: border slight translate */
.cl-effect-8 a {
  padding: 10px 20px;
}

.cl-effect-8 a::before,
.cl-effect-8 a::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 3px solid #8e8e93;
  content: '';
  -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
  -moz-transition: -moz-transform 0.3s, opacity 0.3s;
  transition: transform 0.3s, opacity 0.3s;
}

.cl-effect-8 a::after {
  border-color: #007AFF;
  opacity: 0;
  -webkit-transform: translateY(-7px) translateX(6px);
  -moz-transform: translateY(-7px) translateX(6px);
  transform: translateY(-7px) translateX(6px);
}

.cl-effect-8 a:hover::before,
.cl-effect-8 a:focus::before {
  opacity: 0;
  -webkit-transform: translateY(5px) translateX(-5px);
  -moz-transform: translateY(5px) translateX(-5px);
  transform: translateY(5px) translateX(-5px);
}

.cl-effect-8 a:hover::after,
.cl-effect-8 a:focus::after {
  opacity: 1;
  -webkit-transform: translateY(0px) translateX(0px);
  -moz-transform: translateY(0px) translateX(0px);
  transform: translateY(0px) translateX(0px);
}

/* Effect 9: second text and borders */
.cl-effect-9 a {
  margin: 0 20px;
  padding: 18px 20px;
}

.cl-effect-9 a::before,
.cl-effect-9 a::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background: #007AFF;
  content: '';
  opacity: 0.2;
  -webkit-transition: opacity 0.3s, height 0.3s;
  -moz-transition: opacity 0.3s, height 0.3s;
  transition: opacity 0.3s, height 0.3s;
}

.cl-effect-9 a::after {
  top: 100%;
  opacity: 0;
  -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
  -moz-transition: -moz-transform 0.3s, opacity 0.3s;
  transition: transform 0.3s, opacity 0.3s;
  -webkit-transform: translateY(-10px);
  -moz-transform: translateY(-10px);
  transform: translateY(-10px);
}

.cl-effect-9 a span:first-child {
  z-index: 2;
  display: block;
  font-weight: 300;
}

.cl-effect-9 a span:last-child {
  z-index: 1;
  display: block;
  padding: 8px 0 0 0;
  color: rgba(0, 0, 0, 0.4);
  text-shadow: none;
  text-transform: none;
  font-size: 0.75em;
  opacity: 0;
  -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
  -moz-transition: -moz-transform 0.3s, opacity 0.3s;
  transition: transform 0.3s, opacity 0.3s;
  -webkit-transform: translateY(-100%);
  -moz-transform: translateY(-100%);
  transform: translateY(-100%);
}

.cl-effect-9 a:hover::before,
.cl-effect-9 a:focus::before {
  height: 6px;
}

.cl-effect-9 a:hover::before,
.cl-effect-9 a:hover::after,
.cl-effect-9 a:focus::before,
.cl-effect-9 a:focus::after {
  opacity: 1;
  -webkit-transform: translateY(0px);
  -moz-transform: translateY(0px);
  transform: translateY(0px);
}

.cl-effect-9 a:hover span:last-child,
.cl-effect-9 a:focus span:last-child {
  opacity: 1;
  -webkit-transform: translateY(0%);
  -moz-transform: translateY(0%);
  transform: translateY(0%);
}

/* Effect 10: reveal, push out */
.cl-effect-10 {
  position: relative;
  z-index: 1;
}

.cl-effect-10 a {
  overflow: hidden;
  margin: 0 15px;
}

.cl-effect-10 a span {
  display: block;
  padding: 10px 20px;
  background: #007AFF;
  color: #ffffff;
  -webkit-transition: -webkit-transform 0.3s;
  -moz-transition: -moz-transform 0.3s;
  transition: transform 0.3s;
}

.cl-effect-10 a::before {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  padding: 10px 20px;
  width: 100%;
  height: 100%;
  background: #0062cc;
  color: #ffffff;
  content: attr(data-hover);
  -webkit-transition: -webkit-transform 0.3s;
  -moz-transition: -moz-transform 0.3s;
  transition: transform 0.3s;
  -webkit-transform: translateX(-25%);
}

.cl-effect-10 a:hover span,
.cl-effect-10 a:focus span {
  -webkit-transform: translateX(100%);
  -moz-transform: translateX(100%);
  transform: translateX(100%);
}

.cl-effect-10 a:hover::before,
.cl-effect-10 a:focus::before {
  -webkit-transform: translateX(0%);
  -moz-transform: translateX(0%);
  transform: translateX(0%);
}

/* Effect 11: text fill based on Lea Verou's animation http://dabblet.com/gist/6046779 */
.cl-effect-11 a {
  padding: 10px 0;
  border-top: 2px solid #007AFF;
  color: #0972b4;
  text-shadow: none;
}

.cl-effect-11 a::before {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  padding: 10px 0;
  max-width: 0;
  border-bottom: 2px solid #007AFF;
  color: #007AFF;
  content: attr(data-hover);
  -webkit-transition: max-width 0.5s;
  -moz-transition: max-width 0.5s;
  transition: max-width 0.5s;
}

.cl-effect-11 a:hover::before,
.cl-effect-11 a:focus::before {
  max-width: 100%;
}

/* Effect 12: circle */
.cl-effect-12 a::before,
.cl-effect-12 a::after {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100px;
  height: 100px;
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  content: '';
  opacity: 0;
  -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
  -moz-transition: -moz-transform 0.3s, opacity 0.3s;
  transition: transform 0.3s, opacity 0.3s;
  -webkit-transform: translateX(-50%) translateY(-50%) scale(0.2);
  -moz-transform: translateX(-50%) translateY(-50%) scale(0.2);
  transform: translateX(-50%) translateY(-50%) scale(0.2);
}

.cl-effect-12 a::after {
  width: 90px;
  height: 90px;
  border-width: 6px;
  -webkit-transform: translateX(-50%) translateY(-50%) scale(0.8);
  -moz-transform: translateX(-50%) translateY(-50%) scale(0.8);
  transform: translateX(-50%) translateY(-50%) scale(0.8);
}

.cl-effect-12 a:hover::before,
.cl-effect-12 a:hover::after,
.cl-effect-12 a:focus::before,
.cl-effect-12 a:focus::after {
  opacity: 1;
  -webkit-transform: translateX(-50%) translateY(-50%) scale(1);
  -moz-transform: translateX(-50%) translateY(-50%) scale(1);
  transform: translateX(-50%) translateY(-50%) scale(1);
}

/* Effect 13: three circles */
.cl-effect-13 a {
  -webkit-transition: color 0.3s;
  -moz-transition: color 0.3s;
  transition: color 0.3s;
}

.cl-effect-13 a::before {
  position: absolute;
  top: 100%;
  left: 50%;
  color: transparent;
  content: '\2022';
  text-shadow: 0 0 transparent;
  font-size: 1.2em;
  -webkit-transition: text-shadow 0.3s, color 0.3s;
  -moz-transition: text-shadow 0.3s, color 0.3s;
  transition: text-shadow 0.3s, color 0.3s;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  transform: translateX(-50%);
  pointer-events: none;
}

.cl-effect-13 a:hover::before,
.cl-effect-13 a:focus::before {
  color: #007AFF;
  text-shadow: 10px 0 #007AFF, -10px 0 #007AFF;
}

.cl-effect-13 a:hover,
.cl-effect-13 a:focus {
  color: #007AFF;
}

/* Effect 14: border switch */
.cl-effect-14 a {
  padding: 0 20px;
  height: 45px;
  line-height: 45px;
}

.cl-effect-14 a::before,
.cl-effect-14 a::after {
  position: absolute;
  width: 45px;
  height: 2px;
  background: #007AFF;
  content: '';
  opacity: 0.2;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
  pointer-events: none;
}

.cl-effect-14 a::before {
  top: 0;
  left: 0;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  transform: rotate(90deg);
  -webkit-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  transform-origin: 0 0;
}

.cl-effect-14 a::after {
  right: 0;
  bottom: 0;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  transform: rotate(90deg);
  -webkit-transform-origin: 100% 0;
  -moz-transform-origin: 100% 0;
  transform-origin: 100% 0;
}

.cl-effect-14 a:hover::before,
.cl-effect-14 a:hover::after,
.cl-effect-14 a:focus::before,
.cl-effect-14 a:focus::after {
  opacity: 1;
}

.cl-effect-14 a:hover::before,
.cl-effect-14 a:focus::before {
  left: 50%;
  -webkit-transform: rotate(0deg) translateX(-50%);
  -moz-transform: rotate(0deg) translateX(-50%);
  transform: rotate(0deg) translateX(-50%);
}

.cl-effect-14 a:hover::after,
.cl-effect-14 a:focus::after {
  right: 50%;
  -webkit-transform: rotate(0deg) translateX(50%);
  -moz-transform: rotate(0deg) translateX(50%);
  transform: rotate(0deg) translateX(50%);
}

/* Effect 15: scale down, reveal */
.cl-effect-15 a {
  color: rgba(0, 122, 255, 0.7) !important;
  font-weight: 700;
  text-shadow: none;
}

.cl-effect-15 a::before {
  color: #8e8e93;
  content: attr(data-hover);
  position: absolute;
  -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
  -moz-transition: -moz-transform 0.3s, opacity 0.3s;
  transition: transform 0.3s, opacity 0.3s;
}

.cl-effect-15 a:hover::before,
.cl-effect-15 a:focus::before {
  -webkit-transform: scale(0.9);
  -moz-transform: scale(0.9);
  transform: scale(0.9);
  opacity: 0;
}

/* Effect 16: fall down */
.cl-effect-16 a {
  color: #8e8e93;
  text-shadow: 0 0 1px rgba(111, 134, 134, 0.3);
}

.cl-effect-16 a::before {
  color: #007AFF;
  content: attr(data-hover);
  position: absolute;
  opacity: 0;
  text-shadow: 0 0 1px rgba(255, 255, 255, 0.3);
  -webkit-transform: scale(1.1) translateX(10px) translateY(-10px) rotate(4deg);
  -moz-transform: scale(1.1) translateX(10px) translateY(-10px) rotate(4deg);
  transform: scale(1.1) translateX(10px) translateY(-10px) rotate(4deg);
  -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
  -moz-transition: -moz-transform 0.3s, opacity 0.3s;
  transition: transform 0.3s, opacity 0.3s;
  pointer-events: none;
}

.cl-effect-16 a:hover::before,
.cl-effect-16 a:focus::before {
  -webkit-transform: scale(1) translateX(0px) translateY(0px) rotate(0deg);
  -moz-transform: scale(1) translateX(0px) translateY(0px) rotate(0deg);
  transform: scale(1) translateX(0px) translateY(0px) rotate(0deg);
  opacity: 1;
}

/* Effect 17: move up fade out, push border */
.cl-effect-17 a {
  color: #8e8e93;
  text-shadow: none;
  padding: 10px 0;
}

.cl-effect-17 a::before {
  color: #007AFF;
  text-shadow: 0 0 1px rgba(255, 255, 255, 0.3);
  content: attr(data-hover);
  position: absolute;
  -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
  -moz-transition: -moz-transform 0.3s, opacity 0.3s;
  transition: transform 0.3s, opacity 0.3s;
  pointer-events: none;
}

.cl-effect-17 a::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  background: #007AFF;
  opacity: 0;
  -webkit-transform: translateY(5px);
  -moz-transform: translateY(5px);
  transform: translateY(5px);
  -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
  -moz-transition: -moz-transform 0.3s, opacity 0.3s;
  transition: transform 0.3s, opacity 0.3s;
  pointer-events: none;
}

.cl-effect-17 a:hover::before,
.cl-effect-17 a:focus::before {
  opacity: 0;
  -webkit-transform: translateY(-2px);
  -moz-transform: translateY(-2px);
  transform: translateY(-2px);
}

.cl-effect-17 a:hover::after,
.cl-effect-17 a:focus::after {
  opacity: 1;
  -webkit-transform: translateY(0px);
  -moz-transform: translateY(0px);
  transform: translateY(0px);
}

/* Effect 18: cross */
.cl-effect-18 {
  position: relative;
  z-index: 1;
}

.cl-effect-18 a {
  padding: 0 5px;
  color: #8e8e93;
  font-weight: 700;
  -webkit-transition: color 0.3s;
  -moz-transition: color 0.3s;
  transition: color 0.3s;
}

.cl-effect-18 a::before,
.cl-effect-18 a::after {
  position: absolute;
  width: 100%;
  left: 0;
  top: 50%;
  height: 2px;
  margin-top: -1px;
  background: #007AFF;
  content: '';
  z-index: -1;
  -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
  -moz-transition: -moz-transform 0.3s, opacity 0.3s;
  transition: transform 0.3s, opacity 0.3s;
  pointer-events: none;
}

.cl-effect-18 a::before {
  -webkit-transform: translateY(-20px);
  -moz-transform: translateY(-20px);
  transform: translateY(-20px);
}

.cl-effect-18 a::after {
  -webkit-transform: translateY(20px);
  -moz-transform: translateY(20px);
  transform: translateY(20px);
}

.cl-effect-18 a:hover,
.cl-effect-18 a:focus {
  color: #007AFF;
}

.cl-effect-18 a:hover::before,
.cl-effect-18 a:hover::after,
.cl-effect-18 a:focus::before,
.cl-effect-18 a:focus::after {
  opacity: 0.3;
}

.cl-effect-18 a:hover::before,
.cl-effect-18 a:focus::before {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  transform: rotate(45deg);
}

.cl-effect-18 a:hover::after,
.cl-effect-18 a:focus::after {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

/* Effect 19: 3D side */
.cl-effect-19 a {
  line-height: 2em;
  margin: 15px;
  -webkit-perspective: 800px;
  -moz-perspective: 800px;
  perspective: 800px;
  width: 200px;
}

.cl-effect-19 a span {
  position: relative;
  display: inline-block;
  width: 100%;
  padding: 0 14px;
  background: #007AFF;
  color: #ffffff;
  -webkit-transition: -webkit-transform 0.4s, background 0.4s;
  -moz-transition: -moz-transform 0.4s, background 0.4s;
  transition: transform 0.4s, background 0.4s;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform-origin: 50% 50% -100px;
  -moz-transform-origin: 50% 50% -100px;
  transform-origin: 50% 50% -100px;
}

.csstransforms3d .cl-effect-19 a span::before {
  position: absolute;
  top: 0;
  left: 100%;
  width: 100%;
  height: 100%;
  background: #0062cc;
  content: attr(data-hover);
  -webkit-transition: background 0.4s;
  -moz-transition: background 0.4s;
  transition: background 0.4s;
  -webkit-transform: rotateY(90deg);
  -moz-transform: rotateY(90deg);
  transform: rotateY(90deg);
  -webkit-transform-origin: 0 50%;
  -moz-transform-origin: 0 50%;
  transform-origin: 0 50%;
  pointer-events: none;
}

.cl-effect-19 a:hover span,
.cl-effect-19 a:focus span {
  background: #0062cc;
  -webkit-transform: rotateY(-90deg);
  -moz-transform: rotateY(-90deg);
  transform: rotateY(-90deg);
}

.csstransforms3d .cl-effect-19 a:hover span::before,
.csstransforms3d .cl-effect-19 a:focus span::before {
  background: #3395ff;
}

/* Effect 20: 3D side */
.cl-effect-20 a {
  line-height: 2em;
  -webkit-perspective: 800px;
  -moz-perspective: 800px;
  perspective: 800px;
}

.cl-effect-20 a span {
  position: relative;
  display: inline-block;
  padding: 3px 15px 0;
  background: #007AFF;
  box-shadow: inset 0 3px #0062cc;
  color: #ffffff;
  -webkit-transition: background 0.6s;
  -moz-transition: background 0.6s;
  transition: background 0.6s;
  -webkit-transform-origin: 50% 0;
  -moz-transform-origin: 50% 0;
  transform-origin: 50% 0;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform-origin: 0% 50%;
  -moz-transform-origin: 0% 50%;
  transform-origin: 0% 50%;
}

.cl-effect-20 a span::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #3395ff;
  color: #ffffff;
  content: attr(data-hover);
  -webkit-transform: rotateX(270deg);
  -moz-transform: rotateX(270deg);
  transform: rotateX(270deg);
  -webkit-transition: -webkit-transform 0.6s;
  -moz-transition: -moz-transform 0.6s;
  transition: transform 0.6s;
  -webkit-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  transform-origin: 0 0;
  pointer-events: none;
}

.cl-effect-20 a:hover span,
.cl-effect-20 a:focus span {
  background: #0062cc;
}

.cl-effect-20 a:hover span::before,
.cl-effect-20 a:focus span::before {
  -webkit-transform: rotateX(10deg);
  -moz-transform: rotateX(10deg);
  transform: rotateX(10deg);
}

/* Effect 21: borders slight translate */
.cl-effect-21 a {
  padding: 10px;
  color: #237546;
  font-weight: 700;
  text-shadow: none;
  -webkit-transition: color 0.3s;
  -moz-transition: color 0.3s;
  transition: color 0.3s;
}

.cl-effect-21 a::before,
.cl-effect-21 a::after {
  position: absolute;
  left: 0;
  width: 100%;
  height: 2px;
  background: #fff;
  content: '';
  opacity: 0;
  -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
  -moz-transition: opacity 0.3s, -moz-transform 0.3s;
  transition: opacity 0.3s, transform 0.3s;
  -webkit-transform: translateY(-10px);
  -moz-transform: translateY(-10px);
  transform: translateY(-10px);
}

.cl-effect-21 a::before {
  top: 0;
  -webkit-transform: translateY(-10px);
  -moz-transform: translateY(-10px);
  transform: translateY(-10px);
}

.cl-effect-21 a::after {
  bottom: 0;
  -webkit-transform: translateY(10px);
  -moz-transform: translateY(10px);
  transform: translateY(10px);
}

.cl-effect-21 a:hover,
.cl-effect-21 a:focus {
  color: #fff;
}

.cl-effect-21 a:hover::before,
.cl-effect-21 a:focus::before,
.cl-effect-21 a:hover::after,
.cl-effect-21 a:focus::after {
  opacity: 1;
  -webkit-transform: translateY(0px);
  -moz-transform: translateY(0px);
  transform: translateY(0px);
}

/* ---------------------------------------------------------------------- */
/*  Form Wizard
 /* ---------------------------------------------------------------------- */
.swMain > ul {
  display: table;
  list-style: none;
  margin: 0 0 20px 0;
  padding: 10px 0;
  position: relative;
  width: 100%;
  background: #f7f7f8;
  border-radius: 5px;
}

.swMain > ul li {
  display: table-cell;
  text-align: center;
  width: 1%;
}

.swMain > ul li > a:before {
  border-top: 4px solid #c8c7cc;
  content: "";
  display: block;
  font-size: 0;
  height: 1px;
  overflow: hidden;
  position: relative;
  top: 21px;
  width: 100%;
  z-index: 1;
}

.swMain > ul li:first-child > a:before {
  left: 50%;
  max-width: 51%;
}

.swMain > ul li:last-child > a:before {
  max-width: 50%;
  width: 50%;
}

.swMain > ul li > a.selected:before, .swMain li > a.done:before {
  border-color: #007AFF;
}

.swMain > ul .stepNumber {
  background-color: #ffffff;
  border: 5px solid #c8c7cc;
  border-radius: 100% 100% 100% 100%;
  color: #546474;
  display: inline-block;
  font-size: 15px;
  height: 40px;
  line-height: 30px;
  position: relative;
  text-align: center;
  width: 40px;
  z-index: 2;
}

.swMain > ul li > a.selected .stepNumber {
  border-color: #007AFF;
}

.swMain ul li > a.done .stepNumber, .swMain > ul li:last-child > a.selected .stepNumber {
  border-color: #007AFF;
  background-color: #007AFF;
  color: #fff;
  text-indent: -9999px;
}

.swMain ul li > a.done .stepNumber:before, .swMain > ul li:last-child > a.selected .stepNumber:before {
  content: "\f00c";
  display: inline;
  float: right;
  font-family: FontAwesome;
  font-weight: 300;
  height: auto;
  text-shadow: none;
  margin-right: 7px;
  text-indent: 0;
}

.swMain ul li > a.done.wait .stepNumber {
  background-color: #F6F6F6 !important;
  color: #CCCCCC !important;
  text-indent: -0px !important;
}

.swMain ul li > a.done.wait .stepNumber:before {
  content: "" !important;
}

.swMain > ul li .stepDesc {
  color: #8e8e93;
  display: block;
  font-size: 14px;
  margin-top: 4px;
  max-width: 100%;
  table-layout: fixed;
  text-align: center;
  word-wrap: break-word;
  z-index: 104;
}

.swMain > ul li > a.selected .stepDesc, .swMain li > a.done .stepDesc {
  color: #2B3D53;
}

.swMain > ul li > a:hover {
  text-decoration: none;
}

.swMain > ul li > a.disabled {
  cursor: default;
}

.swMain .progress {
  margin-bottom: 30px;
}

.swMain .stepContainer {
  height: auto !important;
}

.swMain .loader {
  display: none;
}

.swMain [class^="button"], .swMain [class*=" button"] {
  display: none;
}

.swMain .close {
  display: none;
}

/* ---------------------------------------------------------------------- */
/*  Login
/* ---------------------------------------------------------------------- */
.main-login {
  margin-top: 0;
  position: relative;
}
@media (max-width: 991px) {
  .main-login {
    margin-top: 65px;
  }
}
.main-login .logo {
  padding: 0 10px;
}
.main-login .box-login, .main-login .box-forgot, .main-login .box-register {
  background: #FFFFFF;
  border-radius: 5px;
  overflow: hidden;
  padding: 15px;
  margin: 15px 0 65px 0;
}
.main-login .form fieldset {
  border: none;
  margin: 0;
  padding: 10px 0 0;
}
.main-login a.forgot {
  color: #909090;
  font-size: 12px;
  position: absolute;
  right: 10px;
  text-shadow: 1px 1px 1px #FFFFFF;
  top: 9px;
}
.main-login input.password {
  padding-right: 130px;
}
.main-login label {
  color: #7F7F7F;
  font-size: 14px;
  margin-top: 5px;
}
.main-login .copyright {
  font-size: 11px;
  margin: 0 auto;
  padding: 10px 10px 0;
  text-align: center;
}
.main-login .form-actions:before, .main-login .form-actions:after {
  content: "";
  display: table;
  line-height: 0;
}
.main-login .form-actions:after {
  clear: both;
}
.main-login .form-actions {
  margin-top: 15px;
  padding-top: 10px;
  display: block;
}
.main-login .new-account {
  border-top: 1px dotted #EEEEEE;
  margin-top: 15px;
  padding-top: 10px;
  display: block;
}

/* ---------------------------------------------------------------------- */
/*  Lock Screen
/* ---------------------------------------------------------------------- */
.lock-screen {
  margin: -150px 0 0 -100px;
  width: 200px;
  left: 50%;
  position: absolute;
  top: 50%;
}

.lock-screen .box-ls {
  overflow: hidden;
  text-align: center;
}

.lock-screen .user-info {
  margin: 10px 0;
}

.lock-screen .user-info h4 {
  color: #666666;
  font-family: 'Raleway', sans-serif;
  font-weight: 300;
  margin-top: 0;
}

.lock-screen .user-info > span {
  color: #666666;
  display: block;
  font-size: 12px;
  margin-bottom: 5px;
}

/* ---------------------------------------------------------------------- */
/*  Invoice
/* ---------------------------------------------------------------------- */
.invoice {
  margin-bottom: 20px;
}

.invoice .invoice-logo {
  margin-bottom: 20px;
}

.invoice table {
  margin: 30px 0;
}

.invoice .invoice-logo p {
  font-size: 20px;
  line-height: 28px;
  padding: 25px 0;
  text-align: right;
}

.invoice .invoice-logo p small {
  display: block;
  font-size: 14px;
}

.invoice h4 {
  font-family: 'Open Sans', sans-serif;
  font-weight: 300 !important;
}

.invoice-details {
  padding-top: 30px;
}

.invoice .invoice-block {
  text-align: right;
}

.invoice .invoice-block .amounts {
  font-size: 14px;
  margin-top: 20px;
}

/* ---------------------------------------------------------------------- */
/*  Timeline
/* ---------------------------------------------------------------------- */
div.timeline {
  margin: 0;
  overflow: hidden;
  position: relative;
}
div.timeline .columns {
  margin: 0;
  padding: 0;
  list-style: none;
}
div.timeline .columns > li:nth-child(2n+1) {
  float: left;
  width: 50%;
  clear: left;
}
div.timeline .columns > li:nth-child(2n+1) .timeline_element {
  float: right;
  left: 10%;
  margin-right: 30px;
  left: 0;
  opacity: 1;
}
div.timeline .columns > li:nth-child(2n+1) .timeline_element:before {
  right: -27px;
  top: 15px;
}
div.timeline .columns > li:nth-child(2n+1) .timeline_element:after {
  right: -35px;
  top: 10px;
}
div.timeline .columns > li:nth-child(2n+2) {
  float: right;
  margin-top: 20px;
  width: 50%;
  clear: right;
}
div.timeline .columns > li:nth-child(2n+2) .timeline_element {
  float: left;
  margin-left: 30px;
  right: 10%;
  opacity: 1;
  right: 0;
}
div.timeline .columns > li:nth-child(2n+2) .timeline_element:before {
  left: -27px;
  top: 15px;
}
div.timeline .columns > li:nth-child(2n+2) .timeline_element:after {
  left: -35px;
  top: 10px;
}
div.timeline .date_separator {
  clear: both;
  height: 60px;
  position: relative;
  text-align: center;
}
div.timeline .date_separator span {
  border-radius: 5px;
  height: 30px;
  line-height: 30px;
  margin-top: -16px;
  position: absolute;
  top: -200%;
  width: 200px;
  top: 50%;
  left: 50%;
  margin-left: -100px;
  background-color: #007AFF;
  color: #ffffff;
}
div.timeline .spine {
  border-radius: 2px;
  position: absolute;
  top: 0;
  width: 4px;
  left: 50%;
  margin-left: -2px;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.1);
}
div.timeline .column_center .timeline_element {
  margin: 20px auto;
  opacity: 1;
}
div.timeline .column_center .timeline_element:before, div.timeline .column_center .timeline_element:after {
  display: none;
}

.timeline_element {
  border-radius: 5px;
  clear: both;
  margin: 30px 0;
  padding: 20px;
  opacity: 0;
  position: relative;
  transition: all 0.2s linear 0s;
  min-width: 66.6667%;
  text-shadow: none;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
}
.timeline_element.partition-white {
  border: 1px solid rgba(0, 0, 0, 0.07);
}
.timeline_element.partition-white:hover {
  border: 1px solid rgba(0, 0, 0, 0.04);
}
.timeline_element.partition-white:after {
  background-color: #ffffff;
}
.timeline_element.partition-white:hover:after {
  background-color: #c3c2c7;
  border: 1px solid #ffffff;
}
.timeline_element.partition-green {
  border: none;
  color: #ffffff;
}
.timeline_element.partition-green:hover {
  border: none;
}
.timeline_element.partition-green:after {
  background-color: #1FBBA6;
}
.timeline_element.partition-green:hover:after {
  background-color: #ffffff;
  border: 1px solid #1FBBA6;
}
.timeline_element.partition-orange {
  border: none;
  color: #ffffff;
}
.timeline_element.partition-orange:hover {
  border: none;
}
.timeline_element.partition-orange:after {
  background-color: #FF6600;
}
.timeline_element.partition-orange:hover:after {
  background-color: #ffffff;
  border: 1px solid #FF6600;
}
.timeline_element.partition-blue {
  border: none;
  color: #ffffff;
}
.timeline_element.partition-blue:hover {
  border: none;
}
.timeline_element.partition-blue:after {
  background-color: #5F8295;
}
.timeline_element.partition-blue:hover:after {
  background-color: #ffffff;
  border: 1px solid #5F8295;
}
.timeline_element.partition-red {
  border: none;
  color: #ffffff;
}
.timeline_element.partition-red:hover {
  border: none;
}
.timeline_element.partition-red:after {
  background-color: #C82E29;
}
.timeline_element.partition-red:hover:after {
  background-color: #ffffff;
  border: 1px solid #C82E29;
}
.timeline_element.partition-azure {
  border: none;
  color: #ffffff;
}
.timeline_element.partition-azure:hover {
  border: none;
}
.timeline_element.partition-azure:after {
  background-color: #0095C8;
}
.timeline_element.partition-azure:hover:after {
  background-color: #ffffff;
  border: 1px solid #0095C8;
}
.timeline_element.partition-purple {
  border: none;
  color: #ffffff;
}
.timeline_element.partition-purple:hover {
  border: none;
}
.timeline_element.partition-purple:after {
  background-color: #804C75;
}
.timeline_element.partition-purple:hover:after {
  background-color: #ffffff;
  border: 1px solid #804C75;
}
.timeline_element:hover {
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
}
.timeline_element:before {
  content: "";
  display: block;
  height: 0;
  position: absolute;
  width: 26px;
  border-top: 1px dashed #CCCCCC;
}
.timeline_element:after {
  border-radius: 100%;
  content: "";
  display: block;
  height: 10px;
  position: absolute;
  width: 10px;
  background-color: #BBBBBB;
  border: 1px solid #FFFFFF;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2);
}
.timeline_element:hover:after {
  z-index: 100;
  background-color: #FFFFFF;
  border: 1px solid #CCCCCC;
  z-index: 100;
}
.timeline_element .timeline_title {
  overflow: hidden;
  position: relative;
  text-transform: uppercase;
  padding-top: 10px;
  white-space: nowrap;
}
.timeline_element .timeline_title h4 {
  line-height: 30px;
}
.timeline_element .timeline_date {
  display: block;
}
.timeline_element .timeline_date .day {
  font-size: 52px;
  letter-spacing: -2px;
}
.timeline_element .timeline_content {
  padding-top: 10px;
  padding-bottom: 10px;
}
.timeline_element .readmore {
  padding: 10px 0;
  text-align: right;
}

.timeline-scrubber {
  padding: 8px 0 8px 1px;
  top: 60px;
  right: 0;
  width: 100px;
  z-index: 1;
  list-style: none;
  position: absolute;
}
.timeline-scrubber li {
  margin-bottom: 1px;
}
.timeline-scrubber li:nth-last-child(2) a {
  border-color: white;
  color: white;
}
.timeline-scrubber li:last-child a {
  border-color: white;
  color: white;
}
.timeline-scrubber a {
  border-left: 5px solid #f7f7f8;
  color: #f7f7f8;
  display: block;
  font-weight: normal;
  outline: medium none;
  padding: 4px 0 4px 6px;
}
.timeline-scrubber a:hover {
  border-color: #c3c2c7 !important;
  color: #c3c2c7 !important;
}
.timeline-scrubber .selected > a {
  border-left-color: #aeacb4 !important;
  color: #aeacb4 !important;
  font-weight: bold !important;
}

/* ie8 fixes */
.ie8 div.timeline_element:after {
  display: none;
}

.ie8 div.timeline_element:before {
  display: none;
}

/**/
.timeline-xs {
  margin: 0;
  padding: 0;
  list-style: none;
}
.timeline-xs .timeline-item {
  position: relative;
  border-left: 1px solid #c8c7cc;
}
.timeline-xs .timeline-item:after {
  background-color: #fff;
  border-color: #007AFF;
  border-radius: 10px;
  border-style: solid;
  border-width: 1px;
  bottom: 0;
  content: "";
  height: 9px;
  left: 0;
  margin-left: -5px;
  position: absolute;
  width: 9px;
}
.timeline-xs .timeline-item p {
  margin: 0;
  padding-bottom: 10px;
}
.timeline-xs .timeline-item.success {
  border-left-color: #5cb85c;
}
.timeline-xs .timeline-item.success:after {
  border-color: #5cb85c;
}
.timeline-xs .timeline-item.danger {
  border-left-color: #d43f3a;
}
.timeline-xs .timeline-item.danger:after {
  border-color: #d43f3a;
}
.timeline-xs .timeline-item.info {
  border-left-color: #46b8da;
}
.timeline-xs .timeline-item.info:after {
  border-color: #46b8da;
}
.timeline-xs .timeline-item.warning {
  border-left-color: #eea236;
}
.timeline-xs .timeline-item.warning:after {
  border-color: #eea236;
}
.timeline-xs .timeline-item:before, .timeline-xs .timeline-item:after {
  content: " ";
  display: table;
}
.timeline-xs .timeline-item:after {
  clear: both;
  bottom: auto;
  top: 4px;
}

@media (max-width: 991px) {
  /* 991px */
  div.timeline {
    margin: 0;
  }
  div.timeline .columns li {
    float: none !important;
    width: 100% !important;
  }

  .timeline_element {
    margin: 20px auto !important;
  }
  .timeline_element:after {
    display: none;
  }
  .timeline_element:before {
    display: none;
  }

  .timeline-scrubber {
    display: none;
  }
}
/* ---------------------------------------------------------------------- */
/*  Tables
/* ---------------------------------------------------------------------- */
.table-detail > tbody > tr > td
{
  border-bottom: none !important;
}
.table-detail > tbody > tr > td:nth-child(2)
{
  vertical-align: top;
  color: #5b5b60 !important;
  font-weight: bold;
}

.table-detail > tbody > tr > td:first-child {
  vertical-align: top;
  color: #8e8e93 !important;
};

.table {
  border-collapse: separate;
}

.table > thead > tr > th {
  border-top: none;
}

.table-bordered.table > thead > tr > th {
  border-bottom: 1px solid #ddd;
}

.table > thead > tr > th, .table > tbody > tr > th, .table > tfoot > tr > th, .table > thead > tr > td, .table > tbody > tr > td, .table > tfoot > tr > td {
  border-bottom: 1px solid #e2e2e4;
  color: #5b5b60 !important;
  border-top: none;
}

.table-form > tbody > tr > td {
  border-bottom: none;
  border-top: none;
}

.table-hover > tbody > tr:hover > td, .table-hover > tbody > tr:hover > th {
  background-color: #e7e7e9;
}

.table > tbody > tr.active > td, .table > tbody > tr.active > th {
  background-color: #e7e7e9 !important;
  color: #5b5b60 !important;
}

.table > tbody > tr.active:hover > td, .table > tbody > tr.active:hover > th {
  background-color: #dddcdf !important;
  color: #5b5b60 !important;
}

th.center, td.center {
  text-align: center;
}

td.center .dropdown-menu {
  text-align: left;
}

td.small-width {
  width: 15%;
}

.table thead tr {
  color: #707070;
  font-weight: normal;
}

.table.table-bordered {
  border-right: none;
  border-bottom: none;
}

.table.table-bordered thead tr th {
  vertical-align: middle;
}

.table.table-bordered > thead > tr > th, .table.table-bordered > tbody > tr > th, .table.table-bordered > tfoot > tr > th, .table.table-bordered > thead > tr > td, .table.table-bordered > tbody > tr > td, .table.table-bordered > tfoot > tr > td {
  border-top: none;
  border-left: none;
}

.table.table-bordered > thead > tr > th, .table.table-bordered > tbody > tr > th, .table.table-bordered > tfoot > tr > th, .table.table-bordered > thead > tr > td, .table.table-bordered > tbody > tr > td, .table.table-bordered > tfoot > tr > td {
  border-top: none;
  border-left: none;
}

.table thead > tr > th, .table tbody > tr > th, .table tfoot > tr > th, .table thead > tr > td, .table tbody > tr > td, .table tfoot > tr > td {
  vertical-align: middle;
}

.table-striped > tbody > tr:nth-child(2n+1) {
  background-color: #efeff0;
}


.editable-element {
  border-bottom: 1px dashed #0088CC;
  text-decoration: none;
  opacity: 0.5;
  display: inline;
}

.ng-table th {
  text-align: left !important;
}

.ng-table .plus, .ng-table .minus {
  font-weight: bold;
  padding-left: 18px;
  position: relative;
}

.ng-table .plus:before, .ng-table .minus:before {
  content: "";
  border-width: 4px;
  border-style: solid;
  left: 8px;
  top: 50%;
  position: absolute;
  margin-top: -2px;
}

.ng-table .plus {
  color: green;
}

.ng-table .plus:before {
  border-color: green;
  border-top: none;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
}

.ng-table .minus {
  color: red;
}

.ng-table .minus:before {
  border-color: red;
  border-bottom: none;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
}

.odd {
  background: #EFEFF0;
}

.even {
  background: #ffffff;
}

@media (max-width: 767px) {
  .ng-table-pager {
    text-align: center;
  }
  .ng-table-pager .ng-table-counts {
    float: none !important;
  }
  .ng-table-pager .pagination {
    display: block;
  }
  .ng-table-pager .pagination li {
    display: inline-block;
  }
}

/* ---------------------------------------------------------------------- */
/*  User profile
/* ---------------------------------------------------------------------- */
.user-left {
  border-right: 1px solid #DDDDDD;
  padding-right: 15px;
}
@media (max-width: 991px) {
  .user-left {
    border-right: none;
    padding-right: 0;
    margin-bottom: 15px;
  }
}

.user-left a:hover, .user-left a:focus {
  text-decoration: none;
}

.user-left td .edit-user-info {
  visibility: hidden;
}

.user-left tr:hover .edit-user-info {
  visibility: visible;
}

.user-image {
  position: relative;
  display: inline-block;
}

.user-image img {
  max-width: 150px;
}

.user-image .user-image-buttons {
  position: absolute;
  top: 10px;
  right: 10px;
  display: none;
}

.user-image:hover .user-image-buttons {
  display: block;
}

.user-edit-image-buttons {
  display: block;
}

#projects .progress {
  margin-bottom: 0;
}

.ltwt {
  font-size: 12px;
  line-height: 15px;
  list-style: outside none none;
  margin: 0;
  padding: 0;
}
.ltwt .ltwt_tweet {
  margin-bottom: 30px;
  padding: 0 0 0 45px;
  position: relative;
}
.ltwt .ltwt_tweet:before {
  content: "\e74b";
  font-family: 'themify';
  font-size: 30px;
  margin-left: -45px;
  position: absolute;
  top: 6px;
  color: #46b8da;
}
.ltwt .ltwt_tweet .ltwt_tweet_text {
  margin-bottom: 5px;
  margin-top: 0;
}

/* ---------------------------------------------------------------------- */
/*  Calendar
/* ---------------------------------------------------------------------- */
.calAlert {
  width: 595px;
  float: right;
  margin-bottom: 5px;
}

.calXBtn {
  float: right;
  margin-top: -5px;
  margin-right: -5px;
}

.calWell {
  float: left;
  margin-bottom: 40px;
}

.fc-event.openSesame .fc-event-skin {
  background-color: #e5e50b;
  color: black;
}

.fc-event.customFeed .fc-event-skin {
  background-color: #84deaf;
  color: black;
}

.calTools {
  margin-bottom: 10px;
}

/* ---------------------------------------------------------------------- */
/*  ToDo
/* ---------------------------------------------------------------------- */
.todo {
  list-style: none;
  margin: 0;
  padding: 0;
}
.todo li .todo-actions {
  background: white;
  border-bottom: 1px solid #E4E6EB;
  margin-left: 0 !important;
  padding: 10px 10px 10px 35px !important;
  position: relative;
  display: block;
  color: #8B91A0;
}
.todo li .todo-actions:hover, .todo li .todo-actions:focus {
  text-decoration: none;
  background-color: #F4F6F9 !important;
}
.todo li .todo-actions > i {
  color: #C7CBD5;
  font-size: 18px;
  margin: 0 5px 0 0;
  position: absolute;
  left: 10px;
  top: 11px;
  cursor: pointer;
}
.todo li .label {
  position: absolute;
  right: 10px;
  padding: 6px;
}
.todo .todo-tools {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 2;
}

/* ---------------------------------------------------------------------- */
/*  Chat
/* ---------------------------------------------------------------------- */
.discussion {
  list-style: none;
  margin: 0 !important;
  padding: 0 !important;
  display: inline-block;
}
.discussion .other {
  float: left;
}
.discussion .other .message:last-child .message-text {
  border-radius: 16px 16px 16px 0;
  float: left;
}
.discussion .other .message:last-child .message-avatar {
  display: block !important;
}
.discussion .other .message:last-child .message-name {
  display: none;
}
.discussion .other .message {
  float: left;
}
.discussion .other .message:first-child .message-name {
  display: block !important;
}
.discussion .other .message-text {
  background-color: #e5e5ea;
  color: #2C2F3B;
  padding-left: 22px;
  margin-left: 32px;
}
.discussion .other .message-avatar {
  left: 10px;
}
.discussion .self {
  float: right;
}
.discussion .self .message:last-child .message-text {
  border-radius: 16px 16px 0;
  float: left;
}
.discussion .self .message:last-child .message-avatar {
  display: block !important;
}
.discussion .self .message:last-child .message-name {
  display: none;
}
.discussion .self .message {
  float: right;
}
.discussion .self .message:first-child .message-name {
  display: block !important;
}
.discussion .self .message-text {
  background-color: #00d449;
  color: #fff;
  padding-right: 22px;
  margin-right: 32px;
}
.discussion .self .message-avatar {
  float: right;
}
.discussion .self .message-name {
  margin-left: auto !important;
  margin-right: 48px;
  text-align: right;
}
.discussion .self .message-avatar {
  right: 10px;
}
.discussion li {
  position: relative;
  margin: 0 0 10px 0;
  width: 80%;
}
.discussion li.messages-date {
  display: inline-block;
  color: #8e8e93;
  font-size: 11px;
  font-weight: 500;
  line-height: 1;
  margin: 10px 0;
  text-align: center;
  width: 100% !important;
}
.discussion .message {
  margin: 1px 10px 0 10px;
  clear: both;
}
.discussion .message-name {
  color: #8e8e93;
  font-size: 12px;
  line-height: 1;
  margin-bottom: 2px;
  margin-top: 7px;
  margin-left: 48px;
  display: none;
}
.discussion .message-text {
  border-radius: 16px;
  box-sizing: border-box;
  font-size: 15px;
  line-height: 1.2;
  min-height: 30px;
  min-width: 48px;
  padding: 6px 16px 9px;
}
.discussion .message-avatar {
  display: none;
  position: absolute;
  bottom: -5px;
  height: 29px;
  width: 29px;
}
.discussion .message-avatar img {
  border-radius: 100%;
  width: 100%;
}

.message-bar {
  position: relative;
  background: #f7f7f8;
  height: 44px;
  width: 100%;
  display: table;
}
.message-bar .message-inner {
  height: 100%;
  padding: 0 8px;
  display: table-row;
  width: 100%;
}
.message-bar .message-area {
  display: table-cell;
}
.message-bar textarea {
  background: #fff;
  border: 1px solid #c8c7cc;
  border-radius: 5px;
  box-shadow: none;
  font-family: inherit;
  height: 28px;
  line-height: 20px;
  margin: 8px 0;
  padding: 3px 8px;
  resize: none;
  width: 100%;
}
.message-bar .link {
  padding: 0 10px;
  line-height: 44px;
  position: relative;
  display: table-cell;
  width: 30px;
  vertical-align: middle;
}
.message-bar a.icon-only {
  color: #8e8e93;
  font-size: 20px;
  margin: 0;
  margin-right: 8px;
}

/* ---------------------------------------------------------------------- */
/*  Messages
/* ---------------------------------------------------------------------- */
.panel-body.messages {
  padding: 0;
}

.messages-list {
  border-right: 1px solid white;
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  overflow-x: hidden;
  overflow-y: hidden;
  width: 100%;
  top: 65px;
  bottom: 0;
}
.messages-list .messages-item {
  border-bottom: 1px solid #c8c7cc;
  padding: 5px 15px 5px 25px;
  position: relative;
}
.messages-list .messages-item:hover {
  background-color: white;
  cursor: pointer !important;
}
.messages-list .messages-item:hover .messages-item-star {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=30);
  opacity: 0.3;
}
.messages-list .messages-item.active {
  background-color: white;
}
.messages-list .messages-item.starred .messages-item-star {
  display: block;
  filter: progid:DXImageTransform.Microsoft.Alpha(enabled=false);
  opacity: 1;
}
.messages-list .messages-item span {
  color: #007AFF;
  display: block;
}
.messages-list .messages-item span .spam {
  font-style: normal;
}
.messages-list .messages-item .messages-item-star, .messages-list .messages-item .messages-item-attachment, .messages-list .messages-item .messages-item-time, .messages-list .messages-item .messages-item-actions {
  position: absolute;
}
.messages-list .messages-item .messages-item-star {
  color: #007AFF;
  left: 7px;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  -moz-transition: opacity, 0.3s, ease-in-out 0s;
  -o-transition: opacity, 0.3s, ease-in-out 0s;
  -webkit-transition: opacity, 0.3s, ease-in-out 0s;
  transition: opacity, 0.3s, ease-in-out 0s;
}
.messages-list .messages-item .messages-item-star:hover, .messages-list .messages-item .messages-item-star:active, .messages-list .messages-item .messages-item-star:focus {
  filter: progid:DXImageTransform.Microsoft.Alpha(enabled=false);
  opacity: 1;
}
.messages-list .messages-item .messages-item-attachment {
  left: 7px;
  top: 25px;
  color: #5F8295;
}
.messages-list .messages-item .messages-item-avatar {
  border-radius: 4px;
  float: left;
  height: 40px;
  width: 40px;
}
.messages-list .messages-item .messages-item-from, .messages-list .messages-item .messages-item-subject, .messages-list .messages-item .messages-item-content {
  margin-left: 45px;
}
.messages-list .messages-item .messages-item-from {
  font-weight: bold;
  margin-top: 2px;
  color: #aeacb4;
}
.messages-list .messages-item .messages-item-time {
  right: 15px;
  top: 8px;
}
.messages-list .messages-item .messages-item-time .text {
  color: #8e8e93;
  font-size: 11px;
}
.messages-list .messages-item .messages-item-time .messages-item-actions {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  right: 0;
  top: 0;
  -moz-transition: opacity, 0.3s, ease-in-out 0s;
  -o-transition: opacity, 0.3s, ease-in-out 0s;
  -webkit-transition: opacity, 0.3s, ease-in-out 0s;
  transition: opacity, 0.3s, ease-in-out 0s;
  width: 65px;
}
.messages-list .messages-item .messages-item-time .messages-item-actions > a, .messages-list .messages-item .messages-item-time .messages-item-actions .dropdown > a {
  margin-left: 5px;
}
.messages-list .messages-item .messages-item-time .messages-item-actions > div {
  display: inline-block;
}
.messages-list .messages-item .messages-item-time .messages-item-actions .dropdown-menu {
  margin-top: 0;
}
.messages-list .messages-item .messages-item-time .messages-item-actions .tag-icon {
  border-radius: 30px;
  display: inline-block;
  height: 11px;
  margin: 0 5px 0 -13px;
  width: 11px;
}
.messages-list .messages-item .messages-item-time .messages-item-actions .tag-icon.red {
  background: #C82E29;
}
.messages-list .messages-item .messages-item-time .messages-item-actions .tag-icon.blue {
  background: #5F8295;
}
.messages-list .messages-item .messages-item-time .messages-item-actions .tag-icon.green {
  background: #1FBBA6;
}
.messages-list .messages-item .messages-item-subject {
  font-size: 14px;
  margin-bottom: 4px;
  color: #5b5b60;
}
.messages-list .messages-item .messages-item-content {
  font-size: 12px;
  color: #c2c2c5;
  margin-bottom: 4px;
}
.messages-list .messages-item .messages-item-preview {
  color: #aeacb4;
  font-size: 12px;
}

.wrap-options {
  border-bottom: 1px solid #c8c7cc;
  min-height: 65px;
}

.messages-search {
  padding: 15px;
  width: 100%;
}
@media (max-width: 1200px) {
  .messages-search {
    display: none;
    position: absolute;
    top: 0;
  }
}

.message-actions {
  height: 65px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  background-color: #EFEFF4;
}
.message-actions .actions {
  list-style: none;
}
.message-actions .actions > li {
  float: left;
  height: 65px;
  position: relative;
}
@media (max-width: 480px) {
  .message-actions .actions > li {
    display: none;
  }
}
.message-actions .actions > li:after {
  background: rgba(0, 0, 0, 0.07);
  content: "";
  height: 14px;
  position: absolute;
  right: -4px;
  top: 26px;
  width: 1px;
}
.message-actions .actions > li.actions-dropdown {
  display: none;
}
@media (max-width: 480px) {
  .message-actions .actions > li.actions-dropdown {
    display: block;
  }
}
.message-actions .actions > li > a, .message-actions .actions > li > span > a {
  font-size: 12px;
  font-weight: 600;
  line-height: 65px;
  padding: 0 13px;
  white-space: nowrap;
  color: #8e8e93;
}
.message-actions .actions > li.email-list-toggle {
  display: none;
}
@media (max-width: 768px) {
  .message-actions .actions > li.email-list-toggle {
    display: block !important;
  }
}

.message-header {
  border-bottom: 1px solid white;
  padding: 75px 15px 10px;
}
.message-header .message-item-avatar {
  border-radius: 4px;
  float: left;
  height: 50px;
  width: 50px;
  margin-right: 10px;
}
.message-header .message-from {
  font-weight: bold;
  color: #5b5b60;
}
.message-header .message-to {
  color: #c2c2c5;
}
.message-header .message-time {
  color: #c2c2c5;
  font-size: 11px;
}

.message-subject {
  padding: 20px 10px 0;
  color: #5b5b60;
  font-size: 16px;
}

.message-content {
  padding: 20px 10px;
  color: #8e8e93;
}

.inbox {
  border-spacing: 0;
  height: 100%;
  margin: 0 -15px;
}

.inbox .col {
  float: left;
  height: 100%;
  vertical-align: top;
}

.inbox .email-options {
  width: 240px !important;
  border-right: 1px solid #dee5e7;
  overflow: hidden;
}
@media (max-width: 1200px) {
  .inbox .email-options {
    display: none;
  }
}
.inbox .email-options .email-options-title {
  color: #808285;
  font-size: 10px;
  letter-spacing: 0.2px;
  text-transform: uppercase;
}
.inbox .email-options .main-options {
  margin: 0;
  list-style: none;
  padding: 0;
}
.inbox .email-options .main-options li {
  line-height: 34px;
}
.inbox .email-options .main-options li .badge {
  margin-top: 8px;
}

.no-messages {
  bottom: 0;
  left: 0;
  margin-top: -34px;
  opacity: 0.5;
  position: absolute;
  right: 0;
  text-align: center;
  top: 50%;
}

.inbox .email-list {
  width: 360px !important;
  border-right: 1px solid #dee5e7;
  background: #ffffff;
}
@media (max-width: 768px) {
  .inbox .email-list {
    width: 100% !important;
    border-right: none;
    background: #ffffff;
    position: relative;
  }
}

.inbox .wrap-list {
  width: 359px;
  height: 100% !important;
  position: relative;
}
.inbox .wrap-list .close-message-search {
  position: absolute;
  right: 15px;
  top: 15px;
  z-index: 100;
  display: none;
}
.inbox .wrap-list .search-open .close-message-search {
  display: block;
}
.inbox .wrap-list .search-open .open-message-search {
  display: none;
}
.inbox .wrap-list .search-open .messages-options .btn-group {
  display: none;
}
.inbox .wrap-list .search-open .messages-search {
  display: block !important;
  right: 50px;
  left: 0;
  width: auto;
}
@media (max-width: 768px) {
  .inbox .wrap-list {
    width: 100% !important;
  }
}
@media (min-width: 1200px) {
  .inbox .wrap-list .messages-options {
    display: none;
  }
}

.inbox .email-reader {
  height: 100%;
  margin-left: 600px;
  overflow: hidden;
  position: relative;
  padding: 0;
  background: #ffffff;
}
@media (max-width: 1200px) {
  .inbox .email-reader {
    margin-left: 360px;
  }
}
@media (max-width: 768px) {
  .inbox .email-reader {
    margin-left: 0;
  }
}

/* ie8 fixes */
.ie8 .messages-list .messages-item .messages-item-star {
  display: none;
}

.ie8 .messages-list .messages-item:hover .messages-item-star {
  display: block;
}

/**/
/* ie8 fixes */
.ie8 .messages-list .messages-item .messages-item-time .messages-item-actions {
  display: none;
}

/**/
/* ---------------------------------------------------------------------- */
/*  Modals
/* ---------------------------------------------------------------------- */
.modal-backdrop {
  background-color: #000;
  bottom: 0;
  position: fixed;
}
.modal-backdrop.in {
  opacity: 0.4;
}

.modal-backdrop {
  z-index: 9998 !important;
}

.modal, .modal-dialog {
  z-index: 9999 !important;
}

.modal-content {
  background: #e8e8e8;
  box-shadow: none;
}

.modal-footer, .modal-header {
  border-color: #b5b5b5;
}

.modal-aside.horizontal .modal-dialog {
  min-height: 100% !important;
  bottom: 0;
  height: auto !important;
}
.modal-aside.horizontal .modal-dialog .modal-content {
  height: auto !important;
  min-height: 100% !important;
}

@-webkit-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    -ms-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
  }
}
.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
}

@-webkit-keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    -ms-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
  }
}
.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
}

@-webkit-keyframes fadeInTop {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInTop {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    -ms-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
  }
}
.fadeInTop {
  -webkit-animation-name: fadeInTop;
  animation-name: fadeInTop;
}

@-webkit-keyframes fadeInBottom {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInBottom {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    -ms-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
  }
}
.fadeInBottom {
  -webkit-animation-name: fadeInBottom;
  animation-name: fadeInBottom;
}

.modal-aside {
  overflow: hidden;
}

.modal-aside.horizontal {
  transition: none;
  -webkit-transition: none;
  transform: none;
  -webkit-transform: none;
  height: 100%;
}

.modal-aside.horizontal.left.in .modal-dialog {
  animation: fadeInLeft 150ms;
  -webkit-animation: fadeInLeft 150ms;
}

.modal-aside.horizontal.right.in .modal-dialog {
  animation: fadeInRight 150ms;
  -webkit-animation: fadeInRight 150ms;
}

.modal-aside.horizontal .modal-dialog {
  position: absolute;
  top: 0;
  height: 100%;
  margin: 0;
  padding: 0;
  transition: none;
  -webkit-transition: none;
  transform: none;
  -webkit-transform: none;
}

.modal-aside.horizontal.left .modal-dialog {
  left: 0;
}

.modal-aside.horizontal.right .modal-dialog {
  right: 0;
}

.modal-aside.horizontal .modal-dialog .modal-content {
  height: 100%;
  border: none;
  border-radius: 0;
}

.modal-aside.vertical {
  transition: none;
  -webkit-transition: none;
  transform: none;
  -webkit-transform: none;
  width: 100% !important;
  overflow: hidden;
}

.modal-aside.vertical .modal-dialog {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0;
  padding: 0;
  width: 100% !important;
  transition: none;
  -webkit-transition: none;
  transform: none;
  -webkit-transform: none;
}

.modal-aside.vertical.top.in .modal-dialog {
  animation: fadeInTop 150ms;
  -webkit-animation: fadeInTop 150ms;
}

.modal-aside.vertical.bottom.in .modal-dialog {
  animation: fadeInBottom 150ms;
  -webkit-animation: fadeInBottom 150ms;
}

.modal-aside.vertical.bottom .modal-dialog {
  bottom: 0;
}

.modal-aside.vertical.top .modal-dialog {
  top: 0;
}

.modal-aside.vertical .modal-dialog .modal-content {
  border: none;
  width: 100%;
  height: 100%;
  border-radius: 0;
}

/* ---------------------------------------------------------------------- */
/*  Maps
/* ---------------------------------------------------------------------- */
#map_canvas {
  position: relative;
}

.angular-google-map-container {
  height: 400px;
}

/* ---------------------------------------------------------------------- */
/*  Pricing Tables
/* ---------------------------------------------------------------------- */
.pricing-table .plan {
  border: none;
  margin: 20px 0;
  position: relative;
  padding: 0;
  list-style: none;
  border: 1px solid #e2e2e4;
  border-bottom: none;
  text-align: center;
}
.pricing-table .plan li {
  padding: 10px 15px;
  color: #8e8e93;
  border-bottom: 1px solid #e2e2e4;
}
.pricing-table .plan li.plan-name {
  padding: 15px;
  font-size: 18px;
  line-height: 18px;
  color: #fff;
  background: #d5d4d8;
  border-top: 0;
  border-bottom: 0;
}
.pricing-table .plan li.plan-price {
  background: #c8c7cc;
  color: #ffffff;
  font-size: 18px;
  padding: 6px 20px;
  border-bottom: none;
}
.pricing-table .plan li.plan-price h3 {
  margin-bottom: 0;
  margin-top: 0;
  font-size: 2em;
  padding: 10px 0;
  font-weight: 600;
  color: #ffffff;
}
.pricing-table .plan li.plan-price h3 .price-cents {
  font-size: 50%;
  vertical-align: super;
  /*margin-left: -10px;*/
}
.pricing-table .plan li.plan-price h3 .price-month {
  font-size: 30%;
  font-style: italic;
  margin-left: -20px;
  font-weight: 300;
  color: #ffffff;
}
.pricing-table .plan li.plan-action {
  margin-top: 10px;
  border-top: 0;
}
.pricing-table .plan.featured {
  -webkit-transform: scale(1.05);
  -ms-transform: scale(1.05);
  transform: scale(1.05);
  box-shadow: 0 3px 25px -4px rgba(0, 0, 0, 0.9) !important;
}
.pricing-table .plan.featured .plan-name {
  background: #94929b;
}

.pricing-green .plan li strong {
  color: #C82E29;
}
.pricing-green .plan li.plan-name {
  background: #23d1b9;
}
.pricing-green .plan li.plan-price {
  background: #1FBBA6;
  color: #ffffff;
}
.pricing-green .plan.featured .plan-name {
  background: #106458;
}

.pricing-blue .plan li strong {
  color: #5F8295;
}
.pricing-blue .plan li.plan-name {
  background: #6c8fa1;
}
.pricing-blue .plan li.plan-price {
  background: #5F8295;
  color: #ffffff;
}
.pricing-blue .plan.featured .plan-name {
  background: #374c57;
}

.pricing-red .plan li strong {
  color: #C82E29;
}
.pricing-red .plan li.plan-name {
  background: #d63a35;
}
.pricing-red .plan li.plan-price {
  background: #C82E29;
  color: #ffffff;
}
.pricing-red .plan.featured .plan-name {
  background: #731b18;
}

/* ---------------------------------------------------------------------- */
/*  404 and 500 error
/* ---------------------------------------------------------------------- */
.error-full-page .page-error {
  margin-top: 60px;
}

.page-error {
  text-align: center;
}

.page-error .error-number {
  display: block;
  font-size: 158px;
  font-weight: 300;
  letter-spacing: -10px;
  line-height: 128px;
  margin-top: 0;
  text-align: center;
}

.page-error .error-details {
  display: block;
  padding-top: 0;
  text-align: center;
}

.page-error .error-details .btn-return {
  margin: 10px 0;
}

.page-error .error-details h3 {
  margin-top: 0;
}

.error-full-page {
  overflow: hidden;
}

.error-full-page img {
  display: none;
}

.error-full-page #canvas {
  position: absolute;
  top: 0px;
  left: 0px;
}

.error-full-page #sound {
  position: absolute;
  width: 30%;
  height: 30%;
  overflow-y: auto;
  margin-left: 35%;
  -moz-border-radius: 15px;
  border-radius: 15px;
  opacity: 0.3;
  margin-top: 5%;
}

.error-full-page .video {
  position: absolute;
  width: 90%;
  height: 80%;
  margin-left: 5%;
  margin-top: 5%;
}

/* ---------------------------------------------------------------------- */
/*  Page Title
/* ---------------------------------------------------------------------- */
#page-title {
  border-bottom: 1px solid #eee;
  padding: 10px 0;
  position: relative;
  margin-left: -15px;
  margin-right: -15px;
}
@media (max-width: 767px) {
  #page-title {
    text-align: center;
    padding: 20px 0;
  }
}
#page-title .row {
  margin: 0 15px;
}
#page-title h1 {
  color: #333;
  font-size: 28px;
  font-weight: 300;
  letter-spacing: 1px;
  line-height: 1;
  margin: 0;
  padding: 0;
  text-transform: uppercase;
}
@media (max-width: 767px) {
  #page-title h1 {
    font-size: 24px;
  }
}
#page-title .mainDescription {
  font-family: "Lato", sans-serif;
  color: #777;
  display: block;
  font-size: 18px;
  font-weight: 300;
  margin-top: 10px;
}
#page-title .mainDescription small {
  font-size: 60%;
  margin-top: 10px;
  display: block;
}
#page-title .breadcrumb {
  background-color: transparent !important;
  font-size: 12px;
  left: auto !important;
  margin: -30px 0 0 !important;
  padding: 0 !important;
  position: absolute;
  right: 30px !important;
  top: 50% !important;
  width: auto !important;
  font-family: "Lato", sans-serif;
}
@media (max-width: 767px) {
  #page-title .breadcrumb {
    left: 0 !important;
    margin: 15px 0 0 !important;
    position: relative !important;
    right: 0 !important;
    top: 0 !important;
  }
}
#page-title .breadcrumb > li + li {
  padding: 0 5px;
  position: relative;
}
#page-title .breadcrumb > li + li:before {
  color: #007AFF;
  content: "/ ";
  padding: 0 5px;
}
#page-title.page-title-center {
  text-align: center;
}
#page-title.page-title-center .breadcrumb {
  left: 0 !important;
  margin: 15px 0 0 !important;
  position: relative !important;
  right: 0 !important;
  top: 0 !important;
}

/* ---------------------------------------------------------------------- */
/*  Tooltips
/* ---------------------------------------------------------------------- */
.static-tooltip .tooltip {
  display: inline-block;
  margin: 10px 20px;
  opacity: 1;
  position: relative;
}

/* ---------------------------------------------------------------------- */
/*  Popover
/* ---------------------------------------------------------------------- */
.static-popover .popover {
  display: block;
  float: left;
  margin: 20px;
  position: relative;
  width: 260px;
}

/*.popover {
  display: block;
  float: left;
  margin: 20px;
  position: relative;
  width: 260px;
}*/

/* ---------------------------------------------------------------------- */
/*  Datepicker
/* ---------------------------------------------------------------------- */
.picker__box {
  border-color: #ffffff !important;
}

.picker__nav--prev:before, .picker__nav--next:before {
  border: none !important;
  display: block;
  height: auto !important;
  margin: 0 auto;
  width: auto !important;
  font-family: 'themify';
}

.picker__nav--next:before {
  content: "\e649" !important;
}

.picker__nav--prev:before {
  content: "\e64a" !important;
}

.picker__button--close:before {
  content: "\e646" !important;
  font-family: 'themify';
}

.picker__button--clear:before {
  border: none !important;
  top: auto !important;
  width: auto !important;
  font-family: 'themify';
  content: "\e6a5" !important;
  font-size: 1.1em;
}

.picker__weekday {
  color: #007AFF !important;
  text-align: center;
}

[pick-a-date], [pick-a-time] {
  cursor: pointer !important;
}

[date-picker] {
  padding: 5px 20px !important;
  min-width: 230px;
}

[date-picker] th {
  color: #007AFF;
  font-weight: bold;
}

[date-picker] .switch {
  color: #5b5b60;
}

[date-picker] .active, [date-picker] .now {
  background-color: #007AFF !important;
  background-image: none !important;
  border: none !important;
  text-shadow: none !important;
  color: #ffffff !important;
}

[date-picker] .now {
  background-color: #ffffff !important;
  color: #007AFF !important;
  font-weight: bold;
}

[date-picker] .active:hover, [date-picker] .now:hover, [date-picker] .active:active, [date-picker] .now:active, [date-picker] .active.active, [date-picker] .now.active, [date-picker] .active.disabled, [date-picker] .now.disabled, [date-picker] .active[disabled], [date-picker] .now[disabled] {
  color: #ffffff !important;
  background-color: #007AFF !important;
}

[date-picker] .disabled {
  color: #c2c2c5 !important;
}

[date-picker] .disabled.active, [date-picker] .disabled.now {
  color: #ffffff !important;
}



/*.twitter-typeahead .tt-input,
.twitter-typeahead .tt-hint {
  margin-bottom: 0;
}
.tt-hint {
  display: block;
  width: 100%;
  height: 38px;
  padding: 8px 12px;
  font-size: 14px;
  line-height: 1.428571429;
  color: #999;
  vertical-align: middle;
  background-color: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
        transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}
.tt-menu {
  min-width: 160px;
  margin-top: 2px;
  padding: 5px 0;
  background-color: #ffffff;
  border: 1px solid #cccccc;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  background-clip: padding-box;
  z-index: 999;

}
.tt-suggestion {
  display: block;
  padding: 3px 20px;
}
.tt-suggestion.tt-cursor {
  color: #fff;
  background-color: #428bca;
}
.tt-suggestion.tt-cursor a {
  color: #fff;
}
.tt-suggestion p {
  margin: 0;
}*/

.twitter-typeahead {
  width: 100%;
}

.tt-input, /* UPDATE: newer versions use tt-input instead of tt-query */
.tt-hint {
    width: 100%;
    padding: 8px 12px;
    font-size: 14px;
    line-height: 30px;
    border: 2px solid #ccc;
    outline: none;
}

.tt-input { /* UPDATE: newer versions use tt-input instead of tt-query */
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.tt-hint {
    color: #999;
}

.tt-menu { /* UPDATE: newer versions use tt-menu instead of tt-dropdown-menu */
    width: 100%;
    padding: 8px 0;
    background-color: #fff;
    border: 1px solid #ccc;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-shadow: 0 5px 10px rgba(0,0,0,.2);
    z-index: 99999;
}

.tt-suggestion {
    padding: 3px 10px;
    font-size: 14px;
    line-height: 18px;
}

.tt-suggestion:hover {
  cursor: pointer;
  color: #fff;
  background-color: #007aff;
}

.tt-suggestion.tt-cursor { /* UPDATE: newer versions use .tt-suggestion.tt-cursor */
    color: #fff;
    background-color: #007aff;
}


.tt-suggestion p {
    margin: 0;
}


.wrap-word {
  white-space: -moz-pre-wrap !important;  /* Mozilla, since 1999 */
  white-space: -webkit-pre-wrap; /*Chrome & Safari */
  white-space: -pre-wrap;      /* Opera 4-6 */
  white-space: -o-pre-wrap;    /* Opera 7 */
  white-space: pre-wrap;       /* css-3 */
  word-wrap: break-word;       /* Internet Explorer 5.5+ */
  word-break: break-all;
  white-space: normal;
}

.table-fixed thead {
  /*width: 97%;*/
}
.table-fixed tbody {
  height: 230px;
  overflow-y: auto;
  width: 100%;
}
.table-fixed thead, .table-fixed tbody, .table-fixed tr, .table-fixed td, .table-fixed th {
  display: block;
}
.table-fixed tbody td, .table-fixed thead > tr> th {
  /*float: left;*/
  border-bottom-width: 0;
}
.wrap-frame {
    width: 100%;
    height: 120px;
    padding: 0;
    overflow: hidden;
}
.frame {
    width: 310px;
    height: 120px;
    border: 0;
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    -webkit-transform: scale(1);
    transform: scale(1);

    -ms-transform-origin: 0 0;
    -moz-transform-origin: 0 0;
    -o-transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
}

tr.invalid {
  border: 1px solid red;
}

tr.valid {
  border: 1px solid green;
}

tr.selected
{
  background-color: #FFFFCC !important;
}



.guide-data-body
{
  background: #E2E2E4;
  padding-left: 10px;
  padding-right: 10px;
}

.guide-data-main
{
    margin-top: 0;
    position: relative;
}

.guide-data-main .guide-data-box
{
    background: #FFFFFF;
    /*border-radius: 5px;*/
    overflow: hidden;
    padding: 15px 15px 10px 15px;
    margin: 0px 0 10px 0;
}

.buyers-guide
{
  font-family: Arial;
  color: #666666;

}
.buyers-guide .title
{
  font-size: 20px;
  font-weight: bold;
  color: #ff7e82;
}
.buyers-guide .subtitle
{
  font-size: 18px;
}

.buyers-guide .interested-section
{
  text-align: right;
}
.selected-border
{
  border: 3px solid orange;
  padding: 5px;
}


.dt-buttons {
  float: right !important;
}


.topMenu {
    position: fixed;
    top: 0;
    width: 100%;
    background-color: #E2E2E4;
    z-index: 1000000;
    vertical-align: middle;
    transition: all 2s;
}
.hide {
    opacity:0;
    left:-100%;
}
.show {
    opacity:1;
    left:0;
}

.btn-air-red {
    border-color: #ff5a5f;
    border-bottom-color: #e00007;
    background-color: #ff5a5f;
    color: #fff
}

.btn-air-red:hover,.btn-air-red:focus {
    border-color: #ff7e82;
    border-bottom-color: #fa0008;
    background-color: #ff7e82;
    color: #fff
}

.btn-air-red:active {
    border-color: #e00007;
    background-color: #e00007;
    color: #fff
}

.btn-host,.btn-special {
    border-color: #007a87;
    border-bottom-color: #004f58;
    background-color: #007a87;
    color: #fff
}

.btn-host:hover,.btn-host:focus,.btn-special:hover,.btn-special:focus {
    border-color: #009aab;
    border-bottom-color: #006671;
    background-color: #009aab;
    color: #fff
}

.btn-host:active,.btn-special:active {
    border-color: #004f58;
    background-color: #004f58;
    color: #fff
}

.btn-guest {
    border-color: #b4a76c;
    border-bottom-color: #7c713f;
    background-color: #b4a76c;
    color: #fff
}

.btn-guest:hover,.btn-guest:focus {
    border-color: #c0b584;
    border-bottom-color: #8d8048;
    background-color: #c0b584;
    color: #fff
}

.btn-guest:active {
    border-color: #7c713f;
    background-color: #7c713f;
    color: #fff
}

.btn-contrast {
    border-color: #484848;
    border-bottom-color: #2f2f2f;
    background-color: #484848;
    color: #fff
}

.btn-contrast:hover,.btn-contrast:focus {
    border-color: #5a5a5a;
    border-bottom-color: #3c3c3c;
    background-color: #5a5a5a;
    color: #fff
}

.btn-contrast:active {
    border-color: #2f2f2f;
    background-color: #2f2f2f;
    color: #fff
}

.btn-facebook {
    border-color: #3B5998;
    border-bottom-color: #263a63;
    background-color: #3B5998;
    color: #fff
}

.btn-facebook:hover,.btn-facebook:focus {
    border-color: #4568b2;
    border-bottom-color: #2d4575;
    background-color: #4568b2;
    color: #fff
}

.btn-facebook:active {
    border-color: #263a63;
    background-color: #263a63;
    color: #fff
}

.btn-facebook-messenger {
    border-color: #0084ff;
    border-bottom-color: #0056a6;
    background-color: #0084ff;
    color: #fff
}

.btn-facebook-messenger:hover,.btn-facebook-messenger:focus {
    border-color: #2495ff;
    border-bottom-color: #0063bf;
    background-color: #2495ff;
    color: #fff
}

.btn-facebook-messenger:active {
    border-color: #0056a6;
    background-color: #0056a6;
    color: #fff
}

.btn-weibo {
    border-color: #d52b2a;
    border-bottom-color: #8a1c1b;
    background-color: #d52b2a;
    color: #fff
}

.btn-weibo:hover,.btn-weibo:focus {
    border-color: #db4948;
    border-bottom-color: #a02020;
    background-color: #db4948;
    color: #fff
}

.btn-weibo:active {
    border-color: #8a1c1b;
    background-color: #8a1c1b;
    color: #fff
}

.btn-google .icon.icon-google-plus {
    color: #dd4b39
}

.btn-wechat {
    border-color: #09bc13;
    border-bottom-color: #067a0c;
    background-color: #09bc13;
    color: #fff
}

.btn-wechat:hover,.btn-wechat:focus {
    border-color: #0bde16;
    border-bottom-color: #07930f;
    background-color: #0bde16;
    color: #fff
}

.btn-wechat:active {
    border-color: #067a0c;
    background-color: #067a0c;
    color: #fff
}

.btn-alipay {
    border-color: #00a9f1;
    border-bottom-color: #006e9d;
    background-color: #00a9f1;
    color: #fff
}

.btn-alipay:hover,.btn-alipay:focus {
    border-color: #16b9ff;
    border-bottom-color: #0080b6;
    background-color: #16b9ff;
    color: #fff
}

.btn-alipay:active {
    border-color: #006e9d;
    background-color: #006e9d;
    color: #fff
}

.btn-active-interest
{
  background-color: #559ede !important;
  color: #fff !important;
  -moz-transition: -moz-transform 300ms ease 0s;
  -o-transition: -o-transform 300ms ease 0s;
  -webkit-transition: -webkit-transform 300ms ease;
  -webkit-transition-delay: 0s;
  transition: transform 300ms ease 0s;
}

.btn-active-interest:hover,.btn-active-interest:focus,
{
  background-color: #559ede;
  color: #fff;
  font-weight: bold;
}

.btn-active-interest:active
{
  font-weight: bold;
  color:#FFF;
}

.treenode {
  width: 100%;
}
.rc-tree {
  font-size: 16px;
}

.rc-tree li a {
  color: #666666;
  width: 90%;
  height: 22px !important;
  padding: 0px 2px 0 0 !important;
}
.rc-tree li a:hover {
  color: #FFF;
  background-color: #4f7094;
  border: 1px #ffb951 solid;
  opacity: 0.8;
}

.modal-dialog {
  margin-top: 100px;
}

.filter-bar-action-buttons {
  text-align: right;
}

@media (max-width: 992px) {
  .filter-bar-action-buttons {
    text-align: left;
    margin-top: 5px;
  }

  .btn {
    margin-top: 5px;
  }

}

/* ------------- */
.event_title
{
  font-size: 16px;
  font-weight: bold;
  color: #007a87;
}

.event_description > span
{
  white-space: nowrap;
}

/* ------------- Print media  */
@media print {
    /* Your styles here */
    body {
        /* transform: scale(1.1);*/
    }
    table {
        page-break-inside: avoid;
    }
    .container {
        width: auto;
    }
    .guide-data-box {
        page-break-inside: avoid;
        margin: 2px 0 2px 0 !important;
        padding: 5px 15px 5px 15px !important;
        /*transform: scale(1, 0.7);*/
    }
    .guide-data-body {
        background: #E2E2E4;
    }
    .guide-data-main {
        margin-top: 0;
        position: relative;
    }
    .buyers-guide {
        font-family: Arial;
        color: #666666;
    }
    .buyers-guide .title {
        font-size: 14px !important;
        font-weight: bold;
    }
    .buyers-guide {
        font-size: 10px !important;
    }
    .buyers-guide .subtitle {
        font-size: 12px !important;
    }
    .buyers-guide .description {
        font-size: 10px !important;
    }
    .buyers-guide .interested-section {
        text-align: left !important;
    }
    a[href]:after {
    content: none !important;
  }
  .btn-active-interest {
      color: #000 !important;
      font-weight: bold;
      border: 1px solid #000;
      background-color: #FFFFFF !important;
  }
  .btn-default {
      border: 1px solid #d9d9db;
      color: #d9d9db !important;
  }
}

@media screen and (max-width: 767px) {

    .buyers-guide .interested-section {
        text-align: left !important;
    }
}

/* Read More styling*/
.hasMore {
  overflow: hidden;
  position: relative;
}

.hasMore .expandMore {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 15em;
  display: block;
  text-align: right;
}

.hasMore .contractMore {
  text-align: right;
}



/* ---------------------------------------------------------------------- */
/*  Exhibitor icons
/* ---------------------------------------------------------------------- */

.brown {
  background-color: #795548;
  color: rgba(255, 255, 255, 0.85);
}
.blue-grey {
  background-color: #607d8b;
  color: rgba(255, 255, 255, 0.85);
}

.red {
  background-color: #dc3545;
  color: rgba(255, 255, 255, 0.85);
}

.purple {
  background-color: #6f42c1;
  color: rgba(255, 255, 255, 0.85);
}

.blue {
  background-color: #337ab7;
  color: rgba(255, 255, 255, 0.85);
}
.blue-dark {
  background-color: #0060a6;
  color: rgba(255, 255, 255, 0.85);
}
.green {
  background-color: #3c763d;
  color: rgba(255, 255, 255, 0.85);
}
.w-25 {
  width: 25px;
  height:25px;
}
.circle {
  border-radius: 500px;
}
.rectangle {
  border-radius: 4px !important;
  padding: 4px 10px;
}
.avatar {
  vertical-align: bottom;
  position: relative;
  line-height: 1;
  border-radius: 500px;
  white-space: nowrap;
  font-weight: bold;
  font-size: 13px;
  border-radius: 20%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
}

.avatar:hover {
  color: rgba(255, 255, 255, 0.85);
}